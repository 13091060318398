export interface RiegoDataI {
  rie_id: number;
  rie_descripcion: string;
  rie_estado: number;
}

export class Riego implements RiegoDataI {
  rie_id: number;
  rie_descripcion: string;
  rie_estado: number;

  constructor(data: RiegoDataI) {
    this.rie_id = data.rie_id;
    this.rie_descripcion = data.rie_descripcion;
    this.rie_estado = data.rie_estado;
  }

  _toMap() {
    return {
      rie_id: this.rie_id,
      rie_descripcion: this.rie_descripcion,
      rie_estado: this.rie_estado,
    };
  }

  _fromMap(data: RiegoDataI) {
    this.rie_id = data.rie_id;
    this.rie_descripcion = data.rie_descripcion;
    this.rie_estado = data.rie_estado;
  }
}
