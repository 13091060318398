export interface PendienteDataI {
  pen_id: number;
  pen_descripcion: string;
  pen_estado: number;
}

export class Pendiente implements PendienteDataI {
  pen_id: number;
  pen_descripcion: string;
  pen_estado: number;

  constructor(data: PendienteDataI) {
    this.pen_id = data.pen_id;
    this.pen_descripcion = data.pen_descripcion;
    this.pen_estado = data.pen_estado;
  }

  _toMap() {
    return {
      pen_id: this.pen_id,
      pen_descripcion: this.pen_descripcion,
      pen_estado: this.pen_estado,
    };
  }

  _fromMap(data: PendienteDataI) {
    this.pen_id = data.pen_id;
    this.pen_descripcion = data.pen_descripcion;
    this.pen_estado = data.pen_estado;
  }
}
