import { Coloracion } from "../../model/coloracion";
import { EstadoFenologico } from "../../model/estado_fenologico";
import { FichaTecnicaAgronomica } from "../../model/fichatecnicaagronomica";
import { FTABloque } from "../../model/ftabloque";
import { Table } from "antd";
import type { TableProps } from "antd";

export default function FTABloqueComponent({
  fta,
}: {
  fta: FichaTecnicaAgronomica;
}) {
  const columns: TableProps<FTABloque>["columns"] = [
    {
      title: "Código",
      dataIndex: "ftab_id",
      key: "ftab_id",
    },    
    {
      title: "Estado fenológico",
      dataIndex: "ftab_estado_fenologico",
      key: "ftab_estado_fenologico",
      render: (_value: EstadoFenologico) => _value.ef_descripcion,
    },
    {
      title: "N° plantas por muestra",
      dataIndex: "ftab_nro_plantas_muestra",
      key: "ftab_nro_plantas_muestra",
    },
    {
      title: "Distanciamiento entre plantas (cm)",
      dataIndex: "ftab_distanciamiento",
      key: "ftab_distanciamiento",
    },
    {
      title: "Diámetro de la copa (cm)",
      dataIndex: "ftab_diametro_copa",
      key: "ftab_diametro_copa",
    },
    {
      title: "N° de hojas totales",
      dataIndex: "ftab_nro_hojas_totales",
      key: "ftab_nro_hojas_totales",
    },
    {
      title: "N° hojas enfermas",
      dataIndex: "ftab_nro_hojas_enfermas",
      key: "ftab_nro_hojas_enfermas",
    },
    {
      title: "% hojas enfermas",
      dataIndex: "ftab_per_hojas_enfermas",
      key: "ftab_per_hojas_enfermas",
    },
    {
      title: "N° hojas sanas",
      dataIndex: "ftab_nro_hojas_sanas",
      key: "ftab_nro_hojas_sanas",
    },
    {
      title: "% hojas sanas",
      dataIndex: "ftab_per_hojas_sanas",
      key: "ftab_per_hojas_sanas",
    },
    {
      title: "% Malesa",
      dataIndex: "ftab_per_malesa",
      key: "ftab_per_malesa",
    },
    {
      title: "Edad",
      dataIndex: "ftab_edad",
      key: "ftab_edad",
    },
    {
      title: "Punto 1",
      dataIndex: "ftab_lat1",
      key: "ftab_lat1",
      render: (_value: number, _row: FTABloque) => {
        return (
          <span>
            Lat: {_row.ftab_lat1} Lng: {_row.ftab_lng1}
          </span>
        );
      },
    },
    {
      title: "Punto 2",
      dataIndex: "ftab_lat2",
      key: "ftab_lat2",
      render: (_value: number, _row: FTABloque) => {
        return (
          <span>
            Lat: {_row.ftab_lat2} Lng: {_row.ftab_lng2}
          </span>
        );
      },
    },
    {
      title: "Punto 3",
      dataIndex: "ftab_lat3",
      key: "ftab_lat3",
      render: (_value: number, _row: FTABloque) => {
        return (
          <span>
            Lat: {_row.ftab_lat3} Lng: {_row.ftab_lng3}
          </span>
        );
      },
    },
    {
      title: "Punto 4",
      dataIndex: "ftab_lat4",
      key: "ftab_lat4",
      render: (_value: number, _row: FTABloque) => {
        return (
          <span>
            Lat: {_row.ftab_lat4} Lng: {_row.ftab_lng4}
          </span>
        );
      },
    },
    {
      title: "% Anegamiento",
      dataIndex: "ftab_per_anegamiento",
      key: "ftab_per_anegamiento",
    },
    {
      title: "Coloración",
      dataIndex: "ftab_coloracion",
      key: "ftab_coloracion",
      render: (_value: Coloracion) => _value.col_descripcion,
    },
    {
      title: "Altura de la planta evaluada (cm)",
      dataIndex: "ftab_altura_planta",
      key: "ftab_altura_planta",
    },
  ];

  return (
    <Table
      rowKey="ftab_id"
      columns={columns}
      dataSource={fta.fta_bloques}
      size="small"
      pagination={{
        size: "small",
      }}
    />
  );
}
