export interface SistemaSiembraDataI {
  sis_id: number;
  sis_descripcion: string;
  sis_estado: number;
}

export class SistemaSiembra implements SistemaSiembraDataI {
  sis_id: number;
  sis_descripcion: string;
  sis_estado: number;

  constructor(data: SistemaSiembraDataI) {
    this.sis_id = data.sis_id;
    this.sis_descripcion = data.sis_descripcion;
    this.sis_estado = data.sis_estado;
  }

  _toMap() {
    return {
      sis_id: this.sis_id,
      sis_descripcion: this.sis_descripcion,
      sis_estado: this.sis_estado,
    };
  }

  _fromMap(data: SistemaSiembraDataI) {
    this.sis_id = data.sis_id;
    this.sis_descripcion = data.sis_descripcion;
    this.sis_estado = data.sis_estado;
  }
}
