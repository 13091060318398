import { APIException } from "../model/api_exception";
import { AuthService } from "../services/auth.service";
import { AxiosResponse } from "axios";
import { Perfil } from "../model/perfil";
import { PerfilDataI } from "../model/perfil";
import axios from "axios";
import Global from "../global/global";

export class APIPerfil {
  private _auth: AuthService = new AuthService();

  async _listarPerfiles(): Promise<Perfil[]> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarPerfiles`,
      {
        token: await this._auth._getToken(),
      }
    );
    if (result.status === 200) {
      let listaPerfil: Perfil[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: PerfilDataI) => {
          listaPerfil.push(new Perfil(element));
        });
      }
      return listaPerfil;
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }
}
