import { APIException } from "../model/api_exception";
import { AxiosResponse } from "axios";
import { decodeToken } from "react-jwt";
import { Usuario } from "../model/usuario";
import axios from "axios";
import Global from "../global/global";
import { APIResponse } from "../model/api_response";

export class AuthService {
  async _auth(usuario: string, password: string): Promise<Usuario> {
    let result: AxiosResponse = await axios.post(`${Global.URL_API}/auth`, {
      usuario,
      password,
    });
    if (result.status === 200) {
      if (result.data.success) {
        this._save(result.data.token);
        let data: any = decodeToken(result.data.token)!;
        return new Usuario(data.data);
      } else {
        throw new APIException(result.status, result.data.message);
      }
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _cambioPassword(
    password: string,
    password_actual: string
  ): Promise<APIResponse> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/cambioPassword`,
      {
        token: await this._getToken(),
        password,
        password_actual,
      }
    );
    if (result.status === 200) {
      if (result.data.success) {
        return new APIResponse(result.data);
      } else {
        throw new APIException(result.status, result.data.message);
      }
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _logOut(): Promise<boolean> {
    localStorage.clear();
    return true;
  }

  async _getToken(): Promise<string> {
    let _data: string = localStorage.getItem("agroatweb")!;
    return _data;
  }

  async _getCurrentUser(): Promise<Usuario | null> {
    let token: string = localStorage.getItem("agroatweb")!;
    if (token) {
      let data: any = decodeToken(token)!;
      return new Usuario(data.data);
    }
    return null;
  }

  async _save(data: string) {
    localStorage.setItem("agroatweb", data);
  }
}
