import styled from "styled-components";

const Inactivo = styled.div`
  background: rgb(244 67 54);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: ${(props) => props.itemProp};
`;

const InactivoWidget = ({ margin }: { margin: string }) => {
  return <Inactivo itemProp={margin} />;
};

export default InactivoWidget;
