import { APIException } from "../model/api_exception";
import { APIResponse } from "../model/api_response";
import { FichaCampo } from "../model/ficha_campo";
import { FichaCampoDataI } from "../model/ficha_campo";
import { AuthService } from "../services/auth.service";
import { AxiosResponse } from "axios";
import axios from "axios";
import Global from "../global/global";

interface FichaCampoPagination {
  data: FichaCampo[];
  totalItems: number;
  pendientes: number;
  aprobados: number;
  rechazados: number;
}
export class APIFichaCampo {
  private _auth: AuthService = new AuthService();

  async _listarFichaCampo(
    usuario?: number,
    fechaIni?: string,
    fechaFin?: string,
    estado?: number,
    page?: number,
    perPage?: number
  ): Promise<FichaCampoPagination> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarFichaCampo`,
      {
        token: await this._auth._getToken(),
        usuario,
        fechaIni,
        fechaFin,
        estado,
        page,
        perPage,
      }
    );
    if (result.status === 200) {
      let data: FichaCampo[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: FichaCampoDataI) => {
          data.push(new FichaCampo(element));
        });
      }
      let totalItems = result.data.totalItems;
      let pendientes = result.data.pendientes;
      let aprobados = result.data.aprobados;
      let rechazados = result.data.rechazados;
      return { data, totalItems, pendientes, aprobados, rechazados };
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _actualizarFichaCampo(
    _estadisticaAgraria: FichaCampo,
    _estado: number
  ): Promise<APIResponse> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/actualizarFichaCampo`,
      {
        token: await this._auth._getToken(),
        codigo: _estadisticaAgraria.codigo,
        estado: _estado,
      }
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }
}
