export interface CultivoDataI {
  cul_id: number;
  cul_descripcion: string;
  cul_estado: number;
}

export class Cultivo implements CultivoDataI {
  cul_id: number;
  cul_descripcion: string;
  cul_estado: number;

  constructor(data: CultivoDataI) {
    this.cul_id = data.cul_id;
    this.cul_descripcion = data.cul_descripcion;
    this.cul_estado = data.cul_estado;
  }

  _toMap() {
    return {
      cul_id: this.cul_id,
      cul_descripcion: this.cul_descripcion,
      cul_estado: this.cul_estado,
    };
  }

  _fromMap(data: CultivoDataI) {
    this.cul_id = data.cul_id;
    this.cul_descripcion = data.cul_descripcion;
    this.cul_estado = data.cul_estado;
  }
}
