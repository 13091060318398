import { Button, Form, Input, Modal, Select, Space, Spin, Table } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { Cultivo } from "../../model/cultivo";
import { EstadoFenologico } from "../../model/estado_fenologico";
import { APICultivo } from "../../api/api_cultivo";
import CultivoContext from "../../provider/cultivo_provider";
import { APIEstadoFenologico } from "../../api/api_estado_fenologico";
import EstadoFenologicoContext from "../../provider/estado_fenologico_provider";
import { _getContextDashboard } from "./dashboard.page";
import { APIException } from "../../model/api_exception";
import {
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { APIResponse } from "../../model/api_response";
import LoadComponent from "../components/load.component";
import ErrorComponent from "../components/error.component";
import TitleWidget from "../util/title.widget";
import HeaderWidget from "../util/header.widget";
import LeyendaComponent from "../components/leyenda.component";
import ActivoWidget from "../util/activo.widget";

export default function EstadoFenologicoPage() {
  const [_flag, _setFlag] = useState<boolean>(true);
  const [_form] = Form.useForm();
  const [_cultivos, _setListaCultivo] = useState<Cultivo[]>([]);
  const [_estados, _setListaEstadoFenologico] = useState<EstadoFenologico[]>(
    []
  );
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_title, _setTitle] = useState<string>("");
  const [_visible, _setVisible] = useState<boolean>(false);
  const _apiCultivo = useContext<APICultivo>(CultivoContext);
  const _apiEstadoFenologico = useContext<APIEstadoFenologico>(
    EstadoFenologicoContext
  );
  const { _mobile } = _getContextDashboard();
  const { Option } = Select;

  const _listarCultivos = useCallback(async () => {
    await _apiCultivo
      ._listarCultivos()
      .then((value: Cultivo[]) => {
        _setListaCultivo(value);
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      });

    _setLoading(false);
  }, [_apiCultivo, _modal]);

  const _listarEstadoFenologicos = useCallback(async () => {
    await _apiEstadoFenologico
      ._listarEstadoFenologico()
      .then((value: EstadoFenologico[]) => {
        _setListaEstadoFenologico(value);
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      });

    _setLoading(false);
  }, [_apiEstadoFenologico, _modal]);

  const _renderAcciones = (
    _value: number,
    _row: EstadoFenologico,
    _index: number
  ) => {
    return (
      <Space>
        <EditOutlined
          style={{
            fontSize: 20,
            color: "rgb(76 175 80)",
          }}
          onClick={() => {
            _setFlag(false);
            _setTitle("Editar registro");
            _form.setFieldsValue({
              ef_id: _row.ef_id,
              ef_descripcion: _row.ef_descripcion,
              ef_estado: _row.ef_estado,
            });
            _setVisible(true);
          }}
        />
        <DeleteOutlined
          style={{
            fontSize: 20,
            color: "rgb(244 67 54)",
          }}
          onClick={() => {
            _eliminarEstadoFenologico(_row, 0);
          }}
        />
      </Space>
    );
  };

  const _registrarEstadoFenologico = async (
    _estado_fenologico: EstadoFenologico
  ) => {
    _modal.confirm({
      title: "Agregar registro",
      content: "¿Está seguro de agregar el registro",
      okText: "Si, registrar",
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },
      onOk: async () => {
        _setLoading(true);
        await _apiEstadoFenologico
          ._registrarEstadoFenologico(_estado_fenologico)
          .then((value: APIResponse) => {
            if (value.success) {
              _listarEstadoFenologicos();
              _form.resetFields();
              _modal.success({
                title: "Agregar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
                onOk: () => {
                  _setVisible(false);
                },
              });
            } else {
              _modal.error({
                title: "Agregar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: "Agregar registro",
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _editarEstadoFenologico = async (
    _estado_fenologico: EstadoFenologico
  ) => {
    _modal.confirm({
      title: "Actualizar registro",
      content: "¿Está seguro de actualizar el registro",
      okText: "Si, actualizar",
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },
      onOk: async () => {
        _setLoading(true);
        await _apiEstadoFenologico
          ._editarEstadoFenologico(_estado_fenologico)
          .then((value: APIResponse) => {
            if (value.success) {
              _listarEstadoFenologicos();
              _form.resetFields();
              _modal.success({
                title: "Editar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
                onOk: () => {
                  _setVisible(false);
                },
              });
            } else {
              _modal.error({
                title: "Editar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: "Editar registro",
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _eliminarEstadoFenologico = async (
    _estado_fenologico: EstadoFenologico,
    _estado: number
  ) => {
    _modal.confirm({
      title: "Eliminar registro",
      content:
        "¿Está seguro de eliminar el registro? Esta acción no se puede revertir",
      okText: "Si, eliminar",
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },

      onOk: async () => {
        _setLoading(true);
        await _apiEstadoFenologico
          ._eliminarEstadoFenologico(_estado_fenologico)
          .then((value: APIResponse) => {
            _listarEstadoFenologicos();
            if (value.success) {
              _modal.success({
                title: "Eliminar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            } else {
              _modal.error({
                title: "Eliminar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: "Eliminar registro",
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _exportExcel = (data: EstadoFenologico[]) => {
    const exportData = data.map((item) => ({
      CODIGO: item.ef_id,
      DESCRIPCION: item.ef_descripcion,
      ESTADO: item.ef_estado === 1 ? "ACTIVO" : "INACTIVO",
      CULTIVO: item.ef_cul_id?.cul_descripcion,
    }));
    // Crear un nuevo libro de trabajo
    const workbook = XLSX.utils.book_new();

    // Convertir los datos personalizados en una hoja de trabajo
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Añadir la hoja de trabajo al libro
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datos Exportados");

    // Exportar el libro a un archivo Excel
    XLSX.writeFile(workbook, "Estado fenologico.xlsx");
  };

  useEffect(() => {
    _setMounted(true);
    _listarEstadoFenologicos();
    return () => {
      _setMounted(false);
    };
  }, [_listarEstadoFenologicos]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <>
      <Spin spinning={_loading}>
        <TitleWidget data="Estado Fenológico" color="rgb(0 21 41)" size={30} />
        <hr />
        <HeaderWidget
          children={
            <>
              <LeyendaComponent
                mobile={_mobile}
                editar={true}
                borrar={true}
                activo={true}
              />
              <Space direction={_mobile ? "vertical" : "horizontal"}>
                <Button
                  icon={<FileExcelOutlined />}
                  style={{ width: 120 }}
                  onClick={() =>
                    _estados.length > 0
                      ? _exportExcel(_estados)
                      : _modal.warning({
                          title: "Exportar datos",
                          content: "No se econtró información para exportar",
                          centered: true,
                        })
                  }
                >
                  Exportar
                </Button>
                <Button
                  icon={<PlusCircleOutlined />}
                  type="primary"
                  onClick={() => {
                    _listarCultivos();
                    _setFlag(true);
                    _form.resetFields();
                    _form.setFieldValue("ef_estado", 1);
                    _setTitle("Agregar registro");
                    _setVisible(true);
                  }}
                >
                  Agregar
                </Button>
              </Space>
            </>
          }
        />
        <Table
          loading={_loading}
          rowKey={"ef_id"}
          pagination={{ size: "small" }}
          columns={[
            {
              title: "Estado",
              key: "ef_estado",
              dataIndex: "ef_estado",
              width: "10%",
              render: (
                _value: number,
                _row: EstadoFenologico,
                _index: number
              ) => {
                return <ActivoWidget margin="auto" />;
              },
            },
            {
              title: "Descripción",
              key: "ef_descripcion",
              dataIndex: "ef_descripcion",
              width: "60%",
              sorter: (a: EstadoFenologico, b: EstadoFenologico) =>
                a.ef_descripcion.localeCompare(b.ef_descripcion),
            },
            {
              title: "Cultivo",
              key: "ef_cul_id",
              dataIndex: "ef_cul_id",
              width: "20%",
              sorter: (a: EstadoFenologico, b: EstadoFenologico) =>
                a.ef_cul_id.cul_descripcion.localeCompare(
                  b.ef_cul_id.cul_descripcion
                ),
              render: (
                _value: Cultivo,
                _row: EstadoFenologico,
                _index: number
              ) => {
                return _value.cul_descripcion;
              },
            },
            {
              title: "Acciones",
              key: "ef_estado",
              dataIndex: "ef_estado",
              width: "10%",
              align: "center",
              render: _renderAcciones,
            },
          ]}
          dataSource={_estados}
        />

        {/* Modal de edición */}
        <Modal
          title={_title}
          visible={_visible}
          onCancel={() => {
            _setVisible(false);
          }}
          onOk={() => {
            _form.validateFields().then((values: any) => {
              _flag
                ? _registrarEstadoFenologico(new EstadoFenologico(values))
                : _editarEstadoFenologico(new EstadoFenologico(values));
            });
          }}
          okButtonProps={{ danger: true, type: "dashed" }}
          cancelButtonProps={{
            type: "primary",
          }}
          okText="Aceptar"
          cancelText="Cancelar"
          centered
        >
          <Form form={_form} layout="vertical" name="form_in_modal">
            <Form.Item name="ef_id" label="Código" hidden>
              <Input placeholder="Código" readOnly />
            </Form.Item>
            <Form.Item
              name="ef_cul_id"
              label="Cultivo"
              hidden={!_flag}
              rules={
                !_flag
                  ? []
                  : [
                      {
                        required: true,
                        message: "¡Por favor selecciona un cultivo!",
                      },
                    ]
              }
            >
              <Select
                showSearch
                placeholder="Selecciona un cultivo"
                optionFilterProp="children"
                filterOption={(input: string, option: any) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {_cultivos.map((value: Cultivo, _index: number) => {
                  return (
                    <Option
                      key={`option_${value.cul_id}`}
                      value={`${value.cul_id}`}
                    >
                      {value.cul_descripcion}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="ef_descripcion"
              label="Descripción"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa la descripción!",
                },
              ]}
            >
              <Input placeholder="Descripción" />
            </Form.Item>
            <Form.Item name="ef_estado" label="Estado" hidden>
              <Input placeholder="Estado" readOnly />
            </Form.Item>
          </Form>
        </Modal>

        {_contextHolder}
      </Spin>
    </>
  );
}
