import * as XLSX from "xlsx";
import ActivoWidget from "../util/activo.widget";
import CensoAgrarioContext from "../../provider/censo_agrario_provider";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import InactivoWidget from "../util/inactivo.widget";
import LeyendaComponent from "../components/leyenda.component";
import LoadComponent from "../components/load.component";
import locale from "antd/es/date-picker/locale/es_ES";
import MapaComponent from "../components/mapa.component";
import moment from "moment";
import PendienteWidget from "../util/pendiente.widget";
import TitleWidget from "../util/title.widget";
import UsuarioContext from "../../provider/usuario_provider";
import { _getContextDashboard } from "./dashboard.page";
import { APICensoAgrario } from "../../api/api_censo_agrario";
import { APIException } from "../../model/api_exception";
import { APIResponse } from "../../model/api_response";
import { APIUsuario } from "../../api/api_usuario";
import { CausaReconversion } from "../../model/causa_reconversion";
import { CensoAgrario } from "../../model/censo_agrario";
import { Cliente } from "../../model/cliente";
import { ControlCultural } from "../../model/control_cultural";
import { Cultivo } from "../../model/cultivo";
import { Distrito } from "../../model/distrito";
import { EstadoCivil } from "../../model/estado_civil";
import { EstadoFisicoLegal } from "../../model/estado_fisico_legal";
import { GradoInstruccion } from "../../model/grado_instruccion";
import { Institucion } from "../../model/institucion";
import { Maderable } from "../../model/maderable";
import { Pendiente } from "../../model/pendiente";
import { PerfilContext } from "../../context/perfil-context";
import { Producto } from "../../model/producto";
import { Rendimiento } from "../../model/rendimiento";
import { Riego } from "../../model/riego";
import { SistemaSiembra } from "../../model/sistema_siembra";
import { TipoAbonamiento } from "../../model/tipo_abonamiento";
import { TipoAbono } from "../../model/tipo_abono";
import { TipoFuenteAgua } from "../../model/tipo_fuente_agua";
import { TipoVenta } from "../../model/tipo_venta";
import { TipoVia } from "../../model/tipo_via";
import { Transporte } from "../../model/transporte";
import { useCallback, useContext, useEffect, useState } from "react";
import { Usuario } from "../../model/usuario";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Image,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  FileExcelOutlined,
  PushpinFilled,
} from "@ant-design/icons";

const { ESTADOS, URL_FOTOS, ERROR_IMAGE } = Global;
export default function CensoAgrarioPage() {
  const [_censo, _setCenso] = useState<CensoAgrario>();
  const [_censos, _setCensos] = useState<CensoAgrario[]>([]);
  const [_dateFin, _setDateFin] = useState<any>();
  const [_dateIni, _setDateIni] = useState<any>();
  const [_estado, _setEstado] = useState<number>(3);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_usuario, _setUsuario] = useState<number>(0);
  const [_usuarios, _setUsuarios] = useState<Usuario[]>([]);
  const [_visible, _setVisible] = useState<boolean>(false);
  const [aprobados, setAprobados] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [pendientes, setPendientes] = useState<number>(0);
  const [rechazados, setRechazados] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const _apiCensoAgrario = useContext<APICensoAgrario>(CensoAgrarioContext);
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const { _mobile } = _getContextDashboard();
  const { _perfil } = useContext(PerfilContext);
  const { Option } = Select;
  const { Panel } = Collapse;

  const _listarUsuarios = useCallback(async () => {
    await _apiUsuario._listarUsuarios().then((value: Usuario[]) => {
      _setUsuarios(value);
    });
  }, [_apiUsuario]);

  const _listarCensoAgrario = useCallback(async () => {
    _setLoading(true);
    await _apiCensoAgrario
      ._listarCensoAgrario(
        _usuario,
        _dateIni,
        _dateFin,
        _estado,
        page,
        pageSize
      )
      .then((value) => {
        _setCensos(value.data);
        setTotalItems(value.totalItems || 0);
        setPendientes(value.pendientes || 0);
        setAprobados(value.aprobados || 0);
        setRechazados(value.rechazados || 0);
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      })
      .finally(() => {
        _setLoading(false);
      });
  }, [
    _apiCensoAgrario,
    _dateFin,
    _dateIni,
    _estado,
    _modal,
    _usuario,
    page,
    pageSize,
  ]);

  const _renderEstado = (
    _value: number,
    _row: CensoAgrario,
    _index: number
  ) => {
    if (_value === 0) {
      return <InactivoWidget margin="auto" />;
    }
    if (_value === 1) {
      return <PendienteWidget margin="auto" />;
    }
    if (_value === 2) {
      return <ActivoWidget margin="auto" />;
    }
  };

  const _renderAcciones = (
    _value: number,
    _row: CensoAgrario,
    _index: number
  ) => {
    return _value === 1 && _perfil === 1 ? (
      <Space>
        <CheckOutlined
          style={{
            fontSize: 20,
            color: "rgb(76 175 80)",
          }}
          onClick={() => {
            _actualizarCensoAgrario(_row, 2);
          }}
        />
        <CloseOutlined
          style={{
            fontSize: 20,
            color: "rgb(244 67 54)",
          }}
          onClick={() => {
            _actualizarCensoAgrario(_row, 0);
          }}
        />
        <PushpinFilled
          style={{
            fontSize: 20,
            color: "rgb(0 188 212)",
          }}
          onClick={() => {
            _setCenso(_row);
            _setVisible(true);
          }}
        />
      </Space>
    ) : (
      <Space>
        <PushpinFilled
          style={{
            fontSize: 20,
            color: "rgb(0 188 212)",
          }}
          onClick={() => {
            _setCenso(_row);
            _setVisible(true);
          }}
        />
      </Space>
    );
  };

  const _actualizarCensoAgrario = async (
    _censo: CensoAgrario,
    _estado: number
  ) => {
    _modal.confirm({
      title: `${_estado === 2 ? "Aceptar" : "Rechazar"} registro`,
      content:
        "¿Está seguro de actualizar el registro? Esta acción no se puede revertir",
      okText: `Si, ${_estado === 2 ? "aceptar" : "rechazar"}`,
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },
      onOk: async () => {
        _setLoading(true);
        await _apiCensoAgrario
          ._actualizarCensoAgrario(_censo, _estado)
          .then((value: APIResponse) => {
            _listarCensoAgrario();
            if (value.success) {
              _modal.success({
                title: "Actualizar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            } else {
              _modal.error({
                title: "Actualizar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: "Actualizar registro",
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _exportExcel = async () => {
    _setLoading(true);
    await _apiCensoAgrario
      ._listarCensoAgrario(_usuario, _dateIni, _dateFin, _estado)
      .then((value) => {
        if (value.totalItems > 0) {
          const data: CensoAgrario[] = value.data;
          const exportData = data.map((item) => ({
            CODIGO: item.cagnew_id,
            DEPARTAMENTO:
              item.cagnew_id_ubicacion?.dis_pro_id?.pro_dep_id?.dep_nombre,
            PROVINCIA: item.cagnew_id_ubicacion?.dis_pro_id?.pro_nombre,
            DISTRITO: item.cagnew_id_ubicacion?.dis_nombre,
            LOCALIDAD: item.cagnew_localidad,
            SECTOR: item.cagnew_sector,
            NOMBRE: item.cagnew_nombre,
            TELEFONO: item.cagnew_telefono,
            DNI: item.cagnew_dni,
            EDAD: item.cagnew_edad,
            "GRADO DE INSTRUCCIÓN": item.cagnew_gi_id?.gi_descripcion,
            "ESTADO CIVIL": item.cagnew_ec_id?.ec_descripcion,
            "NATURAL DE": item.cagnew_natural_de,
            "DEPARTAMENTO P":
              item.cagnew_dis_id_personal?.dis_pro_id?.pro_dep_id?.dep_nombre,
            "PROVINCIA P": item.cagnew_dis_id_personal?.dis_pro_id?.pro_nombre,
            "DISTRITO P": item.cagnew_dis_id_personal?.dis_nombre,
            "ESTADO FISICO LEGAL": item.cagnew_efl_id?.efl_descripcion,
            "AREA TOTAL": item.cagnew_area_total,
            TOPOGRAFÍA: item.cagnew_pen_id?.pen_descripcion,
            "AREA EN PRODUCCION": item.cagnew_area_produccion,
            "AREA CONSERVADA": item.cagnew_area_conservada,
            "FUENTE DE AGUA CERCANA": item.cagnew_fuente_agua_cercana,
            "TIPO DE FUENTE DE AGUA": item.cagnew_fa_id?.tfa_descripcion,
            ANTECEDENTES: item.cagnew_antecedentes,
            "CAUSA DE RECONVERSION": item.cagnew_cre_id?.cre_descripcion,
            "OTROS CULTIVOS": item.cagnew_otros_cultivos,
            "CULTIVO PRINCIPAL": item.cagnew_cul_principal_id?.cul_descripcion,            
            "CULTIVOS EN EL CAMPO": item.cagnew_cultivos_en_campo
              ?.map((x) => x.cul_descripcion)
              .join(", "),
            "HECTAREAS EN CRECIMIENTO": item.cagnew_ha_crecimiento,
            "FECHA C": item.cagnew_fecha_crecimiento,
            "EDAD C": item.cagnew_edad_crecimiento,
            "HECTAREAS EN PRODUCCION": item.cagnew_ha_produccion,
            "FECHA P": item.cagnew_fecha_crecimiento,
            "EDAD P": item.cagnew_edad_crecimiento,
            RENDIMIENTO: item.cagnew_id_rendimiento?.ren_descripcion,
            "RENDIMIENTO CAMP1": item.cagnew_cam1_rendimiento,
            "RENDIMIENTO CAMP2": item.cagnew_cam2_rendimiento,
            VARIEDAD: item.cagnew_variedad,
            "VARIEDAD D": item.cagnew_variedad_id?.var_descripcion,
            "ESTADO FENOLOGICO": item.cagnew_ef_id?.ef_descripcion,
            COLORACION: item.cagnew_id_coloracion?.col_descripcion,
            ALTURA: item.cagnew_altura,
            "FECHA DE COSECHA": item.cagnew_fecha_cosecha,
            DIAMETRO: item.cagnew_diametro,
            PODA: item.cagnew_poda_id?.pod_descripcion,
            DISTANCIAMIENTO: item.cagnew_distanciamiento,
            "SISTEMA DE SIEMBRA": item.cagnew_sis_id?.sis_descripcion,
            "OTRO SISTEMA": item.cagnew_sis_otros,
            "CULTIVO ASOCIADO": item.cagnew_cul_id_asociado?.cul_descripcion,
            MADERABLE: item.cagnew_mad_id?.mad_descripcion,
            "APLICA ABONAMIENTO": item.cagnew_aplica_abonamiento,
            "TIPO DE ABONAMIENTO": item.cagnew_tab_id?.tab_descripcion,
            "TIPO DE ABONO": item.cagnew_ta_id?.ta_descripcion,
            "APLICA RIEGO": item.cagnew_aplica_riego,
            "TIPO DE RIEGO": item.cagnew_rie_id?.rie_descripcion,
            "PRESENCIA DE PLAGAS": item.cagnew_presencia_plagas,
            PLAGAS: item.cagnew_plagas
              ?.map((x) => x.plag_descripcion)
              .join(", "),
            "PORCENTAJE DE PLAGAS": item.cagnew_porcentaje_plagas,
            "RECIBE ASISTENCIA TECNICA": item.cagnew_recibe_asistencia_tecnica,
            INSTITUCION: item.cagnew_ins_id_asistencia?.ins_descripcion,
            "APLICA CONTROL CULTURAL": item.cagnew_aplica_cc,
            "CONTROL CULTURAL": item.cagnew_cc_id?.cc_descripcion,
            "APLICA CONTROL QUIMICO": item.cagnew_aplica_cq,
            "CONTROL QUIMICO": item.cagnew_cq_id
              ?.map((x) => x.cq_descripcion)
              .join(", "),
            "PERTENECE A ORGANIZACION": item.cagnew_pertenece_organizacion,
            ORGANIZACION: item.cagnew_organizacion,
            MAQUINARIA: item.cagnew_maquinaria,
            "COSTO MAQUINARIA": item.cagnew_costo_maquinaria,
            "PRECIO JORNADA": item.cagnew_precio_jornal,
            CLIENTE: item.cagnew_cli_id?.cli_descripcion,
            "TIPO DE VENTA": item.cagnew_tve_id?.tve_descripcion,
            PRODUCTO: item.cagnew_pro_id?.pro_descripcion,
            "TIPO DE VIA DE ACCESO": item.cagnew_tvi_id?.tvi_descripcion,
            TRANSPORTE: item.cagnew_tra_id?.tra_descripcion,
            FINANCIAMIENTO: item.cagnew_financiamiento,
            "INSTITUCION F": item.cagnew_ins_id_financiamiento?.ins_descripcion,
            OBSERVACIONES: item.cagnew_observaciones,
            ALTITUD: item.cagnew_altitud,
            LATITUD: item.cagnew_latitud,
            LONGITUD: item.cagnew_longitud,
            "FECHA DE VISITA": item.cagnew_fecha_visita,
            "FECHA DE SINCRONIZACION": item.cagnew_sync_up_date,
            USUARIO: item.cagnew_usu_id?.usu_nombres,
            ESTADO: item.cagnew_estado === 1 ? "PENDIENTE" : "ACEPTADO",
          }));

          // Crear un nuevo libro de trabajo
          const workbook = XLSX.utils.book_new();

          // Convertir los datos personalizados en una hoja de trabajo
          const worksheet = XLSX.utils.json_to_sheet(exportData);

          // Añadir la hoja de trabajo al libro
          XLSX.utils.book_append_sheet(workbook, worksheet, "Datos Exportados");

          // Exportar el libro a un archivo Excel
          XLSX.writeFile(workbook, "Censo agrario.xlsx");
        }
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      })
      .finally(() => {
        _setLoading(false);
      });
  };

  const _changeDateIni = (_moment: any, _date: any) => {
    if (!_date) {
      _setDateIni(null);
    } else {
      _setDateIni(_date);
    }
  };

  const _changeDateFin = (_moment: any, _date: any) => {
    if (!_date) {
      _setDateFin(null);
    } else {
      _setDateFin(_date);
    }
  };

  useEffect(() => {
    _setMounted(true);
    _listarUsuarios();
    _listarCensoAgrario();
    return () => {
      _setMounted(false);
    };
  }, [_listarCensoAgrario, _listarUsuarios]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <>
      <TitleWidget data="Censo agrario" color="rgb(0 21 41)" size={30} />

      <Collapse>
        <Panel header="Filtros de búsqueda" key={1}>
          <Row gutter={[24, 24]} style={{ padding: 15 }}>
            {_perfil === 1 && (
              <>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <label>Usuario</label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    allowClear
                    placeholder="Selecciona un usuario"
                    optionFilterProp="children"
                    filterOption={(input: string, option: any) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(usuario: number) => {
                      _setUsuario(usuario);
                    }}
                  >
                    {_usuarios.map((value: Usuario, _index: number) => {
                      return (
                        <Option
                          key={`option_usu_${value.usu_id}`}
                          value={`${value.usu_id}`}
                        >
                          {value.usu_nombres}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>

                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <label>Estado</label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    placeholder="Selecciona un estado"
                    onChange={(estado: number) => {
                      _setEstado(estado);
                    }}
                  >
                    {ESTADOS.map(
                      (
                        value: { text: string; value: number },
                        _index: number
                      ) => {
                        return (
                          <Option
                            key={`option_estado_${value.value}`}
                            value={`${value.value}`}
                          >
                            {value.text}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Col>
              </>
            )}

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Fecha de inicio</label>
              <DatePicker
                style={{ width: "100%" }}
                allowClear
                locale={locale}
                onChange={_changeDateIni}
                format={"YYYY-MM-DD"}
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Fecha de fin</label>
              <DatePicker
                style={{ width: "100%" }}
                allowClear
                locale={locale}
                onChange={_changeDateFin}
                format={"YYYY-MM-DD"}
              />
            </Col>

            <Col xs={24}>
              <Space>
                <Tag color="default">
                  Todos: {aprobados + pendientes + rechazados}
                </Tag>
                <Tag color="success">Aprobados: {aprobados}</Tag>
                <Tag color="warning">Pendientes: {pendientes}</Tag>
                <Tag color="error">Rechazados: {rechazados}</Tag>
              </Space>
            </Col>
          </Row>
        </Panel>
      </Collapse>

      <Space
        direction="vertical"
        style={{ width: "100%", margin: "20px 10px" }}
      >
        <Space
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Pagination
            current={page}
            disabled={_censos.length === 0}
            pageSize={pageSize}
            showSizeChanger
            size="small"
            total={totalItems}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
          />

          <Button
            icon={<FileExcelOutlined />}
            style={{ width: 120 }}
            onClick={_exportExcel}
            loading={_loading}
          >
            Exportar
          </Button>
        </Space>

        <LeyendaComponent
          mobile={_mobile}
          aceptar={_perfil === 1 ? true : false}
          rechazar={_perfil === 1 ? true : false}
          ubicacion={true}
        />

        <Table
          loading={_loading}
          rowKey={"cagnew_id"}
          pagination={false}
          columns={[
            {
              title: "Acciones",
              key: "cagnew_estado",
              dataIndex: "cagnew_estado",
              align: "center",
              render: _renderAcciones,
            },
            {
              title: "Estado",
              key: "cagnew_estado",
              dataIndex: "cagnew_estado",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_estado - b.cagnew_estado,
              showSorterTooltip: false,
              render: _renderEstado,
            },
            {
              title: "Código",
              key: "cagnew_id",
              dataIndex: "cagnew_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_id - b.cagnew_id,
            },
            {
              title: "Distrito (ubicación)",
              key: "cagnew_id_ubicacion",
              dataIndex: "cagnew_id_ubicacion",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_id_ubicacion.dis_nombre.localeCompare(
                  b.cagnew_id_ubicacion.dis_nombre
                ),
              render: (
                _value: Distrito,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.dis_nombre;
              },
            },
            {
              title: "Localidad",
              key: "cagnew_localidad",
              dataIndex: "cagnew_localidad",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_localidad.localeCompare(b.cagnew_localidad),
            },
            {
              title: "Sector",
              key: "cagnew_sector",
              dataIndex: "cagnew_sector",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_sector.localeCompare(b.cagnew_sector),
            },
            {
              title: "Nombre",
              key: "cagnew_nombre",
              dataIndex: "cagnew_nombre",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_nombre.localeCompare(b.cagnew_nombre),
            },
            {
              title: "Teléfono",
              key: "cagnew_telefono",
              dataIndex: "cagnew_telefono",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_telefono.localeCompare(b.cagnew_telefono),
            },
            {
              title: "DNI",
              key: "cagnew_dni",
              dataIndex: "cagnew_dni",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_dni.localeCompare(b.cagnew_dni),
            },
            {
              title: "Edad",
              key: "cagnew_edad",
              dataIndex: "cagnew_edad",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_edad.localeCompare(b.cagnew_edad),
            },
            {
              title: "Grado de instrucción",
              key: "cagnew_gi_id",
              dataIndex: "cagnew_gi_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_gi_id.gi_descripcion.localeCompare(
                  b.cagnew_gi_id.gi_descripcion
                ),
              render: (
                _value: GradoInstruccion,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.gi_descripcion;
              },
            },
            {
              title: "Estado civil",
              key: "cagnew_ec_id",
              dataIndex: "cagnew_ec_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_ec_id.ec_descripcion.localeCompare(
                  b.cagnew_ec_id.ec_descripcion
                ),
              render: (
                _value: EstadoCivil,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.ec_descripcion;
              },
            },
            {
              title: "Natural de",
              key: "cagnew_natural_de",
              dataIndex: "cagnew_natural_de",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_natural_de.localeCompare(b.cagnew_natural_de),
            },
            {
              title: "Departamento (personal)",
              key: "cagnew_dis_id_personal",
              dataIndex: "cagnew_dis_id_personal",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_dis_id_personal.dis_pro_id.pro_dep_id.dep_nombre.localeCompare(
                  b.cagnew_dis_id_personal.dis_pro_id.pro_dep_id.dep_nombre
                ),
              render: (
                _value: Distrito,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.dis_pro_id.pro_dep_id.dep_nombre;
              },
            },
            {
              title: "Provincia (personal)",
              key: "cagnew_dis_id_personal",
              dataIndex: "cagnew_dis_id_personal",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_dis_id_personal.dis_pro_id.pro_nombre.localeCompare(
                  b.cagnew_dis_id_personal.dis_pro_id.pro_nombre
                ),
              render: (
                _value: Distrito,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.dis_pro_id.pro_nombre;
              },
            },
            {
              title: "Distrito (personal)",
              key: "cagnew_dis_id_personal",
              dataIndex: "cagnew_dis_id_personal",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_dis_id_personal.dis_nombre.localeCompare(
                  b.cagnew_dis_id_personal.dis_nombre
                ),
              render: (
                _value: Distrito,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.dis_nombre;
              },
            },
            {
              title: "Estado físico legal",
              key: "cagnew_efl_id",
              dataIndex: "cagnew_efl_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_efl_id.efl_descripcion.localeCompare(
                  b.cagnew_efl_id.efl_descripcion
                ),
              render: (
                _value: EstadoFisicoLegal,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.efl_descripcion;
              },
            },
            {
              title: "Área total",
              key: "cagnew_area_total",
              dataIndex: "cagnew_area_total",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_area_total - b.cagnew_area_total,
            },
            {
              title: "Topografía",
              key: "cagnew_pen_id",
              dataIndex: "cagnew_pen_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_pen_id.pen_descripcion.localeCompare(
                  b.cagnew_pen_id.pen_descripcion
                ),
              render: (
                _value: Pendiente,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.pen_descripcion;
              },
            },
            {
              title: "Área en producción",
              key: "cagnew_area_produccion",
              dataIndex: "cagnew_area_produccion",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_area_produccion - b.cagnew_area_produccion,
            },
            {
              title: "Área conservada",
              key: "cagnew_area_conservada",
              dataIndex: "cagnew_area_conservada",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_area_conservada - b.cagnew_area_conservada,
            },
            {
              title: "Fuente de agua cercana",
              key: "cagnew_fuente_agua_cercana",
              dataIndex: "cagnew_fuente_agua_cercana",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_fuente_agua_cercana.localeCompare(
                  b.cagnew_fuente_agua_cercana
                ),
            },
            {
              title: "Fuente de agua",
              key: "cagnew_fa_id",
              dataIndex: "cagnew_fa_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_fa_id.tfa_descripcion.localeCompare(
                  b.cagnew_fa_id.tfa_descripcion
                ),
              render: (
                _value: TipoFuenteAgua,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _row.cagnew_fuente_agua_cercana === "SI"
                  ? _value
                    ? _value.tfa_descripcion
                    : "---"
                  : "---";
              },
            },
            {
              title: "Antecedentes",
              key: "cagnew_antecedentes",
              dataIndex: "cagnew_antecedentes",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_antecedentes.localeCompare(b.cagnew_antecedentes),
            },
            {
              title: "Causa de reconversión",
              key: "cagnew_cre_id",
              dataIndex: "cagnew_cre_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_cre_id.cre_descripcion.localeCompare(
                  b.cagnew_cre_id.cre_descripcion
                ),
              render: (
                _value: CausaReconversion,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.cre_descripcion;
              },
            },
            {
              title: "Cultivo principal",
              key: "cagnew_cul_principal_id",
              dataIndex: "cagnew_cul_principal_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_cul_principal_id.cul_descripcion.localeCompare(
                  b.cagnew_cul_principal_id.cul_descripcion
                ),
              render: (_value: Cultivo, _row: CensoAgrario, _index: number) => {
                return _value.cul_descripcion;
              },
            },
            {
              title: "Cultivos en el campo",
              key: "cagnew_cultivos_en_campo",
              dataIndex: "cagnew_cultivos_en_campo",
              render: (
                _value: Cultivo[],
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.map((cultivo: Cultivo, index: number) => {
                  return (
                    <p key={`cagnew_cul_campo_${index}${cultivo.cul_id}`}>
                      {cultivo.cul_descripcion}
                    </p>
                  );
                });
              },
            },
            {
              title: "Hectáreas en crecimiento",
              key: "cagnew_ha_crecimiento",
              dataIndex: "cagnew_ha_crecimiento",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_ha_crecimiento - b.cagnew_ha_crecimiento,
            },
            {
              title: "Hectáreas en crecimiento (edad)",
              key: "cagnew_edad_crecimiento",
              dataIndex: "cagnew_edad_crecimiento",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_edad_crecimiento - b.cagnew_edad_crecimiento,
            },
            {
              title: "Hectáreas en produccion",
              key: "cagnew_ha_produccion",
              dataIndex: "cagnew_ha_produccion",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_ha_produccion - b.cagnew_ha_produccion,
            },
            {
              title: "Hectáreas en produccion (edad)",
              key: "cagnew_edad_produccion",
              dataIndex: "cagnew_edad_produccion",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_edad_produccion - b.cagnew_edad_produccion,
            },
            {
              title: "Rendimiento",
              key: "cagnew_id_rendimiento",
              dataIndex: "cagnew_id_rendimiento",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_id_rendimiento.ren_descripcion.localeCompare(
                  b.cagnew_id_rendimiento.ren_descripcion
                ),
              render: (value: Rendimiento) => value?.ren_descripcion || "",
            },
            {
              title: "Variedad",
              key: "cagnew_variedad",
              dataIndex: "cagnew_variedad",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_variedad.localeCompare(b.cagnew_variedad),
            },
            {
              title: "Distanciamiento",
              key: "cagnew_distanciamiento",
              dataIndex: "cagnew_distanciamiento",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_distanciamiento.localeCompare(
                  b.cagnew_distanciamiento
                ),
            },
            {
              title: "Sistema de siembra",
              key: "cagnew_sis_id",
              dataIndex: "cagnew_sis_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_sis_id.sis_descripcion.localeCompare(
                  b.cagnew_sis_id.sis_descripcion
                ),
              render: (
                _value: SistemaSiembra,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.sis_descripcion;
              },
            },
            {
              title: "Cultivo asociado",
              key: "cagnew_cul_id_asociado",
              dataIndex: "cagnew_cul_id_asociado",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_cul_id_asociado.cul_descripcion.localeCompare(
                  b.cagnew_cul_id_asociado.cul_descripcion
                ),
              render: (_value: Cultivo, _row: CensoAgrario, _index: number) => {
                return _value.cul_descripcion;
              },
            },
            {
              title: "Maderable asociado",
              key: "cagnew_mad_id",
              dataIndex: "cagnew_mad_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_mad_id.mad_descripcion.localeCompare(
                  b.cagnew_mad_id.mad_descripcion
                ),
              render: (
                _value: Maderable,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.mad_descripcion;
              },
            },
            {
              title: "Aplica abonamiento",
              key: "cagnew_aplica_abonamiento",
              dataIndex: "cagnew_aplica_abonamiento",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_aplica_abonamiento.localeCompare(
                  b.cagnew_aplica_abonamiento
                ),
            },
            {
              title: "Tipo de abonamiento",
              key: "cagnew_tab_id",
              dataIndex: "cagnew_tab_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_tab_id.tab_descripcion.localeCompare(
                  b.cagnew_tab_id.tab_descripcion
                ),
              render: (
                _value: TipoAbonamiento,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _row.cagnew_aplica_abonamiento === "SI"
                  ? _value.tab_descripcion
                  : "---";
              },
            },
            {
              title: "Tipo de abono",
              key: "cagnew_ta_id",
              dataIndex: "cagnew_ta_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_ta_id.ta_descripcion.localeCompare(
                  b.cagnew_ta_id.ta_descripcion
                ),
              render: (
                _value: TipoAbono,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _row.cagnew_aplica_abonamiento === "SI"
                  ? _value
                    ? _value.ta_descripcion
                    : "---"
                  : "---";
              },
            },
            {
              title: "Aplica riego",
              key: "cagnew_aplica_riego",
              dataIndex: "cagnew_aplica_riego",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_aplica_riego.localeCompare(b.cagnew_aplica_riego),
            },
            {
              title: "Tipo de riego",
              key: "cagnew_rie_id",
              dataIndex: "cagnew_rie_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_rie_id.rie_descripcion.localeCompare(
                  b.cagnew_rie_id.rie_descripcion
                ),
              render: (_value: Riego, _row: CensoAgrario, _index: number) => {
                return _row.cagnew_aplica_riego === "SI"
                  ? _value.rie_descripcion
                  : "---";
              },
            },
            {
              title: "Presencia de plagas",
              key: "cagnew_presencia_plagas",
              dataIndex: "cagnew_presencia_plagas",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_presencia_plagas.localeCompare(
                  b.cagnew_presencia_plagas
                ),
            },
            {
              title: "Recibe asistencia técnica",
              key: "cagnew_recibe_asistencia_tecnica",
              dataIndex: "cagnew_recibe_asistencia_tecnica",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_recibe_asistencia_tecnica.localeCompare(
                  b.cagnew_recibe_asistencia_tecnica
                ),
            },
            {
              title: "Institución",
              key: "cagnew_ins_id_asistencia",
              dataIndex: "cagnew_ins_id_asistencia",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_ins_id_asistencia.ins_descripcion.localeCompare(
                  b.cagnew_ins_id_asistencia.ins_descripcion
                ),
              render: (
                _value: Institucion,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _row.cagnew_recibe_asistencia_tecnica === "SI"
                  ? _value.ins_descripcion
                  : "---";
              },
            },
            {
              title: "Aplica control cultural",
              key: "cagnew_aplica_cc",
              dataIndex: "cagnew_aplica_cc",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_aplica_cc.localeCompare(b.cagnew_aplica_cc),
            },
            {
              title: "Control cultural",
              key: "cagnew_cc_id",
              dataIndex: "cagnew_cc_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_cc_id.cc_descripcion.localeCompare(
                  b.cagnew_cc_id.cc_descripcion
                ),
              render: (
                _value: ControlCultural,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _row.cagnew_aplica_cc === "SI"
                  ? _value.cc_descripcion
                  : "---";
              },
            },
            {
              title: "Aplica control quimico",
              key: "cagnew_aplica_cq",
              dataIndex: "cagnew_aplica_cq",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_aplica_cq.localeCompare(b.cagnew_aplica_cq),
            },
            {
              title: "Pertenece a organización",
              key: "cagnew_pertenece_organizacion",
              dataIndex: "cagnew_pertenece_organizacion",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_pertenece_organizacion.localeCompare(
                  b.cagnew_pertenece_organizacion
                ),
            },
            {
              title: "Organización",
              key: "cagnew_organizacion",
              dataIndex: "cagnew_organizacion",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_organizacion.localeCompare(b.cagnew_organizacion),
              render: (_value: string, _row: CensoAgrario, _index: number) => {
                return _row.cagnew_pertenece_organizacion === "SI"
                  ? _value
                  : "---";
              },
            },
            {
              title: "Utiliza maquinaria",
              key: "cagnew_maquinaria",
              dataIndex: "cagnew_maquinaria",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_maquinaria.localeCompare(b.cagnew_maquinaria),
            },
            {
              title: "Costo hora/día",
              key: "cagnew_costo_maquinaria",
              dataIndex: "cagnew_costo_maquinaria",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_costo_maquinaria - b.cagnew_costo_maquinaria,
              render: (_value: string, _row: CensoAgrario, _index: number) => {
                return _row.cagnew_maquinaria === "SI" ? _value : "---";
              },
            },
            {
              title: "Precio jornal",
              key: "cagnew_precio_jornal",
              dataIndex: "cagnew_precio_jornal",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_precio_jornal - b.cagnew_precio_jornal,
              render: (_value: string, _row: CensoAgrario, _index: number) => {
                return _row.cagnew_maquinaria === "SI" ? _value : "---";
              },
            },
            {
              title: "A quien vende",
              key: "cagnew_cli_id",
              dataIndex: "cagnew_cli_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_cli_id.cli_descripcion.localeCompare(
                  b.cagnew_cli_id.cli_descripcion
                ),
              render: (_value: Cliente, _row: CensoAgrario, _index: number) => {
                return _value.cli_descripcion;
              },
            },
            {
              title: "Tipo de venta",
              key: "cagnew_tve_id",
              dataIndex: "cagnew_tve_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_tve_id.tve_descripcion.localeCompare(
                  b.cagnew_tve_id.tve_descripcion
                ),
              render: (
                _value: TipoVenta,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.tve_descripcion;
              },
            },
            {
              title: "Producto",
              key: "cagnew_pro_id",
              dataIndex: "cagnew_pro_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_pro_id.pro_descripcion.localeCompare(
                  b.cagnew_pro_id.pro_descripcion
                ),
              render: (
                _value: Producto,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.pro_descripcion;
              },
            },
            {
              title: "Tipo de vía",
              key: "cagnew_tvi_id",
              dataIndex: "cagnew_tvi_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_tvi_id.tvi_descripcion.localeCompare(
                  b.cagnew_tvi_id.tvi_descripcion
                ),
              render: (_value: TipoVia, _row: CensoAgrario, _index: number) => {
                return _value.tvi_descripcion;
              },
            },
            {
              title: "Tipo de transporte",
              key: "cagnew_tra_id",
              dataIndex: "cagnew_tra_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_tra_id.tra_descripcion.localeCompare(
                  b.cagnew_tra_id.tra_descripcion
                ),
              render: (
                _value: Transporte,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _value.tra_descripcion;
              },
            },
            {
              title: "Recibe financiamiento",
              key: "cagnew_financiamiento",
              dataIndex: "cagnew_financiamiento",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_financiamiento.localeCompare(b.cagnew_financiamiento),
            },
            {
              title: "Institución",
              key: "cagnew_ins_id_financiamiento",
              dataIndex: "cagnew_ins_id_financiamiento",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_ins_id_financiamiento.ins_descripcion.localeCompare(
                  b.cagnew_ins_id_financiamiento.ins_descripcion
                ),
              render: (
                _value: Institucion,
                _row: CensoAgrario,
                _index: number
              ) => {
                return _row.cagnew_financiamiento === "SI"
                  ? _value.ins_descripcion
                  : "---";
              },
            },
            {
              title: "Ubicación",
              key: "cagnew_latitud",
              dataIndex: "cagnew_latitud",
              render: (_value: number, _row: CensoAgrario, _index: number) => {
                return `Lat.: ${_row.cagnew_latitud} Lng.: ${_row.cagnew_longitud}`;
              },
            },
            {
              title: "Foto",
              key: "cagnew_foto",
              dataIndex: "cagnew_foto",
              render: (_value: string, _row: CensoAgrario, _index: number) => {
                return (
                  <Image
                    width={100}
                    height={50}
                    src={`${URL_FOTOS}/${_value}`}
                    fallback={ERROR_IMAGE}
                    style={{ borderRadius: 10 }}
                  />
                );
              },
            },
            {
              title: "Fecha de visita",
              key: "cagnew_fecha_visita",
              dataIndex: "cagnew_fecha_visita",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_fecha_visita.localeCompare(b.cagnew_fecha_visita),
              render: (_value: string, _row: CensoAgrario, _index: number) => {
                return moment(_value).format("DD/MM/YYYY kk:mm:ss");
              },
            },
            {
              title: "Sincronizado",
              key: "cagnew_sync_up_date",
              dataIndex: "cagnew_sync_up_date",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_sync_up_date.localeCompare(b.cagnew_sync_up_date),
              render: (_value: string, _row: CensoAgrario, _index: number) => {
                return moment(_value).format("DD/MM/YYYY kk:mm:ss");
              },
            },
            {
              title: "Usuario",
              key: "cagnew_usu_id",
              dataIndex: "cagnew_usu_id",
              sorter: (a: CensoAgrario, b: CensoAgrario) =>
                a.cagnew_usu_id.usu_nombres.localeCompare(
                  b.cagnew_usu_id.usu_nombres
                ),
              render: (_value: Usuario, _row: CensoAgrario, _index: number) => {
                return _value.usu_nombres;
              },
            },
          ]}
          dataSource={_censos}
        />

        <Pagination
          current={page}
          disabled={_censos.length === 0}
          pageSize={pageSize}
          showSizeChanger
          size="small"
          total={totalItems}
          onChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      </Space>

      {/* Modal de mapa */}
      {_censo && (
        <Modal
          title={_censo.cagnew_id}
          key={`location_${_censo.cagnew_id}`}
          visible={_visible}
          onCancel={() => {
            _setVisible(false);
          }}
          footer={[]}
          centered
        >
          <MapaComponent
            lat={_censo.cagnew_latitud}
            lng={_censo.cagnew_longitud}
          />
        </Modal>
      )}

      {_contextHolder}
    </>
  );
}
