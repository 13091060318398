import * as XLSX from "xlsx";
import ActivoWidget from "../util/activo.widget";
import ErrorComponent from "../components/error.component";
import HeaderWidget from "../util/header.widget";
import LeyendaComponent from "../components/leyenda.component";
import LoadComponent from "../components/load.component";
import PerfilContext from "../../provider/perfil_provider";
import TitleWidget from "../util/title.widget";
import UsuarioContext from "../../provider/usuario_provider";
import { _getContextDashboard } from "./dashboard.page";
import { APIException } from "../../model/api_exception";
import { APIPerfil } from "../../api/api_perfil";
import { APIResponse } from "../../model/api_response";
import { APIUsuario } from "../../api/api_usuario";
import { Button, Form, Input, Modal, Select, Space, Spin, Table } from "antd";
import { Perfil } from "../../model/perfil";
import { useCallback, useContext, useEffect, useState } from "react";
import { Usuario } from "../../model/usuario";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import InactivoWidget from "../util/inactivo.widget";

export default function UsuarioPage() {
  const [_flag, _setFlag] = useState<boolean>(true);
  const [_form] = Form.useForm();
  const [_listaUsuario, _setListaUsuario] = useState<Usuario[]>([]);
  const [_listaPerfil, _setListaPerfil] = useState<Perfil[]>([]);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_title, _setTitle] = useState<string>("");
  const [_visible, _setVisible] = useState<boolean>(false);
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const _apiPerfil = useContext<APIPerfil>(PerfilContext);
  const { _mobile } = _getContextDashboard();
  const { Option } = Select;

  const _listarPerfiles = useCallback(async () => {
    await _apiPerfil
      ._listarPerfiles()
      .then((value: Perfil[]) => {
        _setListaPerfil(value);
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      });
    _setLoading(false);
  }, [_apiPerfil, _modal]);

  const _listarUsuarios = useCallback(async () => {
    await _apiUsuario
      ._listarUsuarios()
      .then((value: Usuario[]) => {
        _setListaUsuario(value);
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      });
    _setLoading(false);
  }, [_apiUsuario, _modal]);

  const _renderAcciones = (_value: number, _row: Usuario, _index: number) => {
    return _value === 1 ? (
      <Space>
        <EditOutlined
          style={{
            fontSize: 20,
            color: "rgb(76 175 80)",
          }}
          onClick={() => {
            _setFlag(false);
            _setTitle("Editar registro");
            _form.setFieldsValue({
              usu_id: _row.usu_id,
              usu_nombres: _row.usu_nombres,
              usu_usuario: _row.usu_usuario,
              usu_perfil: _row.usu_perfil.per_descripcion,
              usu_estado: _row.usu_estado,
            });
            _setVisible(true);
          }}
        />
        <DeleteOutlined
          style={{
            fontSize: 20,
            color: "rgb(244 67 54)",
          }}
          onClick={() => {
            _actualizarEstadoUsuario(_row, 0);
          }}
        />
      </Space>
    ) : (
      <Space>
        <CheckOutlined
          style={{
            fontSize: 20,
            color: "rgb(76 175 80)",
          }}
          onClick={() => {
            _actualizarEstadoUsuario(_row, 1);
          }}
        />
      </Space>
    );
  };

  const _registrarUsuario = async (_usuario: Usuario) => {
    _modal.confirm({
      title: "Agregar registro",
      content: "¿Está seguro de agregar el registro",
      okText: "Si, registrar",
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },
      onOk: async () => {
        _setLoading(true);
        await _apiUsuario
          ._registrarUsuario(_usuario)
          .then((value: APIResponse) => {
            if (value.success) {
              _listarUsuarios();
              _form.resetFields();
              _modal.success({
                title: "Agregar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
                onOk: () => {
                  _setVisible(false);
                },
              });
            } else {
              _modal.error({
                title: "Agregar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: "Agregar registro",
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _editarUsuario = async (_usuario: Usuario) => {
    _modal.confirm({
      title: "Actualizar registro",
      content: "¿Está seguro de actualizar el registro",
      okText: "Si, actualizar",
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },
      onOk: async () => {
        _setLoading(true);
        await _apiUsuario
          ._editarUsuario(_usuario)
          .then((value: APIResponse) => {
            if (value.success) {
              _listarUsuarios();
              _form.resetFields();
              _modal.success({
                title: "Editar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
                onOk: () => {
                  _setVisible(false);
                },
              });
            } else {
              _modal.error({
                title: "Editar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: "Editar registro",
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _actualizarEstadoUsuario = async (
    _usuario: Usuario,
    _estado: number
  ) => {
    _modal.confirm({
      title: `${_estado === 1 ? "Activar" : "Eliminar"} registro`,

      content: `¿Está seguro de ${
        _estado === 1 ? "activar" : "eliminar"
      } el registro? Esta acción no se puede revertir`,
      okText: `Si, ${_estado === 1 ? "activar" : "eliminar"}`,
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },

      onOk: async () => {
        _setLoading(true);
        await _apiUsuario
          ._actualizarEstadoUsuario(_usuario, _estado)
          .then((value: APIResponse) => {
            _listarUsuarios();
            if (value.success) {
              _modal.success({
                title: `${_estado === 1 ? "Activar" : "Eliminar"} registro`,
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            } else {
              _modal.error({
                title: `${_estado === 1 ? "Activar" : "Eliminar"} registro`,
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: `${_estado === 1 ? "Activar" : "Eliminar"} registro`,
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _exportExcel = (data: Usuario[]) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet.A1.v = "CÓDIGO";
    worksheet.B1.v = "NOMBRES";
    worksheet.C1.v = "USUARIO";
    worksheet.D1.v = "CONTRASEÑA";
    worksheet.E1.v = "PERFIL";
    worksheet.F1.v = "ESTADO";
    data.forEach((value: Usuario, index: number) => {
      worksheet[`D${index + 2}`].v = "";
      worksheet[`E${index + 2}`].v = value.usu_perfil.per_descripcion;
      worksheet[`F${index + 2}`].t = "s";
      worksheet[`F${index + 2}`].v =
        value.usu_estado === 1 ? "ACTIVO" : "INACTIVO";
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");
    XLSX.writeFile(workbook, "Datos.xlsx");
  };

  useEffect(() => {
    _setMounted(true);
    _listarUsuarios();
    _listarPerfiles();
    return () => {
      _setMounted(false);
    };
  }, [_listarPerfiles, _listarUsuarios]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <>
      <Spin spinning={_loading}>
        <TitleWidget data="Usuarios" color="rgb(0 21 41)" size={30} />
        <hr />
        <HeaderWidget
          children={
            <>
              <LeyendaComponent
                mobile={_mobile}
                editar={true}
                borrar={true}
                activo={true}
              />
              <Space direction={_mobile ? "vertical" : "horizontal"}>
                <Button
                  icon={<FileExcelOutlined />}
                  style={{ width: 120 }}
                  onClick={() =>
                    _listaUsuario.length > 0
                      ? _exportExcel(_listaUsuario)
                      : _modal.warning({
                          title: "Exportar datos",
                          content: "No se econtró información para exportar",
                          centered: true,
                        })
                  }
                >
                  Exportar
                </Button>
                <Button
                  icon={<PlusCircleOutlined />}
                  type="primary"
                  onClick={() => {
                    _setFlag(true);
                    _form.resetFields();
                    _form.setFieldValue("usu_estado", 1);
                    _setTitle("Agregar registro");
                    _setVisible(true);
                  }}
                >
                  Agregar
                </Button>
              </Space>
            </>
          }
        />
        <Table
          loading={_loading}
          rowKey={"usu_id"}
          pagination={{ size: "small" }}
          columns={[
            {
              title: "Estado",
              key: "usu_estado",
              dataIndex: "usu_estado",
              width: "10%",
              render: (_value: number, _row: Usuario, _index: number) => {
                return _value === 1 ? (
                  <ActivoWidget margin="auto" />
                ) : (
                  <InactivoWidget margin="auto" />
                );
              },
            },
            {
              title: "Nombres",
              key: "usu_nombres",
              dataIndex: "usu_nombres",
              width: "40%",
              sorter: (a: Usuario, b: Usuario) =>
                a.usu_nombres.localeCompare(b.usu_nombres),
            },
            {
              title: "Usuario",
              key: "usu_usuario",
              dataIndex: "usu_usuario",
              width: "20%",
              sorter: (a: Usuario, b: Usuario) =>
                a.usu_usuario.localeCompare(b.usu_usuario),
            },
            {
              title: "Perfil",
              key: "usu_perfil",
              dataIndex: "usu_perfil",
              width: "20%",
              sorter: (a: Usuario, b: Usuario) =>
                a.usu_perfil.per_descripcion.localeCompare(
                  b.usu_perfil.per_descripcion
                ),
              render: (_value: Perfil, _row: Usuario, _index: number) => {
                return _value.per_descripcion;
              },
            },
            {
              title: "Acciones",
              key: "usu_estado",
              dataIndex: "usu_estado",
              width: "10%",
              align: "center",
              render: _renderAcciones,
            },
          ]}
          dataSource={_listaUsuario}
        />

        {/* Modal de edición */}
        <Modal
          title={_title}
          visible={_visible}
          onCancel={() => {
            _setVisible(false);
          }}
          onOk={() => {
            _form.validateFields().then((values: any) => {
              _flag
                ? _registrarUsuario(new Usuario(values))
                : _editarUsuario(new Usuario(values));
            });
          }}
          okButtonProps={{ danger: true, type: "dashed" }}
          cancelButtonProps={{
            type: "primary",
          }}
          okText="Aceptar"
          cancelText="Cancelar"
          centered
        >
          <Form form={_form} layout="vertical" name="form_in_modal">
            <Form.Item name="usu_id" label="Código" hidden>
              <Input placeholder="Código" readOnly />
            </Form.Item>
            <Form.Item
              name="usu_nombres"
              label="Nombres"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa los nombres del usuario!",
                },
              ]}
            >
              <Input placeholder="Nombres" />
            </Form.Item>
            <Form.Item
              name="usu_usuario"
              label="Usuario"
              hidden={!_flag}
              rules={
                !_flag
                  ? []
                  : [
                      {
                        required: true,
                        message: "¡Por favor ingresa el usuario!",
                      },
                    ]
              }
            >
              <Input placeholder="Usuario" />
            </Form.Item>
            <Form.Item
              name="usu_password"
              label="Contraseña"
              hidden={!_flag}
              rules={
                !_flag
                  ? []
                  : [
                      {
                        required: true,
                        message: "¡Por favor ingresa la contraseña!",
                      },
                    ]
              }
            >
              <Input.Password placeholder="Contraseña" />
            </Form.Item>
            <Form.Item
              name="usu_perfil"
              label="Perfil"
              rules={[
                {
                  required: true,
                  message: "¡Por favor selecciona un perfil!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Selecciona un perfil"
                optionFilterProp="children"
                filterOption={(input: string, option: any) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {_listaPerfil.map((value: Perfil, _index: number) => {
                  return (
                    <Option
                      key={`option_${value.per_id}`}
                      value={`${value.per_id}`}
                    >
                      {value.per_descripcion}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        {_contextHolder}
      </Spin>
    </>
  );
}
