import { APIException } from "../model/api_exception";
import { APIResponse } from "../model/api_response";
import { AuthService } from "../services/auth.service";
import { AxiosResponse } from "axios";
import { Coloracion } from "../model/coloracion";
import { ColoracionDataI } from "../model/coloracion";
import axios from "axios";
import Global from "../global/global";

export class APIColoracion {
  private _auth: AuthService = new AuthService();

  async _listarColoracion(): Promise<Coloracion[]> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarColoracion`,
      {
        token: await this._auth._getToken(),
      }
    );
    if (result.status === 200) {
      let listaColoracion: Coloracion[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: ColoracionDataI) => {
          listaColoracion.push(new Coloracion(element));
        });
      }
      return listaColoracion;
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _registrarColoracion(_coloracion: Coloracion): Promise<APIResponse> {
    let data: any = _coloracion._toMap();
    data.token = await this._auth._getToken();

    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/registrarColoracion`,
      data
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _editarColoracion(_coloracion: Coloracion): Promise<APIResponse> {
    let data: any = _coloracion._toMap();
    data.token = await this._auth._getToken();
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/editarColoracion`,
      data
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _eliminarColoracion(_coloracion: Coloracion): Promise<APIResponse> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/eliminarColoracion`,
      {
        token: await this._auth._getToken(),
        codigo: _coloracion.col_id,
        estado: 0,
      }
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }
}
