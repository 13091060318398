import { APIException } from "../model/api_exception";
import { APIResponse } from "../model/api_response";
import { AuthService } from "../services/auth.service";
import { AxiosResponse } from "axios";
import { Poda } from "../model/poda";
import { PodaDataI } from "../model/poda";
import axios from "axios";
import Global from "../global/global";

export class APIPoda {
  private _auth: AuthService = new AuthService();

  async _listarPodas(): Promise<Poda[]> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarPodas`,
      {
        token: await this._auth._getToken(),
      }
    );
    if (result.status === 200) {
      let listaPoda: Poda[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: PodaDataI) => {
          listaPoda.push(new Poda(element));
        });
      }
      return listaPoda;
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _registrarPoda(_poda: Poda): Promise<APIResponse> {
    let data: any = _poda._toMap();
    data.token = await this._auth._getToken();

    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/registrarPoda`,
      data
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _editarPoda(_poda: Poda): Promise<APIResponse> {
    let data: any = _poda._toMap();
    data.token = await this._auth._getToken();
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/editarPoda`,
      data
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _eliminarPoda(_poda: Poda): Promise<APIResponse> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/eliminarPoda`,
      {
        token: await this._auth._getToken(),
        codigo: _poda.pod_id,
        estado: 0,
      }
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }
}
