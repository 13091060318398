import styled from "styled-components";

const Title = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: ${(props) => `${props.theme.size}px` ?? "150%"};
  color: ${(props) => props.color};
  margin: 15px 0px;
  line-height: 1;
`;

const TitleWidget = ({
  data,
  color,
  size,
}: {
  data: string;
  color: string;
  size?: number;
}) => {
  return (
    <Title color={color} theme={{ size }}>
      {data}
    </Title>
  );
};

export default TitleWidget;
