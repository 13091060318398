export interface ColoracionDataI {
  col_id: number;
  col_descripcion: string;
  col_estado: number;
}

export class Coloracion implements ColoracionDataI {
  col_id: number;
  col_descripcion: string;
  col_estado: number;

  constructor(data: ColoracionDataI) {
    this.col_id = data.col_id;
    this.col_descripcion = data.col_descripcion;
    this.col_estado = data.col_estado;
  }

  _toMap() {
    return {
      col_id: this.col_id,
      col_descripcion: this.col_descripcion,
      col_estado: this.col_estado,
    };
  }

  _fromMap(data: ColoracionDataI) {
    this.col_id = data.col_id;
    this.col_descripcion = data.col_descripcion;
    this.col_estado = data.col_estado;
  }
}
