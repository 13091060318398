import axios from "axios";
import Global from "../global/global";
import { APIException } from "../model/api_exception";
import { APIResponse } from "../model/api_response";
import { AuthService } from "../services/auth.service";
import { AxiosResponse } from "axios";
import { Cacao } from "../model/cacao";
import { CacaoDataI } from "../model/cacao";

interface CacaoPagination {
  data: Cacao[];
  totalItems: number;
  pendientes: number;
  aprobados: number;
  rechazados: number;
}
export class APICacao {
  private _auth: AuthService = new AuthService();

  async _listarCacao(
    usuario?: number,
    fechaIni?: string,
    fechaFin?: string,
    estado?: number,
    page?: number,
    perPage?: number
  ): Promise<CacaoPagination> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarCacao`,
      {
        token: await this._auth._getToken(),
        usuario,
        fechaIni,
        fechaFin,
        estado,
        page,
        perPage,
      }
    );
    if (result.status === 200) {
      let data: Cacao[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: CacaoDataI) => {
          data.push(new Cacao(element));
        });
      }
      let totalItems = result.data.totalItems;
      let pendientes = result.data.pendientes;
      let aprobados = result.data.aprobados;
      let rechazados = result.data.rechazados;
      return { data, totalItems, pendientes, aprobados, rechazados };
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _actualizarCacao(_cacao: Cacao, _estado: number): Promise<APIResponse> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/actualizarCacao`,
      {
        token: await this._auth._getToken(),
        codigo: _cacao.cacao_id,
        estado: _estado,
      }
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }
}
