import AuthContext from "../../provider/auth_provider";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import { APIException } from "../../model/api_exception";
import { APIResponse } from "../../model/api_response";
import { AuthService } from "../../services/auth.service";
import { Drawer, Dropdown, Form, Input, Layout, Menu, Modal, Spin } from "antd";
import { isMobile } from "react-device-detect";
import { MenuInfo } from "rc-menu/lib/interface";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { PerfilContext } from "../../context/perfil-context";
import { useCallback, useContext, useEffect, useState } from "react";
import { Usuario } from "../../model/usuario";
import {
  AppstoreOutlined,
  BgColorsOutlined,
  BranchesOutlined,
  BuildOutlined,
  CalendarOutlined,
  CaretUpOutlined,
  DownOutlined,
  FileTextOutlined,
  HomeOutlined,
  KeyOutlined,
  MenuUnfoldOutlined,
  PoweroffOutlined,
  SlackOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { URL_ASSETS } = Global;

type ContextType = { _mobile: boolean };
export function _getContextDashboard() {
  return useOutletContext<ContextType>();
}

export default function DashboardPage() {
  const [_form] = Form.useForm();
  const [_loading, _setLoading] = useState<boolean>(false);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_password, _setPassword] = useState<boolean>(false);
  const [_path, _setPath] = useState("home");
  const [_usuario, _setUsuario] = useState<Usuario | null>();
  const [_visible, _setVisible] = useState<boolean>(false);
  const _authService = useContext<AuthService>(AuthContext);
  const _mobile = isMobile;
  const { _perfil, _setPerfil } = useContext(PerfilContext);
  const { Content, Footer, Header } = Layout;
  let _navigate = useNavigate();

  const _getCurrentUser = useCallback(async () => {
    let usuario: Usuario | null = await _authService._getCurrentUser();
    if (usuario) {
      _setUsuario(usuario);
      _setPerfil(usuario.usu_perfil.per_id);
    } else {
      _navigate("/auth");
    }
  }, [_authService, _navigate, _setPerfil]);

  const _getRoute = useCallback(() => {
    let _data: string[] = window.location.pathname.split("/");
    if (_data && _data.length > 0) {
      let _path = _data[_data.length - 1];
      _setPath(_path.length === 0 ? "home" : _path);
    }
  }, []);

  const _logout = (value: MenuInfo) => {
    if (value.key === "1") {
      _modal.confirm({
        title: "Cerrar sesión",
        content: "¿Está seguro de cerrar su sesión?",
        okText: "Si, cerrar",
        cancelText: "No, cancelar",
        onOk: async () => {
          await _authService._logOut();
          _navigate("/auth");
        },
        centered: true,
        okButtonProps: {
          danger: true,
          type: "dashed",
        },
        cancelButtonProps: {
          type: "primary",
        },
      });
    } else {
      _setPassword(true);
    }
  };

  const _cambioPassword = async (values: any) => {
    _setLoading(true);
    await _authService
      ._cambioPassword(values.password, values.password_actual)
      .then((value: APIResponse) => {
        _modal.success({
          title: "Cerrar sesión",
          content: value.message,
          centered: true,
          onOk: () => {
            _setPassword(false);
          },
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
        });
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Cerrar sesión",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
        });
      });
    _setLoading(false);
  };

  const _showDrawer = () => {
    _setVisible(true);
  };

  const _onClose = () => {
    _setVisible(false);
  };

  useEffect(() => {
    _setMounted(true);
    _getCurrentUser();
    _getRoute();
    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser, _getRoute]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <>
      <Spin spinning={_loading}>
        <Layout style={{ minHeight: "100vh" }}>
          <Drawer
            title="Menú"
            placement="left"
            onClose={_onClose}
            visible={_visible}
          >
            <img
              style={{ margin: 15, width: "50%" }}
              src={`${URL_ASSETS}/logo_border.png`}
              alt="Agroat"
              title="Agroat"
            />
            <Menu
              theme="dark"
              selectedKeys={[_path]}
              mode="inline"
              items={[
                {
                  key: "home",
                  icon: <HomeOutlined />,
                  title: "Inicio",
                  label: "Inicio",
                  onClick: () => {
                    _navigate("/home");
                    _onClose();
                  },
                },
                {
                  key: "censo_agrario",
                  icon: <FileTextOutlined />,
                  title: "Censo agrario",
                  label: "Censo agrario",
                  onClick: () => {
                    _navigate("/censo_agrario");
                    _onClose();
                  },
                },
                {
                  key: "cacao",
                  icon: <FileTextOutlined />,
                  title: "Cacao",
                  label: "Cacao",
                  onClick: () => {
                    _navigate("/cacao");
                    _onClose();
                  },
                },
                {
                  key: "fta",
                  icon: <FileTextOutlined />,
                  title: "Ficha Tecnica Agronomica",
                  label: "Ficha Tecnica Agronomica",
                  onClick: () => {
                    _navigate("/fta");
                    _onClose();
                  },
                },
                {
                  key: "encuesta_productores",
                  icon: <FileTextOutlined />,
                  title: "Encuesta a productores",
                  label: "Encuesta a productores",
                  onClick: () => {
                    _navigate("/encuesta_productores");
                    _onClose();
                  },
                },
                {
                  key: "ficha_campo",
                  icon: <FileTextOutlined />,
                  title: "Zonas y cadenas productivas",
                  label: "Zonas y cadenas productivas",
                  onClick: () => {
                    _navigate("/ficha_campo");
                    _onClose();
                  },
                },
                ...(_perfil === 1
                  ? [
                      {
                        key: "coloration",
                        icon: <BgColorsOutlined />,
                        title: "Coloración",
                        label: "Coloración",
                        onClick: () => {
                          _navigate("/coloration");
                          _onClose();
                        },
                      },
                      {
                        key: "crops",
                        icon: <SlackOutlined />,
                        title: "Cultivos",
                        label: "Cultivos",
                        onClick: () => {
                          _navigate("/crops");
                          _onClose();
                        },
                      },
                      {
                        key: "phenological_state",
                        icon: <BuildOutlined />,
                        title: "Estado fenológico",
                        label: "Estado fenológico",
                        onClick: () => {
                          _navigate("/phenological_state");
                          _onClose();
                        },
                      },
                      {
                        key: "slope",
                        icon: <CaretUpOutlined />,
                        title: "Pendientes",
                        label: "Pendientes",
                        onClick: () => {
                          _navigate("/slope");
                          _onClose();
                        },
                      },
                      {
                        key: "pruning",
                        icon: <CalendarOutlined />,
                        title: "Podas",
                        label: "Podas",
                        onClick: () => {
                          _navigate("/pruning");
                          _onClose();
                        },
                      },
                      {
                        key: "irrigation",
                        icon: <AppstoreOutlined />,
                        title: "Riego",
                        label: "Riego",
                        onClick: () => {
                          _navigate("/irrigation");
                          _onClose();
                        },
                      },
                      {
                        key: "planting_system",
                        icon: <BranchesOutlined />,
                        title: "Sistemas de siembra",
                        label: "Sistemas de siembra",
                        onClick: () => {
                          _navigate("/planting_system");
                          _onClose();
                        },
                      },
                      {
                        key: "users",
                        icon: <UserOutlined />,
                        title: "Usuarios",
                        label: "Usuarios",
                        onClick: () => {
                          _navigate("/users");
                          _onClose();
                        },
                      },
                    ]
                  : []),
              ]}
            />
          </Drawer>
          <Layout>
            <Header
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 10px",
              }}
            >
              <MenuUnfoldOutlined
                style={{ color: "rgb(255 255 255)", fontSize: 20 }}
                onClick={_showDrawer}
              />
              <Dropdown.Button
                style={{
                  background: "rgb(255 255 255)",
                  borderRadius: 10,
                  border: "unset",
                }}
                overlay={
                  <Menu
                    onClick={(event: MenuInfo) => {
                      _logout(event);
                    }}
                    items={[
                      {
                        key: "1",
                        icon: <PoweroffOutlined />,
                        label: "Cerrar sesión",
                      },
                      ...(_perfil === 1
                        ? [
                            {
                              key: "2",
                              icon: <KeyOutlined />,
                              label: "Cambiar contraseña",
                            },
                          ]
                        : []),
                    ]}
                  />
                }
                placement="bottomLeft"
                icon={<DownOutlined />}
                trigger={["click"]}
              >
                {_usuario?.usu_nombres ?? <Spin />}
              </Dropdown.Button>
            </Header>
            <Content style={{ padding: 20 }}>
              <Outlet context={{ _mobile }} />
            </Content>
            <Footer style={{ textAlign: "center", padding: "10px" }}>
              OAT ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>

        {/* Modal de cambio de contraseña */}
        <Modal
          title="Cambio de contraseña"
          visible={_password}
          onCancel={() => {
            _setPassword(false);
          }}
          onOk={() => {
            _form.validateFields().then((values: any) => {
              _cambioPassword(values);
            });
          }}
          okButtonProps={{ danger: true, type: "dashed" }}
          cancelButtonProps={{
            type: "primary",
          }}
          okText="Aceptar"
          cancelText="Cancelar"
          centered
        >
          <Form form={_form} layout="vertical" name="form_in_modal">
            <Form.Item
              name="password"
              label="Contraseña nueva"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa la nueva contraseña!",
                },
              ]}
            >
              <Input.Password placeholder="Contraseña nueva" />
            </Form.Item>
            <Form.Item
              name="password_actual"
              label="Contraseña actual"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa la nueva actual!",
                },
              ]}
            >
              <Input.Password placeholder="Contraseña actual" />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
      {_contextHolder}
    </>
  );
}
