export interface PerfilDataI {
  per_id: number;
  per_descripcion: string;
  per_estado: number;
}

export class Perfil implements PerfilDataI {
  per_id: number;
  per_descripcion: string;
  per_estado: number;

  constructor(data: PerfilDataI) {
    this.per_id = data.per_id;
    this.per_descripcion = data.per_descripcion;
    this.per_estado = data.per_estado;
  }

  _toMap() {
    return {
      per_id: this.per_id,
      per_descripcion: this.per_descripcion,
      per_estado: this.per_estado,
    };
  }

  _fromMap(data: PerfilDataI) {
    this.per_id = data.per_id;
    this.per_descripcion = data.per_descripcion;
    this.per_estado = data.per_estado;
  }
}
