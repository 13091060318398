import { Cultivo } from "./cultivo";

export interface EstadoFenologicoDataI {
  ef_id: number;
  ef_descripcion: string;
  ef_cul_id: Cultivo;
  ef_estado: number;
}

export class EstadoFenologico implements EstadoFenologicoDataI {
  ef_id: number;
  ef_descripcion: string;
  ef_cul_id: Cultivo;
  ef_estado: number;

  constructor(data: EstadoFenologicoDataI) {
    this.ef_id = data.ef_id;
    this.ef_descripcion = data.ef_descripcion;
    this.ef_cul_id = data.ef_cul_id;
    this.ef_estado = data.ef_estado;
  }

  _toMap() {
    return {
      ef_id: this.ef_id,
      ef_descripcion: this.ef_descripcion,
      ef_cul_id: this.ef_cul_id,
      ef_estado: this.ef_estado,
    };
  }

  _fromMap(data: EstadoFenologicoDataI) {
    this.ef_id = data.ef_id;
    this.ef_descripcion = data.ef_descripcion;
    this.ef_cul_id = data.ef_cul_id;
    this.ef_estado = data.ef_estado;
  }
}
