import { Cliente } from "./cliente";
import { Cultivo } from "./cultivo";
import { Distrito } from "./distrito";
import { EstadoFisicoLegal } from "./estado_fisico_legal";
import { Usuario } from "./usuario";

export interface EncuestaProductoresDataI {
  enc_id: number;
  enc_nombre: string;
  enc_dni: string;
  enc_celular: string;
  enc_direccion: string;
  enc_localidad: string;
  enc_sector: string;
  enc_dis_id_ubicacion: Distrito;
  enc_latitud: number;
  enc_longitud: number;
  enc_altitud: number;
  enc_area: number;
  enc_cag_efl_id: EstadoFisicoLegal;
  enc_tiene_titulo_propiedad: string;
  enc_otro_documento: string;
  enc_cag_cul_id_principal: Cultivo;
  enc_cultivos_en_campo: Cultivo[];
  enc_tipo_produccion: string;
  enc_nivel_tecnologia: string;
  enc_sistema_riego: string;
  enc_area_total_produccion: number;
  enc_area_sembrada: number;
  enc_area_cosechada: number;
  enc_rendimiento: string;
  enc_precio_venta: number;
  enc_maneja_costos_produccion: string;
  enc_ingreso_campania: string;
  enc_costo_produccion_ha: number;
  enc_cliente_comercializacion: Cliente;
  enc_precio_comercializacion: number;
  enc_pertenece_organizacion: string;
  enc_organizacion: string;
  enc_otro_organizacion: string;
  enc_denominacion: string;
  enc_comercializa_organizacion: string;
  enc_recibio_financiamiento: string;
  enc_comentario_financiamiento: string;
  enc_fue_beneficiario: string;
  enc_comentario_beneficiario: string;
  enc_foto: string;
  enc_fecha_visita: string;
  enc_sync_up_date: string;
  enc_usu_id: Usuario;
  enc_estado: number;
} 

export class EncuestaProductores implements EncuestaProductoresDataI {
  enc_id: number;
  enc_nombre: string;
  enc_dni: string;
  enc_celular: string;
  enc_direccion: string;
  enc_localidad: string;
  enc_sector: string;
  enc_dis_id_ubicacion: Distrito;
  enc_latitud: number;
  enc_longitud: number;
  enc_altitud: number;
  enc_area: number;
  enc_cag_efl_id: EstadoFisicoLegal;
  enc_tiene_titulo_propiedad: string;
  enc_otro_documento: string;
  enc_cag_cul_id_principal: Cultivo;
  enc_cultivos_en_campo: Cultivo[];
  enc_tipo_produccion: string;
  enc_nivel_tecnologia: string;
  enc_sistema_riego: string;
  enc_area_total_produccion: number;
  enc_area_sembrada: number;
  enc_area_cosechada: number;
  enc_rendimiento: string;
  enc_precio_venta: number;
  enc_maneja_costos_produccion: string;
  enc_ingreso_campania: string;
  enc_costo_produccion_ha: number;
  enc_cliente_comercializacion: Cliente;
  enc_precio_comercializacion: number;
  enc_pertenece_organizacion: string;
  enc_organizacion: string;
  enc_otro_organizacion: string;
  enc_denominacion: string;
  enc_comercializa_organizacion: string;
  enc_recibio_financiamiento: string;
  enc_comentario_financiamiento: string;
  enc_fue_beneficiario: string;
  enc_comentario_beneficiario: string;
  enc_foto: string;
  enc_fecha_visita: string;
  enc_sync_up_date: string;
  enc_usu_id: Usuario;
  enc_estado: number;

  constructor(data: EncuestaProductoresDataI) {
    this.enc_id = data.enc_id;
    this.enc_nombre = data.enc_nombre;
    this.enc_dni = data.enc_dni;
    this.enc_celular = data.enc_celular;
    this.enc_direccion = data.enc_direccion;
    this.enc_localidad = data.enc_localidad;
    this.enc_sector = data.enc_sector;
    this.enc_dis_id_ubicacion = data.enc_dis_id_ubicacion;
    this.enc_latitud = data.enc_latitud;
    this.enc_longitud = data.enc_longitud;
    this.enc_altitud = data.enc_altitud;
    this.enc_area = data.enc_area;
    this.enc_cag_efl_id = data.enc_cag_efl_id;
    this.enc_tiene_titulo_propiedad = data.enc_tiene_titulo_propiedad;
    this.enc_otro_documento = data.enc_otro_documento;
    this.enc_cag_cul_id_principal = data.enc_cag_cul_id_principal;
    this.enc_cultivos_en_campo = data.enc_cultivos_en_campo;
    this.enc_tipo_produccion = data.enc_tipo_produccion;
    this.enc_nivel_tecnologia = data.enc_nivel_tecnologia;
    this.enc_sistema_riego = data.enc_sistema_riego;
    this.enc_area_total_produccion = data.enc_area_total_produccion;
    this.enc_area_sembrada = data.enc_area_sembrada;
    this.enc_area_cosechada = data.enc_area_cosechada;
    this.enc_rendimiento = data.enc_rendimiento;
    this.enc_precio_venta = data.enc_precio_venta;
    this.enc_maneja_costos_produccion = data.enc_maneja_costos_produccion;
    this.enc_ingreso_campania = data.enc_ingreso_campania;
    this.enc_costo_produccion_ha = data.enc_costo_produccion_ha;
    this.enc_cliente_comercializacion = data.enc_cliente_comercializacion;
    this.enc_precio_comercializacion = data.enc_precio_comercializacion;
    this.enc_pertenece_organizacion = data.enc_pertenece_organizacion;
    this.enc_organizacion = data.enc_organizacion;
    this.enc_otro_organizacion = data.enc_otro_organizacion;
    this.enc_denominacion = data.enc_denominacion;
    this.enc_comercializa_organizacion = data.enc_comercializa_organizacion;
    this.enc_recibio_financiamiento = data.enc_recibio_financiamiento;
    this.enc_comentario_financiamiento = data.enc_comentario_financiamiento;
    this.enc_fue_beneficiario = data.enc_fue_beneficiario;
    this.enc_comentario_beneficiario = data.enc_comentario_beneficiario;
    this.enc_foto = data.enc_foto;
    this.enc_fecha_visita = data.enc_fecha_visita;
    this.enc_sync_up_date = data.enc_sync_up_date;
    this.enc_usu_id = data.enc_usu_id;
    this.enc_estado = data.enc_estado;
  }

  _toMap() {
    return {
      enc_id: this.enc_id,
      enc_nombre: this.enc_nombre,
      enc_dni: this.enc_dni,
      enc_celular: this.enc_celular,
      enc_direccion: this.enc_direccion,
      enc_localidad: this.enc_localidad,
      enc_sector: this.enc_sector,
      enc_dis_id_ubicacion: this.enc_dis_id_ubicacion,
      enc_latitud: this.enc_latitud,
      enc_longitud: this.enc_longitud,
      enc_altitud: this.enc_altitud,
      enc_area: this.enc_area,
      enc_cag_efl_id: this.enc_cag_efl_id,
      enc_tiene_titulo_propiedad: this.enc_tiene_titulo_propiedad,
      enc_otro_documento: this.enc_otro_documento,
      enc_cag_cul_id_principal: this.enc_cag_cul_id_principal,
      enc_cultivos_en_campo: this.enc_cultivos_en_campo,
      enc_tipo_produccion: this.enc_tipo_produccion,
      enc_nivel_tecnologia: this.enc_nivel_tecnologia,
      enc_sistema_riego: this.enc_sistema_riego,
      enc_area_total_produccion: this.enc_area_total_produccion,
      enc_area_sembrada: this.enc_area_sembrada,
      enc_area_cosechada: this.enc_area_cosechada,
      enc_rendimiento: this.enc_rendimiento,
      enc_precio_venta: this.enc_precio_venta,
      enc_maneja_costos_produccion: this.enc_maneja_costos_produccion,
      enc_ingreso_campania: this.enc_ingreso_campania,
      enc_costo_produccion_ha: this.enc_costo_produccion_ha,
      enc_cliente_comercializacion: this.enc_cliente_comercializacion,
      enc_precio_comercializacion: this.enc_precio_comercializacion,
      enc_pertenece_organizacion: this.enc_pertenece_organizacion,
      enc_organizacion: this.enc_organizacion,
      enc_otro_organizacion: this.enc_otro_organizacion,
      enc_denominacion: this.enc_denominacion,
      enc_comercializa_organizacion: this.enc_comercializa_organizacion,
      enc_recibio_financiamiento: this.enc_recibio_financiamiento,
      enc_comentario_financiamiento: this.enc_comentario_financiamiento,
      enc_fue_beneficiario: this.enc_fue_beneficiario,
      enc_comentario_beneficiario: this.enc_comentario_beneficiario,
      enc_foto: this.enc_foto,
      enc_fecha_visita: this.enc_fecha_visita,
      enc_sync_up_date: this.enc_sync_up_date,
      enc_usu_id: this.enc_usu_id,
      enc_estado: this.enc_estado,
    };
  }

  _fromMap(data: EncuestaProductoresDataI) {
    this.enc_id = data.enc_id;
    this.enc_nombre = data.enc_nombre;
    this.enc_dni = data.enc_dni;
    this.enc_celular = data.enc_celular;
    this.enc_direccion = data.enc_direccion;
    this.enc_localidad = data.enc_localidad;
    this.enc_sector = data.enc_sector;
    this.enc_dis_id_ubicacion = data.enc_dis_id_ubicacion;
    this.enc_latitud = data.enc_latitud;
    this.enc_longitud = data.enc_longitud;
    this.enc_altitud = data.enc_altitud;
    this.enc_area = data.enc_area;
    this.enc_cag_efl_id = data.enc_cag_efl_id;
    this.enc_tiene_titulo_propiedad = data.enc_tiene_titulo_propiedad;
    this.enc_otro_documento = data.enc_otro_documento;
    this.enc_cag_cul_id_principal = data.enc_cag_cul_id_principal;
    this.enc_cultivos_en_campo = data.enc_cultivos_en_campo;
    this.enc_tipo_produccion = data.enc_tipo_produccion;
    this.enc_nivel_tecnologia = data.enc_nivel_tecnologia;
    this.enc_sistema_riego = data.enc_sistema_riego;
    this.enc_area_total_produccion = data.enc_area_total_produccion;
    this.enc_area_sembrada = data.enc_area_sembrada;
    this.enc_area_cosechada = data.enc_area_cosechada;
    this.enc_rendimiento = data.enc_rendimiento;
    this.enc_precio_venta = data.enc_precio_venta;
    this.enc_maneja_costos_produccion = data.enc_maneja_costos_produccion;
    this.enc_ingreso_campania = data.enc_ingreso_campania;
    this.enc_costo_produccion_ha = data.enc_costo_produccion_ha;
    this.enc_cliente_comercializacion = data.enc_cliente_comercializacion;
    this.enc_precio_comercializacion = data.enc_precio_comercializacion;
    this.enc_pertenece_organizacion = data.enc_pertenece_organizacion;
    this.enc_organizacion = data.enc_organizacion;
    this.enc_otro_organizacion = data.enc_otro_organizacion;
    this.enc_denominacion = data.enc_denominacion;
    this.enc_comercializa_organizacion = data.enc_comercializa_organizacion;
    this.enc_recibio_financiamiento = data.enc_recibio_financiamiento;
    this.enc_comentario_financiamiento = data.enc_comentario_financiamiento;
    this.enc_fue_beneficiario = data.enc_fue_beneficiario;
    this.enc_comentario_beneficiario = data.enc_comentario_beneficiario;
    this.enc_foto = data.enc_foto;
    this.enc_fecha_visita = data.enc_fecha_visita;
    this.enc_sync_up_date = data.enc_sync_up_date;
    this.enc_usu_id = data.enc_usu_id;
    this.enc_estado = data.enc_estado;
  }
}
