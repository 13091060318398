import Global from "../../global/global";
import GoogleMapReact from "google-map-react";
import { Coords } from "google-map-react";

const { KEY_GOOGLE_MAPS, URL_ASSETS } = Global;
const MapaComponent = ({ lat, lng }: { lat: number; lng: number }) => {
  const _render = (map: GoogleMapReact, maps: any) => {
    let _coordenadas: Coords[] = [{ lat, lng }];

    // Agregar marcadores
    new maps.Marker({
      position: _coordenadas[0],
      map: map,
      title: `Lat.: ${_coordenadas[0].lat} Lng.: ${_coordenadas[0].lng}`,
      icon: {
        url: `${URL_ASSETS}/marker_rojo.png`,
        scaledSize: new maps.Size(40, 40),
      },
      animation: maps.Animation.DROP,
    });
  };

  return (
    <div style={{ height: 500, width: "100%" }}>
      <GoogleMapReact
        style={{
          width: "100%",
          height: "100%",
        }}
        bootstrapURLKeys={{ key: KEY_GOOGLE_MAPS }}
        center={{
          lat,
          lng,
        }}
        zoom={15}
        onGoogleApiLoaded={({ map, maps }) => _render(map, maps)}
        yesIWantToUseGoogleMapApiInternals={true}
        options={{
          zoomControl: false,
          heading: 10,
          mapTypeId: "satellite",
          fullscreenControl: false,
        }}
      />
    </div>
  );
};

export default MapaComponent;
