import { Perfil } from "./perfil";

export interface UsuarioDataI {
  usu_id: number;
  usu_nombres: string;
  usu_usuario: string;
  usu_password: string;
  usu_perfil: Perfil;
  usu_estado: number;
}

export class Usuario implements UsuarioDataI {
  usu_id: number;
  usu_nombres: string;
  usu_usuario: string;
  usu_password: string;
  usu_perfil: Perfil;
  usu_estado: number;

  constructor(data: UsuarioDataI) {
    this.usu_id = data.usu_id;
    this.usu_nombres = data.usu_nombres;
    this.usu_usuario = data.usu_usuario;
    this.usu_password = data.usu_password;
    this.usu_perfil = data.usu_perfil;
    this.usu_estado = data.usu_estado;
  }

  _toMap() {
    return {
      usu_id: this.usu_id,
      usu_nombres: this.usu_nombres,
      usu_usuario: this.usu_usuario,
      usu_password: this.usu_password,
      usu_perfil: this.usu_perfil,
      usu_estado: this.usu_estado,
    };
  }

  _fromMap(data: UsuarioDataI) {
    this.usu_id = data.usu_id;
    this.usu_nombres = data.usu_nombres;
    this.usu_usuario = data.usu_usuario;
    this.usu_password = data.usu_password;
    this.usu_perfil = data.usu_perfil;
    this.usu_estado = data.usu_estado;
  }
}
