import * as XLSX from "xlsx";
import ActivoWidget from "../util/activo.widget";
import ErrorComponent from "../components/error.component";
import FichaCampoContext from "../../provider/ficha_campo_provider";
import Global from "../../global/global";
import InactivoWidget from "../util/inactivo.widget";
import LeyendaComponent from "../components/leyenda.component";
import LoadComponent from "../components/load.component";
import locale from "antd/es/date-picker/locale/es_ES";
import MapaComponent from "../components/mapa.component";
import moment from "moment";
import PendienteWidget from "../util/pendiente.widget";
import TitleWidget from "../util/title.widget";
import UsuarioContext from "../../provider/usuario_provider";
import { _getContextDashboard } from "./dashboard.page";
import { APIException } from "../../model/api_exception";
import { APIFichaCampo } from "../../api/api_ficha_campo";
import { APIResponse } from "../../model/api_response";
import { APIUsuario } from "../../api/api_usuario";
import { Distrito } from "../../model/distrito";
import { FichaCampo } from "../../model/ficha_campo";
import { PerfilContext } from "../../context/perfil-context";
import { useCallback, useContext, useEffect, useState } from "react";
import { Usuario } from "../../model/usuario";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  FileExcelOutlined,
  PushpinFilled,
} from "@ant-design/icons";

const { ESTADOS, FORMATO_FECHA } = Global;
export default function FichaCampoPage() {
  const [_dateFin, _setDateFin] = useState<any>();
  const [_dateIni, _setDateIni] = useState<any>();
  const [_estado, _setEstado] = useState<number>(3);
  const [_ficha, _setFicha] = useState<FichaCampo>();
  const [_fichas, _setFichas] = useState<FichaCampo[]>([]);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_usuario, _setUsuario] = useState<number>(0);
  const [_usuarios, _setUsuarios] = useState<Usuario[]>([]);
  const [_visible, _setVisible] = useState<boolean>(false);
  const [aprobados, setAprobados] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [pendientes, setPendientes] = useState<number>(0);
  const [rechazados, setRechazados] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const _apiFichaCampo = useContext<APIFichaCampo>(FichaCampoContext);
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const { _mobile } = _getContextDashboard();
  const { _perfil } = useContext(PerfilContext);
  const { Option } = Select;
  const { Panel } = Collapse;

  const _listarUsuarios = useCallback(async () => {
    await _apiUsuario._listarUsuarios().then((value: Usuario[]) => {
      _setUsuarios(value);
    });
  }, [_apiUsuario]);

  const _listarFichaCampo = useCallback(async () => {
    _setLoading(true);
    await _apiFichaCampo
      ._listarFichaCampo(_usuario, _dateIni, _dateFin, _estado, page, pageSize)
      .then((value) => {
        _setFichas(value.data);
        setTotalItems(value.totalItems || 0);
        setPendientes(value.pendientes || 0);
        setAprobados(value.aprobados || 0);
        setRechazados(value.rechazados || 0);
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      })
      .finally(() => {
        _setLoading(false);
      });
  }, [
    _apiFichaCampo,
    _dateFin,
    _dateIni,
    _estado,
    _modal,
    _usuario,
    page,
    pageSize,
  ]);

  const _renderEstado = (_value: number, _row: FichaCampo, _index: number) => {
    if (_value === 0) {
      return <InactivoWidget margin="auto" />;
    }
    if (_value === 1) {
      return <PendienteWidget margin="auto" />;
    }
    if (_value === 2) {
      return <ActivoWidget margin="auto" />;
    }
  };

  const _renderAcciones = (
    _value: number,
    _row: FichaCampo,
    _index: number
  ) => {
    return _value === 1 && _perfil === 1 ? (
      <Space>
        <CheckOutlined
          style={{
            fontSize: 20,
            color: "rgb(76 175 80)",
          }}
          onClick={() => {
            _actualizarFichaCampo(_row, 2);
          }}
        />
        <CloseOutlined
          style={{
            fontSize: 20,
            color: "rgb(244 67 54)",
          }}
          onClick={() => {
            _actualizarFichaCampo(_row, 0);
          }}
        />
        <PushpinFilled
          style={{
            fontSize: 20,
            color: "rgb(0 188 212)",
          }}
          onClick={() => {
            _setFicha(_row);
            _setVisible(true);
          }}
        />
      </Space>
    ) : (
      <Space>
        <PushpinFilled
          style={{
            fontSize: 20,
            color: "rgb(0 188 212)",
          }}
          onClick={() => {
            _setFicha(_row);
            _setVisible(true);
          }}
        />
      </Space>
    );
  };

  const _actualizarFichaCampo = async (_ficha: FichaCampo, _estado: number) => {
    _modal.confirm({
      title: `${_estado === 2 ? "Aceptar" : "Rechazar"} registro`,
      content:
        "¿Está seguro de actualizar el registro? Esta acción no se puede revertir",
      okText: `Si, ${_estado === 2 ? "aceptar" : "rechazar"}`,
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },
      onOk: async () => {
        _setLoading(true);
        await _apiFichaCampo
          ._actualizarFichaCampo(_ficha, _estado)
          .then((value: APIResponse) => {
            _listarFichaCampo();
            if (value.success) {
              _modal.success({
                title: "Actualizar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            } else {
              _modal.error({
                title: "Actualizar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: "Actualizar registro",
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _exportExcel = async () => {
    _setLoading(true);
    await _apiFichaCampo
      ._listarFichaCampo(_usuario, _dateIni, _dateFin, _estado)
      .then((value) => {
        if (value.totalItems > 0) {
          const data: FichaCampo[] = value.data;
          // Personalizamos qué columnas exportar y sus nombres
          const exportData = data.map((item) => ({
            CÓDIGO: item.codigo,
            "RE - NOMBRE": item.rpteEconNombre,
            "RE - APELLIDO": item.rpteEconApellido,
            "RE - DNI": item.rpteEconDNI,
            "RE - CARGO": item.rpteEconCargo,
            CELULAR: item.contactoCelular,
            CORREO: item.contactoCorreo,
            DIRECCION: item.contactoDireccion,
            "NOMBRE COMERCIAL": item.ageEcoNombreComercial,
            "RAZÓN SOCIAL": item.ageEcoRazonSocial,
            LOCALIDAD: item.ageEcoLocalidad,
            PROVINCIA: item.ageEcoDistrito?.dis_pro_id?.pro_nombre,
            DISTRITO: item.ageEcoDistrito?.dis_nombre,
            "CANTIDAD DE SOCIOS": item.ageEcoCantidadSocios,
            "TIEMPO DE CREACIÓN": item.ageEcotiempoCreacion,
            "SEGURIDAD JURIDICA": item.ageEcoseguridadJuridica,
            SECTOR: item.ageEcosector,
            "CADENA PRODUCTIVA": item.ageEcocadenaProductiva,
            "PRODUCTO PRIMARIO": item.ageEcoproductoPrimario,
            "PRODUCTO DERIVADO": item.ageEcoproductoDerivado,
            "GENERACIÓN DE EMPLEO": item.generEmpleo,
            "PARTICIPACION POBLACION JOVEN": item.partiPoblacionJoven,
            "PARTICIPACION 30% MUJERES": item.partiMujer30,
            "MUJERES EN AREAS DIRIGENCIALES": item.partiMujerDir,
            "COMITÉ DE MUJERES": item.comitMujer,
            "LÍDER POR POBLACION INDIGENA": item.liderIndigena,
            "PARTICIPACION DE POBLACION INDIGENA": item.partiIndigena,
            "PARTICIPACION DE POBLACION VULNERABLE": item.partiVulnerable,
            "MECANISMO DE ATENCION DE RECLAMOS": item.mecanReclamos,
            "ATENCION DIFERENCIADA PARA MUJERES E HIJOS": item.atencDiferen,
            "PROTOCOLO CONTRA EL ACOSO": item.protoAcoso,
            "ENFOQUE DE GENERO": item.areaEnfoques,
            "NIVEL ORGANIZACIONAL": item.nivelOrganizacional,
            "PRESENCIA DE EMPRENDEDORES": item.presenciaEmprendedores,
            "SELLOS DE CALIDAD": item.sellosCalidad,
            "IMPACTO SOCIAL": item.impactoSocial,
            "NIVEL DE ASOCIATIVIDAD": item.nivelAsociatividad,
            FORMALIZADO: item.formalizado,
            "POTENCIAL DE MERCADO": item.petencialMercado,
            "ESTANDARIZACION DE NIVELES DE PROD.": item.estandNivProduccion,
            "ARTICULACION COMERCIAL": item.tieneArtComercial,
            "NIVEL DE ALIANZAS COMERCIALES": item.nivelAlianzaCom,
            "ACCESO A CREDITOS": item.accesoCreditos,
            "CAPACIDAD DE PRODUCCION": item.capacidadProduccion,
            PRODUCCION: item.produccion,
            "COSTOS COMPETITIVOS": item.costosCompetitivos,
            "ACCESO A TECNOLOGIA DE PROD.": item.accesoTecnologiaProd,
            "CALIDAD DE PRODUCCION": item.calidadProduccion,
            "CERTIFICACIONES OBTENIDAS": item.certifObtenidas,
            "CADENA DE BIOCOMERCIO": item.cadenaAlternativa,
            "HECTAREAS DE CULTIVO": item.hectareasCadena,
            "ACCESO AL MERCADO": item.accesoMercado,
            "DISPONIBILIDAD DE SERVICIOS BASICOS": item.disponibServBasicos,
            "ELETRIFICACION POTENTE": item.electrifPotenReq,
            "SERVICIO DE COMUNICACION": item.servicComunicacion,
            "OPTIMIZACION DE RECURSOS NATURALES": item.optimizRecursosNat,
            "USO DE AGROQUIMICOS PERMITIDOS": item.adoptaAgroPermitidos,
            "MINIMIZA LA GENERACION DE RESIDUOS": item.minimaGenerResiduos,
            "GENERA EMISIONES CONTAMINANTES": item.generaEmisContam,
            "AREA NATURAL PROTEGIDA": item.areaNatProtegida,
            "DERECHOS OTORGADOS": item.derechosOtorgados,
            "FECHA DE CREACIÓN": item.createDate,
            LATITUD: item.latitud,
            LONGITUD: item.longitud,
            "FECHA DE SINCRONIZACION": item.syncUpDate,
            USUARIO: item.usuId?.usu_nombres,
            ESTADO: item.estado === 1 ? "PENDIENTE" : "ACEPTADO",
          }));

          // Crear un nuevo libro de trabajo
          const workbook = XLSX.utils.book_new();

          // Convertir los datos personalizados en una hoja de trabajo
          const worksheet = XLSX.utils.json_to_sheet(exportData);

          // Añadir la hoja de trabajo al libro
          XLSX.utils.book_append_sheet(workbook, worksheet, "Datos Exportados");

          // Exportar el libro a un archivo Excel
          XLSX.writeFile(workbook, "Encuesta a productores.xlsx");
        }
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      })
      .finally(() => {
        _setLoading(false);
      });
  };

  const _changeDateIni = (_moment: any, _date: any) => {
    if (!_date) {
      _setDateIni(null);
    } else {
      _setDateIni(_date);
    }
  };

  const _changeDateFin = (_moment: any, _date: any) => {
    if (!_date) {
      _setDateFin(null);
    } else {
      _setDateFin(_date);
    }
  };

  useEffect(() => {
    _setMounted(true);
    _listarUsuarios();
    _listarFichaCampo();
    return () => {
      _setMounted(false);
    };
  }, [_listarFichaCampo, _listarUsuarios]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <>
      <TitleWidget
        data="Zonas y cadenas productivas"
        color="rgb(0 21 41)"
        size={30}
      />

      <Collapse>
        <Panel header="Filtros de búsqueda" key={1}>
          <Row gutter={[24, 24]} style={{ padding: 15 }}>
            {_perfil === 1 && (
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <label>Usuario</label>
                <Select
                  style={{
                    width: "100%",
                  }}
                  showSearch
                  allowClear
                  placeholder="Selecciona un usuario"
                  optionFilterProp="children"
                  filterOption={(input: string, option: any) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(usuario: number) => {
                    _setUsuario(usuario);
                  }}
                >
                  {_usuarios.map((value: Usuario, _index: number) => {
                    return (
                      <Option
                        key={`option_usu_${value.usu_id}`}
                        value={`${value.usu_id}`}
                      >
                        {value.usu_nombres}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            )}

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Estado</label>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Selecciona un estado"
                onChange={(estado: number) => {
                  _setEstado(estado);
                }}
              >
                {ESTADOS.map(
                  (value: { text: string; value: number }, _index: number) => {
                    return (
                      <Option
                        key={`option_estado_${value.value}`}
                        value={`${value.value}`}
                      >
                        {value.text}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Fecha de inicio</label>
              <DatePicker
                style={{ width: "100%" }}
                allowClear
                locale={locale}
                onChange={_changeDateIni}
                format={"YYYY-MM-DD"}
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Fecha de fin</label>
              <DatePicker
                style={{ width: "100%" }}
                allowClear
                locale={locale}
                onChange={_changeDateFin}
                format={"YYYY-MM-DD"}
              />
            </Col>

            <Col xs={24}>
              <Space>
                <Tag color="default">
                  Todos: {aprobados + pendientes + rechazados}
                </Tag>
                <Tag color="success">Aprobados: {aprobados}</Tag>
                <Tag color="warning">Pendientes: {pendientes}</Tag>
                <Tag color="error">Rechazados: {rechazados}</Tag>
              </Space>
            </Col>
          </Row>
        </Panel>
      </Collapse>

      <Space
        direction="vertical"
        style={{ width: "100%", margin: "20px 10px" }}
      >
        <Space
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Pagination
            current={page}
            disabled={_fichas.length === 0}
            pageSize={pageSize}
            showSizeChanger
            size="small"
            total={totalItems}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
          />

          <Button
            icon={<FileExcelOutlined />}
            style={{ width: 120 }}
            onClick={_exportExcel}
            loading={_loading}
          >
            Exportar
          </Button>
        </Space>

        <LeyendaComponent
          mobile={_mobile}
          aceptar={_perfil === 1 ? true : false}
          rechazar={_perfil === 1 ? true : false}
          ubicacion={true}
        />

        <Table
          loading={_loading}
          rowKey={"codigo"}
          pagination={false}
          columns={[
            {
              title: "Acciones",
              key: "estado",
              dataIndex: "estado",
              align: "center",
              render: _renderAcciones,
            },
            {
              title: "Estado",
              key: "estado",
              dataIndex: "estado",
              sorter: (a: FichaCampo, b: FichaCampo) => a.estado - b.estado,
              showSorterTooltip: false,
              render: _renderEstado,
            },
            {
              title: "Código",
              key: "codigo",
              dataIndex: "codigo",
              sorter: (a: FichaCampo, b: FichaCampo) => a.codigo - b.codigo,
            },
            {
              title: "RE - NOMBRE",
              key: "rpteEconNombre",
              dataIndex: "rpteEconNombre",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.rpteEconNombre.localeCompare(b.rpteEconNombre),
            },
            {
              title: "RE - APELLIDO",
              key: "rpteEconApellido",
              dataIndex: "rpteEconApellido",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.rpteEconApellido.localeCompare(b.rpteEconApellido),
            },
            {
              title: "RE - DNI",
              key: "rpteEconDNI",
              dataIndex: "rpteEconDNI",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.rpteEconDNI.localeCompare(b.rpteEconDNI),
            },
            {
              title: "RE - CARGO",
              key: "rpteEconCargo",
              dataIndex: "rpteEconCargo",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.rpteEconCargo.localeCompare(b.rpteEconCargo),
            },
            {
              title: "Celular/Teléfono",
              key: "contactoCelular",
              dataIndex: "contactoCelular",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.contactoCelular.localeCompare(b.contactoCelular),
            },
            {
              title: "Correo",
              key: "contactoCorreo",
              dataIndex: "contactoCorreo",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.contactoCorreo.localeCompare(b.contactoCorreo),
            },
            {
              title: "Dirección",
              key: "contactoDireccion",
              dataIndex: "contactoDireccion",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.contactoDireccion.localeCompare(b.contactoDireccion),
            },
            {
              title: "Nombre comercial",
              key: "ageEcoNombreComercial",
              dataIndex: "ageEcoNombreComercial",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.ageEcoNombreComercial.localeCompare(b.ageEcoNombreComercial),
            },
            {
              title: "Razón social",
              key: "ageEcoRazonSocial",
              dataIndex: "ageEcoRazonSocial",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.ageEcoRazonSocial.localeCompare(b.ageEcoRazonSocial),
            },
            {
              title: "Localidad",
              key: "ageEcoLocalidad",
              dataIndex: "ageEcoLocalidad",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.ageEcoLocalidad.localeCompare(b.ageEcoLocalidad),
            },
            {
              title: "Provincia",
              key: "ageEcoDistrito",
              dataIndex: "ageEcoDistrito",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.ageEcoDistrito.dis_pro_id.pro_nombre.localeCompare(
                  b.ageEcoDistrito.dis_pro_id.pro_nombre
                ),
              render: (_value: Distrito) => _value.dis_pro_id.pro_nombre,
            },
            {
              title: "Distrito",
              key: "ageEcoDistrito",
              dataIndex: "ageEcoDistrito",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.ageEcoDistrito.dis_nombre.localeCompare(
                  b.ageEcoDistrito.dis_nombre
                ),
              render: (_value: Distrito) => _value.dis_nombre,
            },
            {
              title: "Cantidad de socios",
              key: "ageEcoCantidadSocios",
              dataIndex: "ageEcoCantidadSocios",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.ageEcoCantidadSocios - b.ageEcoCantidadSocios,
            },
            {
              title: "Tiempo de creación",
              key: "ageEcotiempoCreacion",
              dataIndex: "ageEcotiempoCreacion",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.ageEcotiempoCreacion.localeCompare(b.ageEcotiempoCreacion),
            },
            {
              title: "Cuenta con seguridad jurídica",
              key: "ageEcoseguridadJuridica",
              dataIndex: "ageEcoseguridadJuridica",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.ageEcoseguridadJuridica.localeCompare(
                  b.ageEcoseguridadJuridica
                ),
            },
            {
              title: "Sector",
              key: "ageEcosector",
              dataIndex: "ageEcosector",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.ageEcosector.localeCompare(b.ageEcosector),
            },
            {
              title: "Cadena productiva",
              key: "ageEcocadenaProductiva",
              dataIndex: "ageEcocadenaProductiva",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.ageEcocadenaProductiva.localeCompare(
                  b.ageEcocadenaProductiva
                ),
            },
            {
              title: "Producto primario",
              key: "ageEcoproductoPrimario",
              dataIndex: "ageEcoproductoPrimario",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.ageEcoproductoPrimario.localeCompare(
                  b.ageEcoproductoPrimario
                ),
            },
            {
              title: "Producto derivado",
              key: "ageEcoproductoDerivado",
              dataIndex: "ageEcoproductoDerivado",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.ageEcoproductoDerivado.localeCompare(
                  b.ageEcoproductoDerivado
                ),
            },
            {
              title: "Generación de empleo",
              key: "generEmpleo",
              dataIndex: "generEmpleo",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.generEmpleo.localeCompare(b.generEmpleo),
            },
            {
              title: "Participación de la población joven",
              key: "partiPoblacionJoven",
              dataIndex: "partiPoblacionJoven",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.partiPoblacionJoven.localeCompare(b.partiPoblacionJoven),
            },
            {
              title: "Participación del 30% de mujeres",
              key: "partiMujer30",
              dataIndex: "partiMujer30",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.partiMujer30.localeCompare(b.partiMujer30),
            },
            {
              title: "Participación de la mujer en áreas dirigenciales",
              key: "partiMujerDir",
              dataIndex: "partiMujerDir",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.partiMujerDir.localeCompare(b.partiMujerDir),
            },
            {
              title: "COMITÉ DE MUJERES",
              key: "comitMujer",
              dataIndex: "comitMujer",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.comitMujer.localeCompare(b.comitMujer),
            },
            {
              title: "LÍDER POR POBLACION INDIGENA",
              key: "liderIndigena",
              dataIndex: "liderIndigena",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.liderIndigena.localeCompare(b.liderIndigena),
            },
            {
              title: "PARTICIPACION DE POBLACION INDIGENA",
              key: "partiIndigena",
              dataIndex: "partiIndigena",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.partiIndigena.localeCompare(b.partiIndigena),
            },
            {
              title: "PARTICIPACION DE POBLACION VULNERABLE",
              key: "partiVulnerable",
              dataIndex: "partiVulnerable",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.partiVulnerable.localeCompare(b.partiVulnerable),
            },
            {
              title: "MECANISMO DE ATENCION DE RECLAMOS",
              key: "mecanReclamos",
              dataIndex: "mecanReclamos",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.mecanReclamos.localeCompare(b.mecanReclamos),
            },
            {
              title: "ATENCION DIFERENCIADA PARA MUJERES E HIJOS",
              key: "atencDiferen",
              dataIndex: "atencDiferen",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.atencDiferen.localeCompare(b.atencDiferen),
            },
            {
              title: "PROTOCOLO CONTRA EL ACOSO",
              key: "protoAcoso",
              dataIndex: "protoAcoso",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.protoAcoso.localeCompare(b.protoAcoso),
            },
            {
              title: "ENFOQUE DE GENERO",
              key: "areaEnfoques",
              dataIndex: "areaEnfoques",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.areaEnfoques.localeCompare(b.areaEnfoques),
            },
            {
              title: "NIVEL ORGANIZACIONAL",
              key: "nivelOrganizacional",
              dataIndex: "nivelOrganizacional",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.nivelOrganizacional.localeCompare(b.nivelOrganizacional),
            },
            {
              title: "PRESENCIA DE EMPRENDEDORES",
              key: "presenciaEmprendedores",
              dataIndex: "presenciaEmprendedores",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.presenciaEmprendedores.localeCompare(
                  b.presenciaEmprendedores
                ),
            },
            {
              title: "SELLOS DE CALIDAD",
              key: "sellosCalidad",
              dataIndex: "sellosCalidad",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.sellosCalidad.localeCompare(b.sellosCalidad),
            },
            {
              title: "IMPACTO SOCIAL",
              key: "impactoSocial",
              dataIndex: "impactoSocial",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.impactoSocial.localeCompare(b.impactoSocial),
            },
            {
              title: "NIVEL DE ASOCIATIVIDAD",
              key: "nivelAsociatividad",
              dataIndex: "nivelAsociatividad",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.nivelAsociatividad.localeCompare(b.nivelAsociatividad),
            },
            {
              title: "FORMALIZADO",
              key: "formalizado",
              dataIndex: "formalizado",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.formalizado.localeCompare(b.formalizado),
            },
            {
              title: "POTENCIAL DE MERCADO",
              key: "petencialMercado",
              dataIndex: "petencialMercado",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.petencialMercado.localeCompare(b.petencialMercado),
            },
            {
              title: "ESTANDARIZACION DE NIVELES DE PROD.",
              key: "estandNivProduccion",
              dataIndex: "estandNivProduccion",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.estandNivProduccion.localeCompare(b.estandNivProduccion),
            },
            {
              title: "ARTICULACION COMERCIAL",
              key: "tieneArtComercial",
              dataIndex: "tieneArtComercial",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.tieneArtComercial.localeCompare(b.tieneArtComercial),
            },
            {
              title: "NIVEL DE ALIANZAS COMERCIALES",
              key: "nivelAlianzaCom",
              dataIndex: "nivelAlianzaCom",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.nivelAlianzaCom.localeCompare(b.nivelAlianzaCom),
            },
            {
              title: "ACCESO A CREDITOS",
              key: "accesoCreditos",
              dataIndex: "accesoCreditos",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.accesoCreditos.localeCompare(b.accesoCreditos),
            },
            {
              title: "CAPACIDAD DE PRODUCCION",
              key: "capacidadProduccion",
              dataIndex: "capacidadProduccion",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.capacidadProduccion.localeCompare(b.capacidadProduccion),
            },
            {
              title: "PRODUCCION",
              key: "produccion",
              dataIndex: "produccion",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.produccion.localeCompare(b.produccion),
            },
            {
              title: "COSTOS COMPETITIVOS",
              key: "costosCompetitivos",
              dataIndex: "costosCompetitivos",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.costosCompetitivos.localeCompare(b.costosCompetitivos),
            },
            {
              title: "ACCESO A TECNOLOGIA DE PROD.",
              key: "accesoTecnologiaProd",
              dataIndex: "accesoTecnologiaProd",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.accesoTecnologiaProd.localeCompare(b.accesoTecnologiaProd),
            },
            {
              title: "CALIDAD DE PRODUCCION",
              key: "calidadProduccion",
              dataIndex: "calidadProduccion",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.calidadProduccion.localeCompare(b.calidadProduccion),
            },
            {
              title: "CERTIFICACIONES OBTENIDAS",
              key: "certifObtenidas",
              dataIndex: "certifObtenidas",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.certifObtenidas.localeCompare(b.certifObtenidas),
            },
            {
              title: "CADENA DE BIOCOMERCIO",
              key: "cadenaAlternativa",
              dataIndex: "cadenaAlternativa",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.cadenaAlternativa.localeCompare(b.cadenaAlternativa),
            },
            {
              title: "HECTAREAS DE CULTIVO",
              key: "hectareasCadena",
              dataIndex: "hectareasCadena",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.hectareasCadena.localeCompare(b.hectareasCadena),
            },
            {
              title: "ACCESO AL MERCADO",
              key: "accesoMercado",
              dataIndex: "accesoMercado",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.accesoMercado.localeCompare(b.accesoMercado),
            },
            {
              title: "DISPONIBILIDAD DE SERVICIOS BASICOS",
              key: "disponibServBasicos",
              dataIndex: "disponibServBasicos",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.disponibServBasicos.localeCompare(b.disponibServBasicos),
            },
            {
              title: "ELETRIFICACION POTENTE",
              key: "electrifPotenReq",
              dataIndex: "electrifPotenReq",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.electrifPotenReq.localeCompare(b.electrifPotenReq),
            },
            {
              title: "SERVICIO DE COMUNICACION",
              key: "servicComunicacion",
              dataIndex: "servicComunicacion",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.servicComunicacion.localeCompare(b.servicComunicacion),
            },
            {
              title: "OPTIMIZACION DE RECURSOS NATURALES",
              key: "optimizRecursosNat",
              dataIndex: "optimizRecursosNat",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.optimizRecursosNat.localeCompare(b.optimizRecursosNat),
            },
            {
              title: "USO DE AGROQUIMICOS PERMITIDOS",
              key: "adoptaAgroPermitidos",
              dataIndex: "adoptaAgroPermitidos",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.adoptaAgroPermitidos.localeCompare(b.adoptaAgroPermitidos),
            },
            {
              title: "MINIMIZA LA GENERACION DE RESIDUOS",
              key: "minimaGenerResiduos",
              dataIndex: "minimaGenerResiduos",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.minimaGenerResiduos.localeCompare(b.minimaGenerResiduos),
            },
            {
              title: "GENERA EMISIONES CONTAMINANTES",
              key: "generaEmisContam",
              dataIndex: "generaEmisContam",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.generaEmisContam.localeCompare(b.generaEmisContam),
            },
            {
              title: "AREA NATURAL PROTEGIDA",
              key: "areaNatProtegida",
              dataIndex: "areaNatProtegida",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.areaNatProtegida.localeCompare(b.areaNatProtegida),
            },
            {
              title: "DERECHOS OTORGADOS",
              key: "derechosOtorgados",
              dataIndex: "derechosOtorgados",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.derechosOtorgados.localeCompare(b.derechosOtorgados),
            },
            {
              title: "FECHA DE CREACIÓN",
              key: "createDate",
              dataIndex: "createDate",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                moment(a.createDate, FORMATO_FECHA).unix() -
                moment(b.createDate, FORMATO_FECHA).unix(),
            },
            {
              title: "FECHA DE SINCRONIZACION",
              key: "syncUpDate",
              dataIndex: "syncUpDate",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                moment(a.syncUpDate, FORMATO_FECHA).unix() -
                moment(b.syncUpDate, FORMATO_FECHA).unix(),
            },
            {
              title: "Usuario",
              key: "usuId",
              dataIndex: "usuId",
              sorter: (a: FichaCampo, b: FichaCampo) =>
                a.usuId.usu_nombres.localeCompare(b.usuId.usu_nombres),
              render: (_value: Usuario, _row: FichaCampo, _index: number) => {
                return _value.usu_nombres;
              },
            },
          ]}
          dataSource={_fichas}
        />

        <Pagination
          current={page}
          disabled={_fichas.length === 0}
          pageSize={pageSize}
          showSizeChanger
          size="small"
          total={totalItems}
          onChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      </Space>

      {/* Modal de mapa */}
      {_ficha && (
        <Modal
          key={`location_${_ficha.codigo}`}
          visible={_visible}
          onCancel={() => {
            _setVisible(false);
          }}
          footer={[]}
          centered
        >
          <MapaComponent lat={_ficha.latitud} lng={_ficha.longitud} />
        </Modal>
      )}

      {_contextHolder}
    </>
  );
}
