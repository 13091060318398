import styled from "styled-components";

const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`;

export default function ErrorComponent() {
  return (
    <Error>
      <h1>Hubo un error, consulte con TI</h1>
    </Error>
  );
}
