import ActivoWidget from "../util/activo.widget";
import InactivoWidget from "../util/inactivo.widget";
import PendienteWidget from "../util/pendiente.widget";
import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import { PushpinFilled } from "@ant-design/icons";
import { Space } from "antd";

export default function LeyendaComponent({
  mobile = false,
  editar = false,
  borrar = false,
  aceptar = false,
  rechazar = false,
  ubicacion = false,
  activo = false,
  pendiente = false,
  rechazado = false,
}: {
  mobile?: boolean;
  editar?: boolean;
  borrar?: boolean;
  aceptar?: boolean;
  rechazar?: boolean;
  ubicacion?: boolean;
  activo?: boolean;
  pendiente?: boolean;
  rechazado?: boolean;
}) {
  return (
    <div style={{ margin: "10px 0px" }}>
      <Space
        direction={mobile ? "vertical" : "horizontal"}
        style={{ margin: "5px auto" }}
      >
        {editar ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "150px",
            }}
          >
            <EditOutlined
              style={{
                fontSize: 12,
                color: "rgb(76 175 80)",
                margin: "5px",
              }}
            />
            Editar registro
          </div>
        ) : (
          ""
        )}
        {borrar ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "150px",
            }}
          >
            <DeleteOutlined
              style={{
                fontSize: 12,
                color: "rgb(244 67 54)",
                margin: "5px",
              }}
            />
            Borrar registro
          </div>
        ) : (
          ""
        )}
        {aceptar ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "150px",
            }}
          >
            <CheckOutlined
              style={{
                fontSize: 12,
                color: "rgb(76 175 80)",
                margin: "5px",
              }}
            />
            Aceptar registro
          </div>
        ) : (
          ""
        )}
        {rechazar ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "150px",
            }}
          >
            <CloseOutlined
              style={{
                fontSize: 12,
                color: "rgb(244 67 54)",
                margin: "5px",
              }}
            />
            Rechazar registro
          </div>
        ) : (
          ""
        )}
        {ubicacion ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "150px",
            }}
          >
            <PushpinFilled
              style={{
                fontSize: 12,
                color: "rgb(0 188 212)",
                margin: "5px",
              }}
            />
            Ver ubicaci&oacute;n
          </div>
        ) : (
          ""
        )}
        {pendiente ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "150px",
            }}
          >
            <PendienteWidget margin="5px" />
            Pendiente
          </div>
        ) : (
          ""
        )}
        {activo ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "150px",
            }}
          >
            <ActivoWidget margin="5px" />
            Aceptado
          </div>
        ) : (
          ""
        )}
        {rechazado ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "150px",
            }}
          >
            <InactivoWidget margin="5px" />
            Rechazado
          </div>
        ) : (
          ""
        )}
      </Space>
    </div>
  );
}
