import { Distrito } from "./distrito";
import { Evaluacion } from "./evaluacion";
import { Institucion } from "./institucion";
import { Pendiente } from "./pendiente";
import { Plantacion } from "./plantacion";
import { SistemaSiembra } from "./sistema_siembra";
import { TipoAbono } from "./tipo_abono";
import { Usuario } from "./usuario";

export interface CacaoDataI {
  cacao_id: number;
  cacao_altitud: number;
  cacao_latitud: number;
  cacao_longitud: number;
  cacao_dis_id: Distrito;
  cacao_localidad: string;
  cacao_sector: string;
  cacao_direccion_ejecutiva: Institucion;
  cacao_plantaciones: Plantacion[];
  cacao_nombre_predio: string;
  cacao_tipo_documento: string;
  cacao_documento: string;
  cacao_celular: string;
  cacao_propietario: string;
  cacao_area: number;
  cacao_pendiente: Pendiente;
  cacao_edad: number;
  cacao_sistema_siembra: SistemaSiembra;
  cacao_cantidad_plantones: number;
  cacao_abona: string;
  cacao_tipo_abono: TipoAbono;
  cacao_hace_control: string;
  cacao_tipo_control: string;
  cacao_producto_control: string;
  cacao_fecha_control: string;
  cacao_poda: string;
  cacao_numero_poda: number;
  cacao_estado_fenologico: string;
  cacao_sombra: string;
  cacao_tipo_sombra: string;
  cacao_descripcion_sombra: string;
  cacao_rendimiento: number;
  cacao_manejo_post_cosecha: string;
  cacao_tipo_manejo: string;
  cacao_foto: string;
  cacao_observaciones: string;
  cacao_recomendaciones: string;
  cacao_create_date: string;
  cacao_sync_up_date: string;
  cacao_user_id: Usuario;
  cacao_estado: number;
  cacao_evaluaciones: Evaluacion[];
}

export class Cacao implements CacaoDataI {
  cacao_id: number;
  cacao_altitud: number;
  cacao_latitud: number;
  cacao_longitud: number;
  cacao_dis_id: Distrito;
  cacao_localidad: string;
  cacao_sector: string;
  cacao_direccion_ejecutiva: Institucion;
  cacao_plantaciones: Plantacion[];
  cacao_nombre_predio: string;
  cacao_tipo_documento: string;
  cacao_documento: string;
  cacao_celular: string;
  cacao_propietario: string;
  cacao_area: number;
  cacao_pendiente: Pendiente;
  cacao_edad: number;
  cacao_sistema_siembra: SistemaSiembra;
  cacao_cantidad_plantones: number;
  cacao_abona: string;
  cacao_tipo_abono: TipoAbono;
  cacao_hace_control: string;
  cacao_tipo_control: string;
  cacao_producto_control: string;
  cacao_fecha_control: string;
  cacao_poda: string;
  cacao_numero_poda: number;
  cacao_estado_fenologico: string;
  cacao_sombra: string;
  cacao_tipo_sombra: string;
  cacao_descripcion_sombra: string;
  cacao_rendimiento: number;
  cacao_manejo_post_cosecha: string;
  cacao_tipo_manejo: string;
  cacao_foto: string;
  cacao_observaciones: string;
  cacao_recomendaciones: string;
  cacao_create_date: string;
  cacao_sync_up_date: string;
  cacao_user_id: Usuario;
  cacao_estado: number;
  cacao_evaluaciones: Evaluacion[];

  constructor(data: CacaoDataI) {
    this.cacao_id = data.cacao_id;
    this.cacao_altitud = data.cacao_altitud;
    this.cacao_latitud = data.cacao_latitud;
    this.cacao_longitud = data.cacao_longitud;
    this.cacao_dis_id = data.cacao_dis_id;
    this.cacao_localidad = data.cacao_localidad;
    this.cacao_sector = data.cacao_sector;
    this.cacao_direccion_ejecutiva = data.cacao_direccion_ejecutiva;
    this.cacao_plantaciones = data.cacao_plantaciones;
    this.cacao_nombre_predio = data.cacao_nombre_predio;
    this.cacao_tipo_documento = data.cacao_tipo_documento;
    this.cacao_documento = data.cacao_documento;
    this.cacao_celular = data.cacao_celular;
    this.cacao_propietario = data.cacao_propietario;
    this.cacao_area = data.cacao_area;
    this.cacao_pendiente = data.cacao_pendiente;
    this.cacao_edad = data.cacao_edad;
    this.cacao_sistema_siembra = data.cacao_sistema_siembra;
    this.cacao_cantidad_plantones = data.cacao_cantidad_plantones;
    this.cacao_abona = data.cacao_abona;
    this.cacao_tipo_abono = data.cacao_tipo_abono;
    this.cacao_hace_control = data.cacao_hace_control;
    this.cacao_tipo_control = data.cacao_tipo_control;
    this.cacao_producto_control = data.cacao_producto_control;
    this.cacao_fecha_control = data.cacao_fecha_control;
    this.cacao_poda = data.cacao_poda;
    this.cacao_numero_poda = data.cacao_numero_poda;
    this.cacao_estado_fenologico = data.cacao_estado_fenologico;
    this.cacao_sombra = data.cacao_sombra;
    this.cacao_tipo_sombra = data.cacao_tipo_sombra;
    this.cacao_descripcion_sombra = data.cacao_descripcion_sombra;
    this.cacao_rendimiento = data.cacao_rendimiento;
    this.cacao_manejo_post_cosecha = data.cacao_manejo_post_cosecha;
    this.cacao_tipo_manejo = data.cacao_tipo_manejo;
    this.cacao_foto = data.cacao_foto;
    this.cacao_observaciones = data.cacao_observaciones;
    this.cacao_recomendaciones = data.cacao_recomendaciones;
    this.cacao_create_date = data.cacao_create_date;
    this.cacao_sync_up_date = data.cacao_sync_up_date;
    this.cacao_user_id = data.cacao_user_id;
    this.cacao_estado = data.cacao_estado;
    this.cacao_evaluaciones = data.cacao_evaluaciones;
  }

  _toMap() {
    return {
      cacao_id: this.cacao_id,
      cacao_altitud: this.cacao_altitud,
      cacao_latitud: this.cacao_latitud,
      cacao_longitud: this.cacao_longitud,
      cacao_dis_id: this.cacao_dis_id,
      cacao_localidad: this.cacao_localidad,
      cacao_sector: this.cacao_sector,
      cacao_direccion_ejecutiva: this.cacao_direccion_ejecutiva,
      cacao_plantaciones: this.cacao_plantaciones,
      cacao_nombre_predio: this.cacao_nombre_predio,
      cacao_tipo_documento: this.cacao_tipo_documento,
      cacao_documento: this.cacao_documento,
      cacao_celular: this.cacao_celular,
      cacao_propietario: this.cacao_propietario,
      cacao_area: this.cacao_area,
      cacao_pendiente: this.cacao_pendiente,
      cacao_edad: this.cacao_edad,
      cacao_sistema_siembra: this.cacao_sistema_siembra,
      cacao_cantidad_plantones: this.cacao_cantidad_plantones,
      cacao_abona: this.cacao_abona,
      cacao_tipo_abono: this.cacao_tipo_abono,
      cacao_hace_control: this.cacao_hace_control,
      cacao_tipo_control: this.cacao_tipo_control,
      cacao_producto_control: this.cacao_producto_control,
      cacao_fecha_control: this.cacao_fecha_control,
      cacao_poda: this.cacao_poda,
      cacao_numero_poda: this.cacao_numero_poda,
      cacao_estado_fenologico: this.cacao_estado_fenologico,
      cacao_sombra: this.cacao_sombra,
      cacao_tipo_sombra: this.cacao_tipo_sombra,
      cacao_descripcion_sombra: this.cacao_descripcion_sombra,
      cacao_rendimiento: this.cacao_rendimiento,
      cacao_manejo_post_cosecha: this.cacao_manejo_post_cosecha,
      cacao_tipo_manejo: this.cacao_tipo_manejo,
      cacao_foto: this.cacao_foto,
      cacao_observaciones: this.cacao_observaciones,
      cacao_recomendaciones: this.cacao_recomendaciones,
      cacao_create_date: this.cacao_create_date,
      cacao_sync_up_date: this.cacao_sync_up_date,
      cacao_user_id: this.cacao_user_id,
      cacao_estado: this.cacao_estado,
      cacao_evaluaciones: this.cacao_evaluaciones,
    };
  }

  _fromMap(data: CacaoDataI) {
    this.cacao_id = data.cacao_id;
    this.cacao_altitud = data.cacao_altitud;
    this.cacao_latitud = data.cacao_latitud;
    this.cacao_longitud = data.cacao_longitud;
    this.cacao_dis_id = data.cacao_dis_id;
    this.cacao_localidad = data.cacao_localidad;
    this.cacao_sector = data.cacao_sector;
    this.cacao_direccion_ejecutiva = data.cacao_direccion_ejecutiva;
    this.cacao_plantaciones = data.cacao_plantaciones;
    this.cacao_nombre_predio = data.cacao_nombre_predio;
    this.cacao_tipo_documento = data.cacao_tipo_documento;
    this.cacao_documento = data.cacao_documento;
    this.cacao_celular = data.cacao_celular;
    this.cacao_propietario = data.cacao_propietario;
    this.cacao_area = data.cacao_area;
    this.cacao_pendiente = data.cacao_pendiente;
    this.cacao_edad = data.cacao_edad;
    this.cacao_sistema_siembra = data.cacao_sistema_siembra;
    this.cacao_cantidad_plantones = data.cacao_cantidad_plantones;
    this.cacao_abona = data.cacao_abona;
    this.cacao_tipo_abono = data.cacao_tipo_abono;
    this.cacao_hace_control = data.cacao_hace_control;
    this.cacao_tipo_control = data.cacao_tipo_control;
    this.cacao_producto_control = data.cacao_producto_control;
    this.cacao_fecha_control = data.cacao_fecha_control;
    this.cacao_poda = data.cacao_poda;
    this.cacao_numero_poda = data.cacao_numero_poda;
    this.cacao_estado_fenologico = data.cacao_estado_fenologico;
    this.cacao_sombra = data.cacao_sombra;
    this.cacao_tipo_sombra = data.cacao_tipo_sombra;
    this.cacao_descripcion_sombra = data.cacao_descripcion_sombra;
    this.cacao_rendimiento = data.cacao_rendimiento;
    this.cacao_manejo_post_cosecha = data.cacao_manejo_post_cosecha;
    this.cacao_tipo_manejo = data.cacao_tipo_manejo;
    this.cacao_foto = data.cacao_foto;
    this.cacao_observaciones = data.cacao_observaciones;
    this.cacao_recomendaciones = data.cacao_recomendaciones;
    this.cacao_create_date = data.cacao_create_date;
    this.cacao_sync_up_date = data.cacao_sync_up_date;
    this.cacao_user_id = data.cacao_user_id;
    this.cacao_estado = data.cacao_estado;
    this.cacao_evaluaciones = data.cacao_evaluaciones;
  }
}
