import axios from "axios";
import Global from "../global/global";
import { APIException } from "../model/api_exception";
import { AuthService } from "../services/auth.service";
import { AxiosResponse } from "axios";
import { DataGrafico } from "../model/data_graficos";
import { DataGraficoDataI } from "../model/data_graficos";

export class APIDashboard {
  private _auth: AuthService = new AuthService();

  async _dashboardCA(
    estado?: number,
    usuario?: number,
    fechaIni?: string,
    fechaFin?: string,
    distrito?: number,
    provincia?: number,
    departamento?: number,
    cultivo?: number
  ): Promise<DataGrafico[]> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/dashboardCA`,
      {
        token: await this._auth._getToken(),
        estado,
        usuario,
        fechaIni,
        fechaFin,
        distrito,
        provincia,
        departamento,
        cultivo,
      }
    );
    if (result.status === 200) {
      let data: DataGrafico[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: DataGraficoDataI) => {
          data.push(new DataGrafico(element));
        });
      }
      return data;
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }
}
