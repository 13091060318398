import AuthContext from "../../provider/auth_provider";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import { APIException } from "../../model/api_exception";
import { AuthService } from "../../services/auth.service";
import { Button, Col, Form, Input, Modal, Row, Space, Spin } from "antd";
import { isMobile } from "react-device-detect";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Usuario } from "../../model/usuario";

const { URL_ASSETS } = Global;
export default function LoginPage() {
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);

  const _authService = useContext<AuthService>(AuthContext);
  let _navigate = useNavigate();

  const _getCurrentUser = useCallback(async () => {
    let usuario: Usuario | null = await _authService._getCurrentUser();
    if (usuario) {
      _navigate("/");
    } else {
      _setLoading(false);
    }
  }, [_authService, _navigate]);

  const _auth = async (values: any) => {
    _setLoading(true);
    await _authService
      ._auth(values.usuario, values.password)
      .then((usuario: Usuario) => {
        _modal.success({
          title: "Inicio de sesión",
          content: `Bienvenido ${usuario.usu_nombres}`,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
          onOk: () => {
            _navigate("/");
          },
        });
      })
      .catch((exception: APIException) => {
        _setLoading(false);
        _modal.error({
          title: "Inicio de sesión",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      });
  };

  useEffect(() => {
    _setMounted(true);
    _getCurrentUser();
    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <>
      <Row style={{ textAlign: "center" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <img
            style={{ margin: 15, width: "300px" }}
            src={`${URL_ASSETS}/logo.png`}
            alt="Agroat"
            title="Agroat"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form
            layout="vertical"
            onFinish={_auth}
            autoComplete="off"
            style={{ width: isMobile ? "90%" : "25%", margin: "auto" }}
          >
            <Form.Item
              label="Usuario"
              name="usuario"
              rules={[
                { required: true, message: "Por favor ingresa tu usuario" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Contraseña"
              name="password"
              rules={[
                { required: true, message: "Por favor ingresa tu contraseña" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              {_loading ? (
                <Spin />
              ) : (
                <Button type="primary" htmlType="submit">
                  Iniciar sesi&oacute;n
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Space direction="vertical">
            <p>Desarrollado por</p>
            <img
              style={{ margin: 15, width: "150px" }}
              src={`${URL_ASSETS}/agroat.png`}
              alt="Agroat"
              title="Agroat"
            />
          </Space>
        </Col>
      </Row>
      {_contextHolder}
    </>
  );
}
