import { Departamento } from "./departamento";

export interface ProvinciaDataI {
  pro_id: number;
  pro_nombre: string;
  pro_dep_id: Departamento;
}

export class Provincia implements ProvinciaDataI {
  pro_id: number;
  pro_nombre: string;
  pro_dep_id: Departamento;

  constructor(data: ProvinciaDataI) {
    this.pro_id = data.pro_id;
    this.pro_nombre = data.pro_nombre;
    this.pro_dep_id = data.pro_dep_id;
  }

  _toMap() {
    return {
      pro_id: this.pro_id,
      pro_nombre: this.pro_nombre,
      pro_dep_id: this.pro_dep_id,
    };
  }

  _fromMap(data: ProvinciaDataI) {
    this.pro_id = data.pro_id;
    this.pro_nombre = data.pro_nombre;
    this.pro_dep_id = data.pro_dep_id;
  }
}
