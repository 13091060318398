import { Cultivo } from "./cultivo";
import { Distrito } from "./distrito";
import { FTABloque } from "./ftabloque";
import { Usuario } from "./usuario";
import { Variedad } from "./variedad";

export interface FichaTecnicaAgronomicaDataI {
  fta_id: number;
  fta_latitud: number;
  fta_longitud: number;
  fta_altitud: number;
  fta_id_distrito: Distrito;
  fta_localidad: string;
  fta_sector: string;
  fta_dni: string;
  fta_nombre: string;
  fta_cultivo: Cultivo;
  fta_variedad: Variedad;
  fta_fecha_registro: string;
  fta_observaciones: string;
  fta_foto: string;
  fta_create_date: string;
  fta_sync_up_date: string;
  fta_usu_id: Usuario;
  fta_estado: number;
  fta_bloques: FTABloque[];
}

export class FichaTecnicaAgronomica implements FichaTecnicaAgronomicaDataI {
  fta_id: number;
  fta_latitud: number;
  fta_longitud: number;
  fta_altitud: number;
  fta_id_distrito: Distrito;
  fta_localidad: string;
  fta_sector: string;
  fta_dni: string;
  fta_nombre: string;
  fta_cultivo: Cultivo;
  fta_variedad: Variedad;
  fta_fecha_registro: string;
  fta_observaciones: string;
  fta_foto: string;
  fta_create_date: string;
  fta_sync_up_date: string;
  fta_usu_id: Usuario;
  fta_estado: number;
  fta_bloques: FTABloque[];

  constructor(data: FichaTecnicaAgronomicaDataI) {
    this.fta_id = data.fta_id;
    this.fta_latitud = data.fta_latitud;
    this.fta_longitud = data.fta_longitud;
    this.fta_altitud = data.fta_altitud;
    this.fta_id_distrito = data.fta_id_distrito;
    this.fta_localidad = data.fta_localidad;
    this.fta_sector = data.fta_sector;
    this.fta_dni = data.fta_dni;
    this.fta_nombre = data.fta_nombre;
    this.fta_cultivo = data.fta_cultivo;
    this.fta_variedad = data.fta_variedad;
    this.fta_fecha_registro = data.fta_fecha_registro;
    this.fta_observaciones = data.fta_observaciones;
    this.fta_foto = data.fta_foto;
    this.fta_create_date = data.fta_create_date;
    this.fta_sync_up_date = data.fta_sync_up_date;
    this.fta_usu_id = data.fta_usu_id;
    this.fta_estado = data.fta_estado;
    this.fta_bloques = data.fta_bloques;
  }

  _toMap() {
    return {
      fta_id: this.fta_id,
      fta_latitud: this.fta_latitud,
      fta_longitud: this.fta_longitud,
      fta_altitud: this.fta_altitud,
      fta_id_distrito: this.fta_id_distrito,
      fta_localidad: this.fta_localidad,
      fta_sector: this.fta_sector,
      fta_dni: this.fta_dni,
      fta_nombre: this.fta_nombre,
      fta_cultivo: this.fta_cultivo,
      fta_variedad: this.fta_variedad,
      fta_fecha_registro: this.fta_fecha_registro,
      fta_observaciones: this.fta_observaciones,
      fta_foto: this.fta_foto,
      fta_create_date: this.fta_create_date,
      fta_sync_up_date: this.fta_sync_up_date,
      fta_usu_id: this.fta_usu_id,
      fta_estado: this.fta_estado,
      fta_bloques: this.fta_bloques,
    };
  }

  _fromMap(data: FichaTecnicaAgronomicaDataI) {
    this.fta_id = data.fta_id;
    this.fta_latitud = data.fta_latitud;
    this.fta_longitud = data.fta_longitud;
    this.fta_altitud = data.fta_altitud;
    this.fta_id_distrito = data.fta_id_distrito;
    this.fta_localidad = data.fta_localidad;
    this.fta_sector = data.fta_sector;
    this.fta_dni = data.fta_dni;
    this.fta_nombre = data.fta_nombre;
    this.fta_cultivo = data.fta_cultivo;
    this.fta_variedad = data.fta_variedad;
    this.fta_fecha_registro = data.fta_fecha_registro;
    this.fta_observaciones = data.fta_observaciones;
    this.fta_foto = data.fta_foto;
    this.fta_create_date = data.fta_create_date;
    this.fta_sync_up_date = data.fta_sync_up_date;
    this.fta_usu_id = data.fta_usu_id;
    this.fta_estado = data.fta_estado;
    this.fta_bloques = data.fta_bloques;
  }
}
