export interface PodaDataI {
  pod_id: number;
  pod_descripcion: string;
  pod_estado: number;
}

export class Poda implements PodaDataI {
  pod_id: number;
  pod_descripcion: string;
  pod_estado: number;

  constructor(data: PodaDataI) {
    this.pod_id = data.pod_id;
    this.pod_descripcion = data.pod_descripcion;
    this.pod_estado = data.pod_estado;
  }

  _toMap() {
    return {
      pod_id: this.pod_id,
      pod_descripcion: this.pod_descripcion,
      pod_estado: this.pod_estado,
    };
  }

  _fromMap(data: PodaDataI) {
    this.pod_id = data.pod_id;
    this.pod_descripcion = data.pod_descripcion;
    this.pod_estado = data.pod_estado;
  }
}
