import { Distrito } from "./distrito";
import { Usuario } from "./usuario";

export interface FichaCampoDataI {
  codigo: number;
  rpteEconNombre: string;
  rpteEconApellido: string;
  rpteEconDNI: string;
  rpteEconCargo: string;
  rpteEconFirma: string;
  contactoCelular: string;
  contactoCorreo: string;
  contactoDireccion: string;
  ageEcoNombreComercial: string;
  ageEcoRazonSocial: string;
  ageEcoLocalidad: string;
  ageEcoDistrito: Distrito;
  ageEcoCantidadSocios: number;
  ageEcotiempoCreacion: string;
  ageEcoseguridadJuridica: string;
  ageEcosector: string;
  ageEcocadenaProductiva: string;
  ageEcoproductoPrimario: string;
  ageEcoproductoDerivado: string;
  generEmpleo: string;
  partiPoblacionJoven: string;
  partiMujer30: string;
  partiMujerDir: string;
  comitMujer: string;
  liderIndigena: string;
  partiIndigena: string;
  partiVulnerable: string;
  mecanReclamos: string;
  atencDiferen: string;
  protoAcoso: string;
  areaEnfoques: string;
  nivelOrganizacional: string;
  presenciaEmprendedores: string;
  sellosCalidad: string;
  impactoSocial: string;
  nivelAsociatividad: string;
  formalizado: string;
  petencialMercado: string;
  estandNivProduccion: string;
  tieneArtComercial: string;
  nivelAlianzaCom: string;
  accesoCreditos: string;
  capacidadProduccion: string;
  produccion: string;
  costosCompetitivos: string;
  accesoTecnologiaProd: string;
  calidadProduccion: string;
  certifObtenidas: string;
  cadenaAlternativa: string;
  hectareasCadena: string;
  accesoMercado: string;
  disponibServBasicos: string;
  electrifPotenReq: string;
  servicComunicacion: string;
  optimizRecursosNat: string;
  adoptaAgroPermitidos: string;
  minimaGenerResiduos: string;
  generaEmisContam: string;
  areaNatProtegida: string;
  derechosOtorgados: string;
  createDate: string;
  latitud: number;
  longitud: number;
  syncUpDate: string;
  usuId: Usuario;
  estado: number;
} 

export class FichaCampo implements FichaCampoDataI {
  codigo: number;
  rpteEconNombre: string;
  rpteEconApellido: string;
  rpteEconDNI: string;
  rpteEconCargo: string;
  rpteEconFirma: string;
  contactoCelular: string;
  contactoCorreo: string;
  contactoDireccion: string;
  ageEcoNombreComercial: string;
  ageEcoRazonSocial: string;
  ageEcoLocalidad: string;
  ageEcoDistrito: Distrito;
  ageEcoCantidadSocios: number;
  ageEcotiempoCreacion: string;
  ageEcoseguridadJuridica: string;
  ageEcosector: string;
  ageEcocadenaProductiva: string;
  ageEcoproductoPrimario: string;
  ageEcoproductoDerivado: string;
  generEmpleo: string;
  partiPoblacionJoven: string;
  partiMujer30: string;
  partiMujerDir: string;
  comitMujer: string;
  liderIndigena: string;
  partiIndigena: string;
  partiVulnerable: string;
  mecanReclamos: string;
  atencDiferen: string;
  protoAcoso: string;
  areaEnfoques: string;
  nivelOrganizacional: string;
  presenciaEmprendedores: string;
  sellosCalidad: string;
  impactoSocial: string;
  nivelAsociatividad: string;
  formalizado: string;
  petencialMercado: string;
  estandNivProduccion: string;
  tieneArtComercial: string;
  nivelAlianzaCom: string;
  accesoCreditos: string;
  capacidadProduccion: string;
  produccion: string;
  costosCompetitivos: string;
  accesoTecnologiaProd: string;
  calidadProduccion: string;
  certifObtenidas: string;
  cadenaAlternativa: string;
  hectareasCadena: string;
  accesoMercado: string;
  disponibServBasicos: string;
  electrifPotenReq: string;
  servicComunicacion: string;
  optimizRecursosNat: string;
  adoptaAgroPermitidos: string;
  minimaGenerResiduos: string;
  generaEmisContam: string;
  areaNatProtegida: string;
  derechosOtorgados: string;
  createDate: string;
  latitud: number;
  longitud: number;
  syncUpDate: string;
  usuId: Usuario;
  estado: number;

  constructor(data: FichaCampoDataI) {
    this.codigo = data.codigo;
    this.rpteEconNombre = data.rpteEconNombre;
    this.rpteEconApellido = data.rpteEconApellido;
    this.rpteEconDNI = data.rpteEconDNI;
    this.rpteEconCargo = data.rpteEconCargo;
    this.rpteEconFirma = data.rpteEconFirma;
    this.contactoCelular = data.contactoCelular;
    this.contactoCorreo = data.contactoCorreo;
    this.contactoDireccion = data.contactoDireccion;
    this.ageEcoNombreComercial = data.ageEcoNombreComercial;
    this.ageEcoRazonSocial = data.ageEcoRazonSocial;
    this.ageEcoLocalidad = data.ageEcoLocalidad;
    this.ageEcoDistrito = data.ageEcoDistrito;
    this.ageEcoCantidadSocios = data.ageEcoCantidadSocios;
    this.ageEcotiempoCreacion = data.ageEcotiempoCreacion;
    this.ageEcoseguridadJuridica = data.ageEcoseguridadJuridica;
    this.ageEcosector = data.ageEcosector;
    this.ageEcocadenaProductiva = data.ageEcocadenaProductiva;
    this.ageEcoproductoPrimario = data.ageEcoproductoPrimario;
    this.ageEcoproductoDerivado = data.ageEcoproductoDerivado;
    this.generEmpleo = data.generEmpleo;
    this.partiPoblacionJoven = data.partiPoblacionJoven;
    this.partiMujer30 = data.partiMujer30;
    this.partiMujerDir = data.partiMujerDir;
    this.comitMujer = data.comitMujer;
    this.liderIndigena = data.liderIndigena;
    this.partiIndigena = data.partiIndigena;
    this.partiVulnerable = data.partiVulnerable;
    this.mecanReclamos = data.mecanReclamos;
    this.atencDiferen = data.atencDiferen;
    this.protoAcoso = data.protoAcoso;
    this.areaEnfoques = data.areaEnfoques;
    this.nivelOrganizacional = data.nivelOrganizacional;
    this.presenciaEmprendedores = data.presenciaEmprendedores;
    this.sellosCalidad = data.sellosCalidad;
    this.impactoSocial = data.impactoSocial;
    this.nivelAsociatividad = data.nivelAsociatividad;
    this.formalizado = data.formalizado;
    this.petencialMercado = data.petencialMercado;
    this.estandNivProduccion = data.estandNivProduccion;
    this.tieneArtComercial = data.tieneArtComercial;
    this.nivelAlianzaCom = data.nivelAlianzaCom;
    this.accesoCreditos = data.accesoCreditos;
    this.capacidadProduccion = data.capacidadProduccion;
    this.produccion = data.produccion;
    this.costosCompetitivos = data.costosCompetitivos;
    this.accesoTecnologiaProd = data.accesoTecnologiaProd;
    this.calidadProduccion = data.calidadProduccion;
    this.certifObtenidas = data.certifObtenidas;
    this.cadenaAlternativa = data.cadenaAlternativa;
    this.hectareasCadena = data.hectareasCadena;
    this.accesoMercado = data.accesoMercado;
    this.disponibServBasicos = data.disponibServBasicos;
    this.electrifPotenReq = data.electrifPotenReq;
    this.servicComunicacion = data.servicComunicacion;
    this.optimizRecursosNat = data.optimizRecursosNat;
    this.adoptaAgroPermitidos = data.adoptaAgroPermitidos;
    this.minimaGenerResiduos = data.minimaGenerResiduos;
    this.generaEmisContam = data.generaEmisContam;
    this.areaNatProtegida = data.areaNatProtegida;
    this.derechosOtorgados = data.derechosOtorgados;
    this.createDate = data.createDate;
    this.latitud = data.latitud;
    this.longitud = data.longitud;
    this.syncUpDate = data.syncUpDate;
    this.usuId = data.usuId;
    this.estado = data.estado;
  }

  _toMap() {
    return {
      codigo: this.codigo,
      rpteEconNombre: this.rpteEconNombre,
      rpteEconApellido: this.rpteEconApellido,
      rpteEconDNI: this.rpteEconDNI,
      rpteEconCargo: this.rpteEconCargo,
      rpteEconFirma: this.rpteEconFirma,
      contactoCelular: this.contactoCelular,
      contactoCorreo: this.contactoCorreo,
      contactoDireccion: this.contactoDireccion,
      ageEcoNombreComercial: this.ageEcoNombreComercial,
      ageEcoRazonSocial: this.ageEcoRazonSocial,
      ageEcoLocalidad: this.ageEcoLocalidad,
      ageEcoDistrito: this.ageEcoDistrito,
      ageEcoCantidadSocios: this.ageEcoCantidadSocios,
      ageEcotiempoCreacion: this.ageEcotiempoCreacion,
      ageEcoseguridadJuridica: this.ageEcoseguridadJuridica,
      ageEcosector: this.ageEcosector,
      ageEcocadenaProductiva: this.ageEcocadenaProductiva,
      ageEcoproductoPrimario: this.ageEcoproductoPrimario,
      ageEcoproductoDerivado: this.ageEcoproductoDerivado,
      generEmpleo: this.generEmpleo,
      partiPoblacionJoven: this.partiPoblacionJoven,
      partiMujer30: this.partiMujer30,
      partiMujerDir: this.partiMujerDir,
      comitMujer: this.comitMujer,
      liderIndigena: this.liderIndigena,
      partiIndigena: this.partiIndigena,
      partiVulnerable: this.partiVulnerable,
      mecanReclamos: this.mecanReclamos,
      atencDiferen: this.atencDiferen,
      protoAcoso: this.protoAcoso,
      areaEnfoques: this.areaEnfoques,
      nivelOrganizacional: this.nivelOrganizacional,
      presenciaEmprendedores: this.presenciaEmprendedores,
      sellosCalidad: this.sellosCalidad,
      impactoSocial: this.impactoSocial,
      nivelAsociatividad: this.nivelAsociatividad,
      formalizado: this.formalizado,
      petencialMercado: this.petencialMercado,
      estandNivProduccion: this.estandNivProduccion,
      tieneArtComercial: this.tieneArtComercial,
      nivelAlianzaCom: this.nivelAlianzaCom,
      accesoCreditos: this.accesoCreditos,
      capacidadProduccion: this.capacidadProduccion,
      produccion: this.produccion,
      costosCompetitivos: this.costosCompetitivos,
      accesoTecnologiaProd: this.accesoTecnologiaProd,
      calidadProduccion: this.calidadProduccion,
      certifObtenidas: this.certifObtenidas,
      cadenaAlternativa: this.cadenaAlternativa,
      hectareasCadena: this.hectareasCadena,
      accesoMercado: this.accesoMercado,
      disponibServBasicos: this.disponibServBasicos,
      electrifPotenReq: this.electrifPotenReq,
      servicComunicacion: this.servicComunicacion,
      optimizRecursosNat: this.optimizRecursosNat,
      adoptaAgroPermitidos: this.adoptaAgroPermitidos,
      minimaGenerResiduos: this.minimaGenerResiduos,
      generaEmisContam: this.generaEmisContam,
      areaNatProtegida: this.areaNatProtegida,
      derechosOtorgados: this.derechosOtorgados,
      createDate: this.createDate,
      latitud: this.latitud,
      longitud: this.longitud,
      syncUpDate: this.syncUpDate,
      usuId: this.usuId,
      estado: this.estado,
    };
  }

  _fromMap(data: FichaCampoDataI) {
    this.codigo = data.codigo;
    this.rpteEconNombre = data.rpteEconNombre;
    this.rpteEconApellido = data.rpteEconApellido;
    this.rpteEconDNI = data.rpteEconDNI;
    this.rpteEconCargo = data.rpteEconCargo;
    this.rpteEconFirma = data.rpteEconFirma;
    this.contactoCelular = data.contactoCelular;
    this.contactoCorreo = data.contactoCorreo;
    this.contactoDireccion = data.contactoDireccion;
    this.ageEcoNombreComercial = data.ageEcoNombreComercial;
    this.ageEcoRazonSocial = data.ageEcoRazonSocial;
    this.ageEcoLocalidad = data.ageEcoLocalidad;
    this.ageEcoDistrito = data.ageEcoDistrito;
    this.ageEcoCantidadSocios = data.ageEcoCantidadSocios;
    this.ageEcotiempoCreacion = data.ageEcotiempoCreacion;
    this.ageEcoseguridadJuridica = data.ageEcoseguridadJuridica;
    this.ageEcosector = data.ageEcosector;
    this.ageEcocadenaProductiva = data.ageEcocadenaProductiva;
    this.ageEcoproductoPrimario = data.ageEcoproductoPrimario;
    this.ageEcoproductoDerivado = data.ageEcoproductoDerivado;
    this.generEmpleo = data.generEmpleo;
    this.partiPoblacionJoven = data.partiPoblacionJoven;
    this.partiMujer30 = data.partiMujer30;
    this.partiMujerDir = data.partiMujerDir;
    this.comitMujer = data.comitMujer;
    this.liderIndigena = data.liderIndigena;
    this.partiIndigena = data.partiIndigena;
    this.partiVulnerable = data.partiVulnerable;
    this.mecanReclamos = data.mecanReclamos;
    this.atencDiferen = data.atencDiferen;
    this.protoAcoso = data.protoAcoso;
    this.areaEnfoques = data.areaEnfoques;
    this.nivelOrganizacional = data.nivelOrganizacional;
    this.presenciaEmprendedores = data.presenciaEmprendedores;
    this.sellosCalidad = data.sellosCalidad;
    this.impactoSocial = data.impactoSocial;
    this.nivelAsociatividad = data.nivelAsociatividad;
    this.formalizado = data.formalizado;
    this.petencialMercado = data.petencialMercado;
    this.estandNivProduccion = data.estandNivProduccion;
    this.tieneArtComercial = data.tieneArtComercial;
    this.nivelAlianzaCom = data.nivelAlianzaCom;
    this.accesoCreditos = data.accesoCreditos;
    this.capacidadProduccion = data.capacidadProduccion;
    this.produccion = data.produccion;
    this.costosCompetitivos = data.costosCompetitivos;
    this.accesoTecnologiaProd = data.accesoTecnologiaProd;
    this.calidadProduccion = data.calidadProduccion;
    this.certifObtenidas = data.certifObtenidas;
    this.cadenaAlternativa = data.cadenaAlternativa;
    this.hectareasCadena = data.hectareasCadena;
    this.accesoMercado = data.accesoMercado;
    this.disponibServBasicos = data.disponibServBasicos;
    this.electrifPotenReq = data.electrifPotenReq;
    this.servicComunicacion = data.servicComunicacion;
    this.optimizRecursosNat = data.optimizRecursosNat;
    this.adoptaAgroPermitidos = data.adoptaAgroPermitidos;
    this.minimaGenerResiduos = data.minimaGenerResiduos;
    this.generaEmisContam = data.generaEmisContam;
    this.areaNatProtegida = data.areaNatProtegida;
    this.derechosOtorgados = data.derechosOtorgados;
    this.createDate = data.createDate;
    this.latitud = data.latitud;
    this.longitud = data.longitud;
    this.syncUpDate = data.syncUpDate;
    this.usuId = data.usuId;
    this.estado = data.estado;
  }
}
