import CultivoContext from "../../provider/cultivo_provider";
import DashboardContext from "../../provider/dashboard_provider";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import GoogleMapReact from "google-map-react";
import LoadComponent from "../components/load.component";
import locale from "antd/es/date-picker/locale/es_ES";
import TitleWidget from "../util/title.widget";
import UbigeoContext from "../../provider/ubigeo_provider";
import UsuarioContext from "../../provider/usuario_provider";
import { APICultivo } from "../../api/api_cultivo";
import { APIDashboard } from "../../api/api_dashboard";
import { APIUbigeo } from "../../api/api_ubigeo";
import { APIUsuario } from "../../api/api_usuario";
import { BarChart, ColumnChart, PieChart } from "@opd/g2plot-react";
import { CSSProperties } from "styled-components";
import { Cultivo } from "../../model/cultivo";
import { PerfilContext } from "../../context/perfil-context";
import { useCallback, useContext, useEffect, useState } from "react";
import { Usuario } from "../../model/usuario";
import {
  Card,
  Col,
  Collapse,
  DatePicker,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { Departamento } from "../../model/departamento";
import { Distrito } from "../../model/distrito";
import { Provincia } from "../../model/provincia";

interface GraphicData {
  key: string;
  value: string | number;
}
interface Point {
  lat: number;
  lng: number;
}

const { ESTADOS, KEY_GOOGLE_MAPS } = Global;
const { Text } = Typography;
export default function HomePage() {
  const [abonamientos, setAbonamientos] = useState<GraphicData[]>([]);
  const [antecedentes, setAntecedentes] = useState<GraphicData[]>([]);
  const [areaProduccion, setAreaProduccion] = useState<GraphicData[]>([]);
  const [areaTotal, setAreaTotal] = useState<GraphicData[]>([]);
  const [asistenciaTecnica, setAsistenciaTecnica] = useState<GraphicData[]>([]);
  const [controlQuimico, setControlQuimico] = useState<GraphicData[]>([]);
  const [cultivo, setCultivo] = useState<number>(0);
  const [cultivos, setCultivos] = useState<Cultivo[]>([]);
  const [departamento, setDeparmento] = useState<number>(0);
  const [departamentos, setDeparmentos] = useState<Departamento[]>([]);
  const [distrito, setDistrito] = useState<number>(0);
  const [distritos, setDistritos] = useState<Distrito[]>([]);
  const [endDate, setEndDate] = useState<any>();
  const [estado, setEstado] = useState<number>(3);
  const [estadoLegal, setEstadoLegal] = useState<GraphicData[]>([]);
  const [load, setLoad] = useState<boolean>(false);
  const [mounted, setMounted] = useState<any>(null);
  const [plagas, setPlagas] = useState<GraphicData[]>([]);
  const [podas, setPodas] = useState<GraphicData[]>([]);
  const [points, setPoints] = useState<Point[]>([]);
  const [provincia, setProvincia] = useState<number>(0);
  const [provincias, setProvincias] = useState<Provincia[]>([]);
  const [startDate, setStartDate] = useState<any>();
  const [usuario, setUsuario] = useState<number>(0);
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const { _perfil } = useContext(PerfilContext);
  const { Option } = Select;
  const { Panel } = Collapse;
  const apiCultivo = useContext<APICultivo>(CultivoContext);
  const apiDashboard = useContext<APIDashboard>(DashboardContext);
  const apiUbigeo = useContext<APIUbigeo>(UbigeoContext);
  const apiUsuario = useContext<APIUsuario>(UsuarioContext);

  const cardStyle: CSSProperties = {
    borderRadius: 20,
    textAlign: "center",
    maxHeight: 400,
  };

  const dashboardCA = useCallback(async () => {
    setLoad(true);
    await apiDashboard
      ._dashboardCA(
        estado,
        usuario,
        startDate,
        endDate,
        distrito,
        provincia,
        departamento,
        cultivo
      )
      .then((value) => {
        // Heatmap
        const tempPoints: Point[] = value.map((x) => ({
          lat: x.latitud,
          lng: x.longitud,
        }));
        setPoints(tempPoints);

        // Función auxiliar para contar ocurrencias
        const countOccurrences = (data: any[], key: string) => {
          return data.reduce((acc, item) => {
            acc[item[key]] = (acc[item[key]] || 0) + 1;
            return acc;
          }, {} as Record<string, number>);
        };

        // Función auxiliar para mapear a GraphicData[]
        const mapToGraphicData = (data: Record<string, number>) => {
          return Object.keys(data).map((key) => ({
            key,
            value: data[key],
          }));
        };

        // Podas
        const podasCount = countOccurrences(value, "podas");
        setPodas(mapToGraphicData(podasCount));

        // Abonamientos
        const abonamientosCount = countOccurrences(value, "abonamientos");
        setAbonamientos(mapToGraphicData(abonamientosCount));

        // Plagas
        const plagasCount = countOccurrences(value, "plagas");
        setPlagas(mapToGraphicData(plagasCount));

        // Control químico
        const controlQuimicoCount = countOccurrences(value, "controlQuimico");
        setControlQuimico(mapToGraphicData(controlQuimicoCount));

        // Asistencia técnica
        const asistenciaTecnicaCount = countOccurrences(
          value,
          "asistenciaTecnica"
        );
        setAsistenciaTecnica(mapToGraphicData(asistenciaTecnicaCount));

        // Área total
        const areaTotalCount = countOccurrences(value, "areaTotal");
        setAreaTotal(
          mapToGraphicData(areaTotalCount).sort((a, b) =>
            b.key.localeCompare(a.key)
          )
        );

        // Área produccion
        const areaProduccionCount = countOccurrences(value, "areaProduccion");
        setAreaProduccion(
          mapToGraphicData(areaProduccionCount).sort((a, b) =>
            b.key.localeCompare(a.key)
          )
        );

        // Estado legal
        const estadoLegalCount = countOccurrences(value, "estadoLegal");
        setEstadoLegal(mapToGraphicData(estadoLegalCount));

        // Estado legal
        const antecedentesCount = countOccurrences(value, "antecedentes");
        setAntecedentes(
          mapToGraphicData(antecedentesCount)
            .sort((a, b) => b.value - a.value)
            .slice(0, 4)
        );
      })
      .finally(() => {
        setLoad(false);
      });
  }, [
    apiDashboard,
    cultivo,
    departamento,
    distrito,
    endDate,
    estado,
    provincia,
    startDate,
    usuario,
  ]);

  const listarUsuarios = useCallback(async () => {
    await apiUsuario._listarUsuarios().then((value: Usuario[]) => {
      setUsuarios(value);
    });
  }, [apiUsuario]);

  const listarCultivos = useCallback(async () => {
    await apiCultivo._listarCultivos().then((value: Cultivo[]) => {
      setCultivos(value);
    });
  }, [apiCultivo]);

  const listarDepartamentos = useCallback(async () => {
    await apiUbigeo._listarDepartamentos().then((value: Departamento[]) => {
      setDeparmentos(value);
    });
  }, [apiUbigeo]);

  const listarProvincias = useCallback(async () => {
    await apiUbigeo
      ._listarProvincias(departamento)
      .then((value: Provincia[]) => {
        setProvincias(value);
      });
  }, [apiUbigeo, departamento]);

  const listarDistritos = useCallback(async () => {
    await apiUbigeo._listarDistritos(provincia).then((value: Distrito[]) => {
      setDistritos(value);
    });
  }, [apiUbigeo, provincia]);

  const changeStartDate = (_: any, date: any) => {
    if (!date) {
      setStartDate(null);
    } else {
      setStartDate(date);
    }
  };

  const changeEndDate = (_: any, date: any) => {
    if (!date) {
      setEndDate(null);
    } else {
      setEndDate(date);
    }
  };

  const CardDash = (title: string, subtitle: string, child: JSX.Element) => {
    return (
      <Card
        style={cardStyle}
        title={
          <>
            <TitleWidget data={title} color="rgb(0 21 41)" size={20} />
            <Text>{subtitle}</Text>
          </>
        }
      >
        <Spin spinning={load}>{child}</Spin>
      </Card>
    );
  };

  useEffect(() => {
    setMounted(true);
    listarUsuarios();
    listarCultivos();
    listarDepartamentos();
    listarProvincias();
    listarDistritos();
    dashboardCA();
    return () => {
      setMounted(false);
    };
  }, [
    dashboardCA,
    listarCultivos,
    listarDepartamentos,
    listarDistritos,
    listarProvincias,
    listarUsuarios,
  ]);

  if (mounted === null) {
    return <LoadComponent />;
  }

  if (!mounted) {
    return <ErrorComponent />;
  }

  return (
    <>
      <Collapse>
        <Panel header="Búsqueda avanzada" key={1}>
          <Row gutter={[24, 24]}>
            {_perfil === 1 && (
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <label>Usuario</label>
                <Select
                  style={{
                    width: "100%",
                  }}
                  showSearch
                  allowClear
                  placeholder="Selecciona un usuario"
                  optionFilterProp="children"
                  filterOption={(input: string, option: any) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(usuario: number) => {
                    setUsuario(usuario);
                  }}
                >
                  {usuarios.map((value: Usuario) => {
                    return (
                      <Option
                        key={`option_usu_${value.usu_id}`}
                        value={`${value.usu_id}`}
                      >
                        {value.usu_nombres}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            )}

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Estado</label>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Selecciona un estado"
                onChange={(estado: number) => {
                  setEstado(estado);
                }}
              >
                {ESTADOS.map(
                  (value: { text: string; value: number }, _index: number) => {
                    return (
                      <Option
                        key={`option_estado_${value.value}`}
                        value={`${value.value}`}
                      >
                        {value.text}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Fecha de inicio</label>
              <DatePicker
                style={{ width: "100%" }}
                allowClear
                locale={locale}
                onChange={changeStartDate}
                format={"YYYY-MM-DD"}
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Fecha de fin</label>
              <DatePicker
                style={{ width: "100%" }}
                allowClear
                locale={locale}
                onChange={changeEndDate}
                format={"YYYY-MM-DD"}
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Cultivo</label>
              <Select
                style={{
                  width: "100%",
                }}
                showSearch
                allowClear
                placeholder="Selecciona un cultivo"
                optionFilterProp="children"
                filterOption={(input: string, option: any) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(cultivo: number) => {
                  setCultivo(cultivo);
                }}
              >
                {cultivos.map((value: Cultivo, _index: number) => {
                  return (
                    <Option
                      key={`option_cul_${value.cul_id}`}
                      value={`${value.cul_id}`}
                    >
                      {value.cul_descripcion}
                    </Option>
                  );
                })}
              </Select>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Departamento</label>
              <Select
                style={{
                  width: "100%",
                }}
                showSearch
                allowClear
                placeholder="Selecciona un departamento"
                optionFilterProp="children"
                filterOption={(input: string, option: any) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(departamento: number) => {
                  setDeparmento(departamento);
                }}
              >
                {departamentos.map((value: Departamento, _index: number) => {
                  return (
                    <Option
                      key={`option_dep_${value.dep_id}`}
                      value={`${value.dep_id}`}
                    >
                      {value.dep_nombre}
                    </Option>
                  );
                })}
              </Select>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Provincia</label>
              <Select
                style={{
                  width: "100%",
                }}
                showSearch
                allowClear
                placeholder="Selecciona una provincia"
                optionFilterProp="children"
                filterOption={(input: string, option: any) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(provincia: number) => {
                  setProvincia(provincia);
                }}
              >
                {provincias.map((value: Provincia, _index: number) => {
                  return (
                    <Option
                      key={`option_pro_${value.pro_id}`}
                      value={`${value.pro_id}`}
                    >
                      {value.pro_nombre}
                    </Option>
                  );
                })}
              </Select>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Distrito</label>
              <Select
                style={{
                  width: "100%",
                }}
                showSearch
                allowClear
                placeholder="Selecciona un distrito"
                optionFilterProp="children"
                filterOption={(input: string, option: any) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(distrito: number) => {
                  setDistrito(distrito);
                }}
              >
                {distritos.map((value: Distrito, _index: number) => {
                  return (
                    <Option
                      key={`option_dis_${value.dis_id}`}
                      value={`${value.dis_id}`}
                    >
                      {value.dis_nombre}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Panel>
      </Collapse>

      <Row style={{ padding: 10 }}>
        <Col xs={24}>
          <Row gutter={[16, 16]} style={{ padding: 10 }}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: KEY_GOOGLE_MAPS,
                }}
                defaultCenter={{
                  lat: -6.4967563,
                  lng: -76.4348079,
                }}
                defaultZoom={8}
                yesIWantToUseGoogleMapApiInternals
                options={{
                  zoomControl: false,
                  heading: 10,
                  mapTypeId: "satellite",
                }}
                heatmap={{
                  positions: points,
                  options: {
                    opacity: 1,
                    radius: 10,
                  },
                }}
                heatmapLibrary={true}
                style={{
                  minHeight: 200,
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                  {CardDash(
                    "Tipos de poda",
                    "¿Qué tipo de poda se aplican en los cultivos?",
                    <PieChart
                      data={podas}
                      angleField="value"
                      colorField="key"
                      innerRadius={0.5}
                      interactions={[
                        { type: "element-selected" },
                        { type: "element-active" },
                      ]}
                      legend={{
                        position: "right",
                        animate: true,
                      }}
                      statistic={{
                        content: {
                          style: {
                            whiteSpace: "pre-wrap",
                            fontSize: "10px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        },
                      }}
                    />
                  )}
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                  {CardDash(
                    "Tipos de abonamiento",
                    "¿Cuáles son los tipos de abonamiento utilizados?",
                    <PieChart
                      data={abonamientos}
                      angleField="value"
                      colorField="key"
                      innerRadius={0.5}
                      interactions={[
                        { type: "element-selected" },
                        { type: "element-active" },
                      ]}
                      legend={{
                        position: "right",
                        animate: true,
                      }}
                      statistic={{
                        content: {
                          style: {
                            whiteSpace: "pre-wrap",
                            fontSize: "10px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        },
                      }}
                    />
                  )}
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                  {CardDash(
                    "Presencia de plagas",
                    "¿Cuántos cultivos registrados presentan plagas?",
                    <PieChart
                      data={plagas}
                      angleField="value"
                      colorField="key"
                      innerRadius={0.5}
                      interactions={[
                        { type: "element-selected" },
                        { type: "element-active" },
                      ]}
                      legend={{
                        position: "right",
                        animate: true,
                      }}
                      statistic={{
                        content: {
                          style: {
                            whiteSpace: "pre-wrap",
                            fontSize: "10px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        },
                      }}
                    />
                  )}
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                  {CardDash(
                    "Control químico",
                    "¿Cuántos agricultores realizan el control químico de sus cultivos?",
                    <PieChart
                      data={controlQuimico}
                      angleField="value"
                      colorField="key"
                      innerRadius={0.5}
                      interactions={[
                        { type: "element-selected" },
                        { type: "element-active" },
                      ]}
                      legend={{
                        position: "right",
                        animate: true,
                      }}
                      statistic={{
                        content: {
                          style: {
                            whiteSpace: "pre-wrap",
                            fontSize: "10px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        },
                      }}
                    />
                  )}
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                  {CardDash(
                    "Asitencia técnica",
                    "¿Cuántos agricultores reciben asistencia técnica?",
                    <PieChart
                      data={asistenciaTecnica}
                      angleField="value"
                      colorField="key"
                      innerRadius={0.5}
                      interactions={[
                        { type: "element-selected" },
                        { type: "element-active" },
                      ]}
                      legend={{
                        position: "right",
                        animate: true,
                      }}
                      statistic={{
                        content: {
                          style: {
                            whiteSpace: "pre-wrap",
                            fontSize: "10px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        },
                      }}
                    />
                  )}
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                  {CardDash(
                    "Estado físico legal",
                    "¿Cuál es el estado físico legal de los cultivos registrados?",
                    <PieChart
                      data={estadoLegal}
                      angleField="value"
                      colorField="key"
                      innerRadius={0.5}
                      interactions={[
                        { type: "element-selected" },
                        { type: "element-active" },
                      ]}
                      legend={{
                        position: "right",
                        animate: true,
                      }}
                      statistic={{
                        content: {
                          style: {
                            whiteSpace: "pre-wrap",
                            fontSize: "10px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        },
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row gutter={[16, 16]} style={{ padding: 10 }}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
              {CardDash(
                "Área total",
                "¿Cuál es el área total de los cultivos registrados?",
                <ColumnChart
                  data={areaTotal}
                  xField="value"
                  yField="key"
                  seriesField="key"
                  legend={{ position: "bottom" }}
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
              {CardDash(
                "Área en producción",
                "¿Cuál es el área en producción de los cultivos",
                <ColumnChart
                  data={areaProduccion}
                  xField="value"
                  yField="key"
                  seriesField="key"
                  legend={{ position: "bottom" }}
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              {CardDash(
                "Antecedentes",
                "¿Cuáles son los antecedentes de los cultivos registrados?",
                <BarChart
                  data={antecedentes}
                  xField="value"
                  yField="key"
                  seriesField="key"
                  legend={{ position: "bottom" }}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
