import styled from "styled-components";

const Pendiente = styled.div`
  background: rgb(255 235 59);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: ${(props) => props.itemProp};
`;

const PendienteWidget = ({ margin }: { margin: string }) => {
  return <Pendiente itemProp={margin} />;
};

export default PendienteWidget;
