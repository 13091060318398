import { APIException } from "../model/api_exception";
import { APIResponse } from "../model/api_response";
import { AuthService } from "../services/auth.service";
import { AxiosResponse } from "axios";
import { EstadoFenologico } from "../model/estado_fenologico";
import { EstadoFenologicoDataI } from "../model/estado_fenologico";
import axios from "axios";
import Global from "../global/global";

export class APIEstadoFenologico {
  private _auth: AuthService = new AuthService();

  async _listarEstadoFenologico(): Promise<EstadoFenologico[]> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarEstados`,
      {
        token: await this._auth._getToken(),
        flag: true,
      }
    );
    if (result.status === 200) {
      let listaEstadoFenologico: EstadoFenologico[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: EstadoFenologicoDataI) => {
          listaEstadoFenologico.push(new EstadoFenologico(element));
        });
      }
      return listaEstadoFenologico;
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _registrarEstadoFenologico(
    _estadoFenologico: EstadoFenologico
  ): Promise<APIResponse> {
    let data: any = _estadoFenologico._toMap();
    data.token = await this._auth._getToken();

    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/registrarEstado`,
      data
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _editarEstadoFenologico(
    _estadoFenologico: EstadoFenologico
  ): Promise<APIResponse> {
    let data: any = _estadoFenologico._toMap();
    data.token = await this._auth._getToken();
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/editarEstado`,
      data
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _eliminarEstadoFenologico(
    _estadoFenologico: EstadoFenologico
  ): Promise<APIResponse> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/eliminarEstado`,
      {
        token: await this._auth._getToken(),
        codigo: _estadoFenologico.ef_id,
        estado: 0,
      }
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }
}
