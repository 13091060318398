import * as XLSX from "xlsx";
import ActivoWidget from "../util/activo.widget";
import CacaoContext from "../../provider/cacao_provider";
import ErrorComponent from "../components/error.component";
import EvaluacionesComponent from "../components/evaluacion.component";
import Global from "../../global/global";
import InactivoWidget from "../util/inactivo.widget";
import LeyendaComponent from "../components/leyenda.component";
import LoadComponent from "../components/load.component";
import locale from "antd/es/date-picker/locale/es_ES";
import MapaComponent from "../components/mapa.component";
import moment from "moment";
import PendienteWidget from "../util/pendiente.widget";
import TitleWidget from "../util/title.widget";
import UsuarioContext from "../../provider/usuario_provider";
import { _getContextDashboard } from "./dashboard.page";
import { APICacao } from "../../api/api_cacao";
import { APIException } from "../../model/api_exception";
import { APIResponse } from "../../model/api_response";
import { APIUsuario } from "../../api/api_usuario";
import { Cacao } from "../../model/cacao";
import { Distrito } from "../../model/distrito";
import { Evaluacion } from "../../model/evaluacion";
import { Institucion } from "../../model/institucion";
import { Pendiente } from "../../model/pendiente";
import { PerfilContext } from "../../context/perfil-context";
import { Plantacion } from "../../model/plantacion";
import { SistemaSiembra } from "../../model/sistema_siembra";
import { TipoAbono } from "../../model/tipo_abono";
import { useCallback, useContext, useEffect, useState } from "react";
import { Usuario } from "../../model/usuario";
import {
  CheckOutlined,
  CloseOutlined,
  FileExcelOutlined,
  OrderedListOutlined,
  PushpinFilled,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Image,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";

const { ESTADOS, URL_FOTOS, ERROR_IMAGE, FORMATO_FECHA } = Global;
export default function CacaoPage() {
  const [_cacao, _setCacao] = useState<Cacao>();
  const [_cacaos, _setCacaos] = useState<Cacao[]>([]);
  const [_dateFin, _setDateFin] = useState<any>();
  const [_dateIni, _setDateIni] = useState<any>();
  const [_estado, _setEstado] = useState<number>(3);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_usuario, _setUsuario] = useState<number>(0);
  const [_usuarios, _setUsuarios] = useState<Usuario[]>([]);
  const [_visible, _setVisible] = useState<boolean>(false);
  const [_visibleEva, _setVisibleEva] = useState<boolean>(false);
  const [aprobados, setAprobados] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [pendientes, setPendientes] = useState<number>(0);
  const [rechazados, setRechazados] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const _apiCacao = useContext<APICacao>(CacaoContext);
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const { _mobile } = _getContextDashboard();
  const { _perfil } = useContext(PerfilContext);
  const { Option } = Select;
  const { Panel } = Collapse;

  const _listarUsuarios = useCallback(async () => {
    await _apiUsuario._listarUsuarios().then((value: Usuario[]) => {
      _setUsuarios(value);
    });
  }, [_apiUsuario]);

  const _listarCacao = useCallback(async () => {
    _setLoading(true);
    await _apiCacao
      ._listarCacao(_usuario, _dateIni, _dateFin, _estado, page, pageSize)
      .then((value) => {
        _setCacaos(value.data);
        setTotalItems(value.totalItems || 0);
        setPendientes(value.pendientes || 0);
        setAprobados(value.aprobados || 0);
        setRechazados(value.rechazados || 0);
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      })
      .finally(() => {
        _setLoading(false);
      });
  }, [
    _apiCacao,
    _dateFin,
    _dateIni,
    _estado,
    _modal,
    _usuario,
    page,
    pageSize,
  ]);

  const _renderEstado = (_value: number, _row: Cacao, _index: number) => {
    if (_value === 0) {
      return <InactivoWidget margin="auto" />;
    }
    if (_value === 1) {
      return <PendienteWidget margin="auto" />;
    }
    if (_value === 2) {
      return <ActivoWidget margin="auto" />;
    }
  };

  const _renderAcciones = (_value: number, _row: Cacao, _index: number) => {
    return _value === 1 && _perfil === 1 ? (
      <Space>
        <CheckOutlined
          style={{
            fontSize: 20,
            color: "rgb(76 175 80)",
          }}
          onClick={() => {
            _actualizarCacao(_row, 2);
          }}
        />
        <CloseOutlined
          style={{
            fontSize: 20,
            color: "rgb(244 67 54)",
          }}
          onClick={() => {
            _actualizarCacao(_row, 0);
          }}
        />
        <PushpinFilled
          style={{
            fontSize: 20,
            color: "rgb(0 188 212)",
          }}
          onClick={() => {
            _setCacao(_row);
            _setVisible(true);
          }}
        />
        <OrderedListOutlined
          style={{
            fontSize: 20,
            color: "rgb(96 125 139)",
          }}
          onClick={() => {
            _setCacao(_row);
            _setVisibleEva(true);
          }}
        />
      </Space>
    ) : (
      <Space>
        <PushpinFilled
          style={{
            fontSize: 20,
            color: "rgb(0 188 212)",
          }}
          onClick={() => {
            _setCacao(_row);
            _setVisible(true);
          }}
        />
        <OrderedListOutlined
          style={{
            fontSize: 20,
            color: "rgb(96 125 139)",
          }}
          onClick={() => {
            _setCacao(_row);
            _setVisibleEva(true);
          }}
        />
      </Space>
    );
  };

  const _actualizarCacao = async (_cacao: Cacao, _estado: number) => {
    _modal.confirm({
      title: `${_estado === 2 ? "Aceptar" : "Rechazar"} registro`,
      content:
        "¿Está seguro de actualizar el registro? Esta acción no se puede revertir",
      okText: `Si, ${_estado === 2 ? "aceptar" : "rechazar"}`,
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },
      onOk: async () => {
        _setLoading(true);
        await _apiCacao
          ._actualizarCacao(_cacao, _estado)
          .then((value: APIResponse) => {
            _listarCacao();
            if (value.success) {
              _modal.success({
                title: "Actualizar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            } else {
              _modal.error({
                title: "Actualizar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: "Actualizar registro",
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _exportExcel = async () => {
    _setLoading(true);
    await _apiCacao
      ._listarCacao(_usuario, _dateIni, _dateFin, _estado)
      .then((value) => {
        // Personalizamos qué columnas exportar y sus nombres
        if (value.totalItems > 0) {
          const data: Cacao[] = value.data;
          const exportData = data.map((item) => ({
            CÓDIGO: item.cacao_id,
            PROVINCIA: item.cacao_dis_id.dis_pro_id.pro_nombre,
            DISTRITO: item.cacao_dis_id.dis_nombre,
            LOCALIDAD: item.cacao_localidad,
            SECTOR: item.cacao_sector,
            "DIRECCIÓN EJECUTIVA":
              item.cacao_direccion_ejecutiva.ins_descripcion,
            PLANTACIONES: item.cacao_plantaciones
              .map((x) => x.plan_descripcion)
              .join(" - "),
            "NOMBRE DE PREDIO": item.cacao_nombre_predio,
            "TIPO DE DOCUMENTO": item.cacao_tipo_documento,
            DOCUMENTO: item.cacao_documento,
            CELULAR: item.cacao_celular,
            PROPIETARIO: item.cacao_propietario,
            AREA: item.cacao_area,
            PENDIENTE: item.cacao_pendiente.pen_descripcion,
            EDAD: item.cacao_edad,
            "SISTEMA DE SIEMBRA": item.cacao_sistema_siembra.sis_descripcion,
            "CANTIDAD PLANTONES": item.cacao_cantidad_plantones,
            ABONA: item.cacao_abona,
            "TIPO DE ABONO": item.cacao_tipo_abono?.ta_descripcion || "",
            "HACE CONTROL": item.cacao_hace_control,
            "TIPO DE CONTROL": item.cacao_tipo_control,
            "PRODUCTO DE CONTROL": item.cacao_producto_control,
            "FECHA DE CONTROL": item.cacao_fecha_control,
            PODA: item.cacao_poda,
            "NUMERO DE PODA": item.cacao_numero_poda,
            "ESTADO FENOLOGICO": item.cacao_estado_fenologico,
            SOMBRA: item.cacao_sombra,
            "TIPO DE SOMBRA": item.cacao_tipo_sombra,
            "DESCRIPCION DE SOMBRA": item.cacao_descripcion_sombra,
            RENDIMIENTO: item.cacao_rendimiento,
            "MANEJO POST COSECHA": item.cacao_manejo_post_cosecha,
            "TIPO DE MANEJO": item.cacao_tipo_manejo,
            OBSERVACIONES: item.cacao_observaciones,
            RECOMENDACIONES: item.cacao_recomendaciones,
            "FECHA DE CREACION": item.cacao_create_date,
            "FECHA DE SINCRONIZACION": item.cacao_sync_up_date,
            USUARIO: item.cacao_user_id.usu_nombres,
            ESTADO: item.cacao_estado === 1 ? "PENDIENTE" : "ACEPTADO",
            LATITUD: item.cacao_latitud.toString(),
            LONGITUD: item.cacao_longitud.toString(),
          }));

          // Crear un nuevo libro de trabajo
          const workbook = XLSX.utils.book_new();

          // Convertir los datos personalizados en una hoja de trabajo
          const worksheet = XLSX.utils.json_to_sheet(exportData);

          // Añadir la hoja de trabajo al libro
          XLSX.utils.book_append_sheet(workbook, worksheet, "Datos Exportados");

          // Segunda hoja: Lista de evaluacions con relación a cabeceras
          const evaluaciones: Evaluacion[] = [];

          // Recorrer cada registro y sus evaluacions
          data.forEach((item) => {
            item.cacao_evaluaciones.forEach((evaluacion) => {
              evaluaciones.push(evaluacion);
            });
          });

          // Convertir los datos de los evaluacions en una hoja de trabajo
          const evaluacionWorksheet = XLSX.utils.json_to_sheet(
            evaluaciones.map((evaluacion) => ({
              CACAO: evaluacion.eva_cacao_id,
              CODIGO: evaluacion.eva_id,
              LATITUD: evaluacion.eva_latitud,
              LONGITUD: evaluacion.eva_longitud,
              PLANTACION: evaluacion.eva_plan_id?.plan_descripcion,
              "TOTAL MAZORCAS": evaluacion.eva_total_mazorcas,
              "TIENE ESCOBA DE BRUJA": evaluacion.eva_tiene_escoba_de_bruja,
              "TIENE PHYTOPTHORA": evaluacion.eva_tiene_phytopthora,
              "MAZORCAS INFECTADAS PERFORADOR":
                evaluacion.eva_mazorcas_infectadas_perforador,
              "% INFECTADAS PERFORADOR":
                evaluacion.eva_porcentaje_infectadas_perforador,
              "MAZORCAS INFECTADAS MONILIA":
                evaluacion.eva_mazorcas_infectadas_monilia,
              "% INFECTADAS MONILIA":
                evaluacion.eva_porcentaje_infectadas_monilia,
              "MAZORCAS INFECTADAS ESCOBA DE BRUJA":
                evaluacion.eva_mazorcas_infectadas_bruja,
              "COJINES FLORALES": evaluacion.eva_cojines_florales,
              "N° COJINES FLORALES": evaluacion.eva_cojines_florales_cantidad,
              "BROTES TERMINALES": evaluacion.eva_brotes_terminales,
              "N° BROTES TERMINALES": evaluacion.eva_brotes_terminales_cantidad,
              "BROTES LATERALES": evaluacion.eva_brotes_laterales,
              "N° BROTES LATERALES": evaluacion.eva_brotes_laterales_cantidad,
              "% INFECTADAS ESCOBA DE BRUJA":
                evaluacion.eva_porcentaje_infectadas_bruja,
              "MAZORCAS INFECTADAS PHYTOPTHORA":
                evaluacion.eva_mazorcas_infectadas_phytopthora,
              "% INFECTADAS PHYTOPTHORA":
                evaluacion.eva_porcentaje_infectadas_phytopthora,
              "OTRAS PLAGAS": evaluacion.eva_otras_plagas,
              OBSERVACIONES: evaluacion.eva_observaciones,
              "FECHA DE CREACION": evaluacion.eva_create_date,
              "FECHA DE SINCRONIZACION": evaluacion.eva_sync_up_date,
              USUARIO: evaluacion.eva_user_id.usu_nombres,
            }))
          );

          // Añadir la segunda hoja de trabajo al libro
          XLSX.utils.book_append_sheet(
            workbook,
            evaluacionWorksheet,
            "Lista de evaluaciones"
          );

          // Exportar el libro a un archivo Excel
          XLSX.writeFile(workbook, "Cacao.xlsx");
        }
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      })
      .finally(() => {
        _setLoading(false);
      });
  };

  const _changeDateIni = (_moment: any, _date: any) => {
    if (!_date) {
      _setDateIni(null);
    } else {
      _setDateIni(_date);
    }
  };

  const _changeDateFin = (_moment: any, _date: any) => {
    if (!_date) {
      _setDateFin(null);
    } else {
      _setDateFin(_date);
    }
  };

  useEffect(() => {
    _setMounted(true);
    _listarUsuarios();
    _listarCacao();
    return () => {
      _setMounted(false);
    };
  }, [_listarCacao, _listarUsuarios]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <>
      <TitleWidget data="Cacao" color="rgb(0 21 41)" size={30} />

      <Collapse>
        <Panel header="Filtros de búsqueda" key={1}>
          <Row gutter={[24, 24]} style={{ padding: 15 }}>
            {_perfil === 1 && (
              <>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <label>Usuario</label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    allowClear
                    placeholder="Selecciona un usuario"
                    optionFilterProp="children"
                    filterOption={(input: string, option: any) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(usuario: number) => {
                      _setUsuario(usuario);
                    }}
                  >
                    {_usuarios.map((value: Usuario, _index: number) => {
                      return (
                        <Option
                          key={`option_usu_${value.usu_id}`}
                          value={`${value.usu_id}`}
                        >
                          {value.usu_nombres}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>

                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <label>Estado</label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    placeholder="Selecciona un estado"
                    onChange={(estado: number) => {
                      _setEstado(estado);
                    }}
                  >
                    {ESTADOS.map(
                      (
                        value: { text: string; value: number },
                        _index: number
                      ) => {
                        return (
                          <Option
                            key={`option_estado_${value.value}`}
                            value={`${value.value}`}
                          >
                            {value.text}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Col>
              </>
            )}

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Fecha de inicio</label>
              <DatePicker
                style={{ width: "100%" }}
                allowClear
                locale={locale}
                onChange={_changeDateIni}
                format={"YYYY-MM-DD"}
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Fecha de fin</label>
              <DatePicker
                style={{ width: "100%" }}
                allowClear
                locale={locale}
                onChange={_changeDateFin}
                format={"YYYY-MM-DD"}
              />
            </Col>

            <Col xs={24}>
              <Space>
                <Tag color="default">
                  Todos: {aprobados + pendientes + rechazados}
                </Tag>
                <Tag color="success">Aprobados: {aprobados}</Tag>
                <Tag color="warning">Pendientes: {pendientes}</Tag>
                <Tag color="error">Rechazados: {rechazados}</Tag>
              </Space>
            </Col>
          </Row>
        </Panel>
      </Collapse>

      <Space
        direction="vertical"
        style={{ width: "100%", margin: "20px 10px" }}
      >
        <Space
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Pagination
            current={page}
            disabled={_cacaos.length === 0}
            pageSize={pageSize}
            showSizeChanger
            size="small"
            total={totalItems}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
          />

          <Button
            icon={<FileExcelOutlined />}
            style={{ width: 120 }}
            onClick={_exportExcel}
            loading={_loading}
          >
            Exportar
          </Button>
        </Space>

        <LeyendaComponent
          mobile={_mobile}
          aceptar={_perfil === 1 ? true : false}
          rechazar={_perfil === 1 ? true : false}
          ubicacion={true}
        />

        <Table
          loading={_loading}
          rowKey={"cacao_id"}
          pagination={false}
          columns={[
            {
              title: "Acciones",
              key: "cacao_estado",
              dataIndex: "cacao_estado",
              align: "center",
              render: _renderAcciones,
            },
            {
              title: "Estado",
              key: "cacao_estado",
              dataIndex: "cacao_estado",
              sorter: (a: Cacao, b: Cacao) => a.cacao_estado - b.cacao_estado,
              showSorterTooltip: false,
              render: _renderEstado,
            },
            {
              title: "Código",
              key: "cacao_id",
              dataIndex: "cacao_id",
              sorter: (a: Cacao, b: Cacao) => a.cacao_id - b.cacao_id,
            },
            {
              title: "Foto",
              key: "cacao_foto",
              dataIndex: "cacao_foto",
              render: (_value: string, _row: Cacao, _index: number) => {
                return (
                  <Image
                    width={100}
                    height={50}
                    src={`${URL_FOTOS}/${_value}`}
                    fallback={ERROR_IMAGE}
                    style={{ borderRadius: 10 }}
                  />
                );
              },
            },
            {
              title: "Distrito",
              key: "cacao_dis_id",
              dataIndex: "cacao_dis_id",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_dis_id.dis_nombre.localeCompare(
                  b.cacao_dis_id.dis_nombre
                ),
              render: (_value: Distrito, _row: Cacao, _index: number) => {
                return _value.dis_nombre;
              },
            },
            {
              title: "Localidad",
              key: "cacao_localidad",
              dataIndex: "cacao_localidad",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_localidad.localeCompare(b.cacao_localidad),
            },
            {
              title: "Sector",
              key: "cacao_sector",
              dataIndex: "cacao_sector",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_sector.localeCompare(b.cacao_sector),
            },
            {
              title: "Dirección ejecutiva",
              key: "cacao_direccion_ejecutiva",
              dataIndex: "cacao_direccion_ejecutiva",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_direccion_ejecutiva.ins_descripcion.localeCompare(
                  b.cacao_direccion_ejecutiva.ins_descripcion
                ),
              render: (_value: Institucion, _row: Cacao, _index: number) => {
                return _value.ins_descripcion;
              },
            },
            {
              title: "Plantaciones",
              key: "cacao_plantaciones",
              dataIndex: "cacao_plantaciones",
              render: (_value: Plantacion[], _row: Cacao, _index: number) => {
                return _value
                  .map((plantacion) => plantacion.plan_descripcion)
                  .join(" - ");
              },
            },
            {
              title: "Nombre del predio",
              key: "cacao_nombre_predio",
              dataIndex: "cacao_nombre_predio",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_nombre_predio.localeCompare(b.cacao_nombre_predio),
            },
            {
              title: "Tipo de documento",
              key: "cacao_tipo_documento",
              dataIndex: "cacao_tipo_documento",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_tipo_documento.localeCompare(b.cacao_tipo_documento),
            },
            {
              title: "N° Documento",
              key: "cacao_documento",
              dataIndex: "cacao_documento",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_documento.localeCompare(b.cacao_documento),
            },
            {
              title: "Celular",
              key: "cacao_celular",
              dataIndex: "cacao_celular",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_celular.localeCompare(b.cacao_celular),
            },
            {
              title: "Propietario",
              key: "cacao_propietario",
              dataIndex: "cacao_propietario",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_propietario.localeCompare(b.cacao_propietario),
            },
            {
              title: "Área",
              key: "cacao_area",
              dataIndex: "cacao_area",
              sorter: (a: Cacao, b: Cacao) => a.cacao_area - b.cacao_area,
            },
            {
              title: "Pendiente",
              key: "cacao_pendiente",
              dataIndex: "cacao_pendiente",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_pendiente.pen_descripcion.localeCompare(
                  b.cacao_pendiente.pen_descripcion
                ),
              render: (_value: Pendiente, _row: Cacao, _index: number) => {
                return _value.pen_descripcion;
              },
            },
            {
              title: "Edad",
              key: "cacao_edad",
              dataIndex: "cacao_edad",
              sorter: (a: Cacao, b: Cacao) => a.cacao_edad - b.cacao_edad,
            },
            {
              title: "Sistema de siembra",
              key: "cacao_sistema_siembra",
              dataIndex: "cacao_sistema_siembra",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_sistema_siembra.sis_descripcion.localeCompare(
                  b.cacao_sistema_siembra.sis_descripcion
                ),
              render: (_value: SistemaSiembra, _row: Cacao, _index: number) => {
                return _value.sis_descripcion;
              },
            },
            {
              title: "Cantidad de plantones",
              key: "cacao_cantidad_plantones",
              dataIndex: "cacao_cantidad_plantones",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_cantidad_plantones - b.cacao_cantidad_plantones,
            },
            {
              title: "Abona",
              key: "cacao_abona",
              dataIndex: "cacao_abona",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_abona.localeCompare(b.cacao_abona),
            },
            {
              title: "Tipo abono",
              key: "cacao_tipo_abono",
              dataIndex: "cacao_tipo_abono",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_tipo_abono.ta_descripcion.localeCompare(
                  b.cacao_tipo_abono.ta_descripcion
                ),
              render: (_value: TipoAbono, _row: Cacao, _index: number) => {
                return _value !== null ? _value.ta_descripcion : "";
              },
            },
            {
              title: "Hace control",
              key: "cacao_hace_control",
              dataIndex: "cacao_hace_control",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_hace_control.localeCompare(b.cacao_hace_control),
            },
            {
              title: "Tipo control",
              key: "cacao_tipo_control",
              dataIndex: "cacao_tipo_control",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_tipo_control.localeCompare(b.cacao_tipo_control),
            },
            {
              title: "Producto control",
              key: "cacao_producto_control",
              dataIndex: "cacao_producto_control",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_producto_control.localeCompare(
                  b.cacao_producto_control
                ),
            },
            {
              title: "Fecha control",
              key: "cacao_fecha_control",
              dataIndex: "cacao_fecha_control",
              sorter: (a: Cacao, b: Cacao) =>
                moment(a.cacao_fecha_control, FORMATO_FECHA).unix() -
                moment(b.cacao_fecha_control, FORMATO_FECHA).unix(),
            },
            {
              title: "Poda",
              key: "cacao_poda",
              dataIndex: "cacao_poda",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_poda.localeCompare(b.cacao_poda),
            },
            {
              title: "Número de poda",
              key: "cacao_numero_poda",
              dataIndex: "cacao_numero_poda",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_numero_poda - b.cacao_numero_poda,
            },
            {
              title: "Estado fenologico",
              key: "cacao_estado_fenologico",
              dataIndex: "cacao_estado_fenologico",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_estado_fenologico.localeCompare(
                  b.cacao_estado_fenologico
                ),
            },
            {
              title: "Sombra",
              key: "cacao_sombra",
              dataIndex: "cacao_sombra",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_sombra.localeCompare(b.cacao_sombra),
            },
            {
              title: "Tipo sombra",
              key: "cacao_tipo_sombra",
              dataIndex: "cacao_tipo_sombra",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_tipo_sombra.localeCompare(b.cacao_tipo_sombra),
            },
            {
              title: "Descripción sombra",
              key: "cacao_descripcion_sombra",
              dataIndex: "cacao_descripcion_sombra",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_descripcion_sombra.localeCompare(
                  b.cacao_descripcion_sombra
                ),
            },
            {
              title: "Rendimiento",
              key: "cacao_rendimiento",
              dataIndex: "cacao_rendimiento",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_rendimiento - b.cacao_rendimiento,
            },
            {
              title: "Manejo postcosecha",
              key: "cacao_manejo_post_cosecha",
              dataIndex: "cacao_manejo_post_cosecha",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_manejo_post_cosecha.localeCompare(
                  b.cacao_manejo_post_cosecha
                ),
            },
            {
              title: "Tipo manejo",
              key: "cacao_tipo_manejo",
              dataIndex: "cacao_tipo_manejo",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_tipo_manejo.localeCompare(b.cacao_tipo_manejo),
            },
            {
              title: "Observaciones",
              key: "cacao_observaciones",
              dataIndex: "cacao_observaciones",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_observaciones.localeCompare(b.cacao_observaciones),
            },
            {
              title: "Recomendaciones",
              key: "cacao_recomendaciones",
              dataIndex: "cacao_recomendaciones",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_recomendaciones.localeCompare(b.cacao_recomendaciones),
            },
            {
              title: "Fecha de sincronización",
              key: "cacao_sync_up_date",
              dataIndex: "cacao_sync_up_date",
              sorter: (a: Cacao, b: Cacao) =>
                moment(a.cacao_sync_up_date, FORMATO_FECHA).unix() -
                moment(b.cacao_sync_up_date, FORMATO_FECHA).unix(),
            },
            {
              title: "Usuario",
              key: "cacao_user_id",
              dataIndex: "cacao_user_id",
              sorter: (a: Cacao, b: Cacao) =>
                a.cacao_user_id.usu_nombres.localeCompare(
                  b.cacao_user_id.usu_nombres
                ),
              render: (_value: Usuario, _row: Cacao, _index: number) => {
                return _value.usu_nombres;
              },
            },
          ]}
          dataSource={_cacaos}
        />

        <Pagination
          current={page}
          disabled={_cacaos.length === 0}
          pageSize={pageSize}
          showSizeChanger
          size="small"
          total={totalItems}
          onChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      </Space>

      {/* Modal ubicación */}
      {_cacao && (
        <Modal
          key={`location_${_cacao.cacao_id}`}
          visible={_visible}
          onCancel={() => {
            _setVisible(false);
          }}
          footer={[]}
          centered
        >
          <MapaComponent
            lat={_cacao.cacao_latitud}
            lng={_cacao.cacao_longitud}
          />
        </Modal>
      )}

      {/* Modal evaluaciones */}
      {_cacao && (
        <Modal
          key={`evaluacion_${_cacao.cacao_id}`}
          visible={_visibleEva}
          onCancel={() => {
            _setVisibleEva(false);
          }}
          footer={[]}
          centered
          width={"1500px"}
        >
          <EvaluacionesComponent cacao={_cacao} />
        </Modal>
      )}

      {_contextHolder}
    </>
  );
}
