import axios from "axios";
import Global from "../global/global";
import { APIException } from "../model/api_exception";
import { APIResponse } from "../model/api_response";
import { AuthService } from "../services/auth.service";
import { AxiosResponse } from "axios";
import { FichaTecnicaAgronomica } from "../model/fichatecnicaagronomica";
import { FichaTecnicaAgronomicaDataI } from "../model/fichatecnicaagronomica";

interface FTAPagination {
  data: FichaTecnicaAgronomica[];
  totalItems: number;
  pendientes: number;
  aprobados: number;
  rechazados: number;
}

export class APIFichaTecnicaAgronomica {
  private _auth: AuthService = new AuthService();

  async _listarFichaTecnicaAgronomica(
    usuario?: number,
    fechaIni?: string,
    fechaFin?: string,
    estado?: number,
    page?: number,
    perPage?: number
  ): Promise<FTAPagination> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarFichaTecnicaAgronomica`,
      {
        token: await this._auth._getToken(),
        usuario,
        fechaIni,
        fechaFin,
        estado,
        page,
        perPage,
      }
    );
    if (result.status === 200) {
      let data: FichaTecnicaAgronomica[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: FichaTecnicaAgronomicaDataI) => {
          data.push(new FichaTecnicaAgronomica(element));
        });
      }
      let totalItems = result.data.totalItems;
      let pendientes = result.data.pendientes;
      let aprobados = result.data.aprobados;
      let rechazados = result.data.rechazados;
      return { data, totalItems, pendientes, aprobados, rechazados };
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _actualizarFichaTecnicaAgronomica(
    _fichatecnicaagronomica: FichaTecnicaAgronomica,
    _estado: number
  ): Promise<APIResponse> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/actualizarFichaTecnicaAgronomica`,
      {
        token: await this._auth._getToken(),
        codigo: _fichatecnicaagronomica.fta_id,
        estado: _estado,
      }
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }
}
