import * as XLSX from "xlsx";
import ActivoWidget from "../util/activo.widget";
import EncuestaProductoresContext from "../../provider/encuesta_productores_provider";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import InactivoWidget from "../util/inactivo.widget";
import LeyendaComponent from "../components/leyenda.component";
import LoadComponent from "../components/load.component";
import locale from "antd/es/date-picker/locale/es_ES";
import MapaComponent from "../components/mapa.component";
import moment from "moment";
import PendienteWidget from "../util/pendiente.widget";
import TitleWidget from "../util/title.widget";
import UsuarioContext from "../../provider/usuario_provider";
import { _getContextDashboard } from "./dashboard.page";
import { APIEncuestaProductores } from "../../api/api_encuesta_productores";
import { APIException } from "../../model/api_exception";
import { APIResponse } from "../../model/api_response";
import { APIUsuario } from "../../api/api_usuario";
import { Cliente } from "../../model/cliente";
import { Cultivo } from "../../model/cultivo";
import { Distrito } from "../../model/distrito";
import { EncuestaProductores } from "../../model/encuesta_productores";
import { EstadoFisicoLegal } from "../../model/estado_fisico_legal";
import { PerfilContext } from "../../context/perfil-context";
import { useCallback, useContext, useEffect, useState } from "react";
import { Usuario } from "../../model/usuario";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Image,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  FileExcelOutlined,
  PushpinFilled,
} from "@ant-design/icons";

const { ESTADOS, URL_FOTOS, ERROR_IMAGE, FORMATO_FECHA } = Global;
export default function EncuestaProductoresPage() {
  const [_dateFin, _setDateFin] = useState<any>();
  const [_dateIni, _setDateIni] = useState<any>();
  const [_encuesta, _setEncuesta] = useState<EncuestaProductores>();
  const [_encuestas, _setEncuestas] = useState<EncuestaProductores[]>([]);
  const [_estado, _setEstado] = useState<number>(3);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_usuario, _setUsuario] = useState<number>(0);
  const [_usuarios, _setUsuarios] = useState<Usuario[]>([]);
  const [_visible, _setVisible] = useState<boolean>(false);
  const [aprobados, setAprobados] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [pendientes, setPendientes] = useState<number>(0);
  const [rechazados, setRechazados] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const _apiEncuestaProductores = useContext<APIEncuestaProductores>(
    EncuestaProductoresContext
  );
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const { _mobile } = _getContextDashboard();
  const { _perfil } = useContext(PerfilContext);
  const { Option } = Select;
  const { Panel } = Collapse;

  const _listarUsuarios = useCallback(async () => {
    await _apiUsuario._listarUsuarios().then((value: Usuario[]) => {
      _setUsuarios(value);
    });
  }, [_apiUsuario]);

  const _listarEncuestaProductores = useCallback(async () => {
    _setLoading(true);
    await _apiEncuestaProductores
      ._listarEncuestaProductores(
        _usuario,
        _dateIni,
        _dateFin,
        _estado,
        page,
        pageSize
      )
      .then((value) => {
        _setEncuestas(value.data);
        setTotalItems(value.totalItems || 0);
        setPendientes(value.pendientes || 0);
        setAprobados(value.aprobados || 0);
        setRechazados(value.rechazados || 0);
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      })
      .finally(() => {
        _setLoading(false);
      });
  }, [
    _apiEncuestaProductores,
    _dateFin,
    _dateIni,
    _estado,
    _modal,
    _usuario,
    page,
    pageSize,
  ]);

  const _renderEstado = (
    _value: number,
    _row: EncuestaProductores,
    _index: number
  ) => {
    if (_value === 0) {
      return <InactivoWidget margin="auto" />;
    }
    if (_value === 1) {
      return <PendienteWidget margin="auto" />;
    }
    if (_value === 2) {
      return <ActivoWidget margin="auto" />;
    }
  };

  const _renderAcciones = (
    _value: number,
    _row: EncuestaProductores,
    _index: number
  ) => {
    return _value === 1 && _perfil === 1 ? (
      <Space>
        <CheckOutlined
          style={{
            fontSize: 20,
            color: "rgb(76 175 80)",
          }}
          onClick={() => {
            _actualizarEncuestaProductores(_row, 2);
          }}
        />
        <CloseOutlined
          style={{
            fontSize: 20,
            color: "rgb(244 67 54)",
          }}
          onClick={() => {
            _actualizarEncuestaProductores(_row, 0);
          }}
        />
        <PushpinFilled
          style={{
            fontSize: 20,
            color: "rgb(0 188 212)",
          }}
          onClick={() => {
            _setEncuesta(_row);
            _setVisible(true);
          }}
        />
      </Space>
    ) : (
      <Space>
        <PushpinFilled
          style={{
            fontSize: 20,
            color: "rgb(0 188 212)",
          }}
          onClick={() => {
            _setEncuesta(_row);
            _setVisible(true);
          }}
        />
      </Space>
    );
  };

  const _actualizarEncuestaProductores = async (
    _encuesta: EncuestaProductores,
    _estado: number
  ) => {
    _modal.confirm({
      title: `${_estado === 2 ? "Aceptar" : "Rechazar"} registro`,
      content:
        "¿Está seguro de actualizar el registro? Esta acción no se puede revertir",
      okText: `Si, ${_estado === 2 ? "aceptar" : "rechazar"}`,
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },
      onOk: async () => {
        _setLoading(true);
        await _apiEncuestaProductores
          ._actualizarEncuestaProductores(_encuesta, _estado)
          .then((value: APIResponse) => {
            _listarEncuestaProductores();
            if (value.success) {
              _modal.success({
                title: "Actualizar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            } else {
              _modal.error({
                title: "Actualizar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: "Actualizar registro",
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _exportExcel = async () => {
    _setLoading(true);
    await _apiEncuestaProductores
      ._listarEncuestaProductores(_usuario, _dateIni, _dateFin, _estado)
      .then((value) => {
        if (value.totalItems > 0) {
          const data: EncuestaProductores[] = value.data;
          // Personalizamos qué columnas exportar y sus nombres
          const exportData = data.map((item) => ({
            CÓDIGO: item.enc_id,
            NOMBRE: item.enc_nombre,
            DNI: item.enc_dni,
            CELULAR: item.enc_celular,
            DIRECCION: item.enc_direccion,
            LOCALIDAD: item.enc_localidad,
            SECTOR: item.enc_sector,
            PROVINCIA: item.enc_dis_id_ubicacion?.dis_pro_id?.pro_nombre,
            DISTRITO: item.enc_dis_id_ubicacion?.dis_nombre,
            "COORDENADA UTM (X)": item.enc_latitud,
            "COORDENADA UTM (Y)": item.enc_longitud,
            ALTITUD: item.enc_altitud,
            AREA: item.enc_area,
            "CONDICION DEL PREDIO": item.enc_cag_efl_id?.efl_descripcion,
            "TITULO DE PROPIEDAD": item.enc_tiene_titulo_propiedad,
            "OTRO DOCUMENTO": item.enc_otro_documento,
            "CULTIVO PRINCIPAL": item.enc_cag_cul_id_principal?.cul_descripcion,
            "CULTIVOS SECUNDARIOS": item.enc_cultivos_en_campo
              ?.map((x) => x.cul_descripcion)
              .join(", "),
            "TIPO DE PRODUCCIÓN": item.enc_tipo_produccion,
            "NIVEL DE TECNOLOGÍA": item.enc_nivel_tecnologia,
            "SISTEMA DE RIEGO": item.enc_sistema_riego,
            "AREA TOTAL EN PRODUCCION": item.enc_area_total_produccion,
            "AREA SEMBRADA": item.enc_area_sembrada,
            "AREA COSECHADA": item.enc_area_cosechada,
            RENDIMIENTO: item.enc_rendimiento,
            "PRECIO DE VENTA": item.enc_precio_venta,
            "MANEJA COSTOS DE PRODUCCION": item.enc_maneja_costos_produccion,
            "COSTO DE PRODUCCION POR HA": item.enc_costo_produccion_ha,
            "A QUIEN COMERCIALIZA": item.enc_cliente_comercializacion,
            "A CUANTO COMERCIALIZA": item.enc_precio_comercializacion,
            "PERTENECE A ORGANIZACION": item.enc_pertenece_organizacion,
            ORGANIZACION: item.enc_organizacion,
            "OTRA ORGANIZACION": item.enc_otro_organizacion,
            DENOMINACION: item.enc_denominacion,
            "LO COMERCIALIZA SU ORGANIZACION":
              item.enc_comercializa_organizacion,
            "RECIBIÓ FINANCIAMIENTO": item.enc_recibio_financiamiento,
            "COMENTARIO FINAN.": item.enc_comentario_financiamiento,
            "FUE BENIFICARIO": item.enc_fue_beneficiario,
            "COMENTARIO BEN.": item.enc_comentario_beneficiario,
            "FECHA DE VISITA": item.enc_fecha_visita,
            "FECHA DE SINCRONIZACIÓN": item.enc_sync_up_date,
            USUARIO: item.enc_usu_id?.usu_nombres,
            ESTADO: item.enc_estado === 1 ? "PENDIENTE" : "ACEPTADO",
          }));

          // Crear un nuevo libro de trabajo
          const workbook = XLSX.utils.book_new();

          // Convertir los datos personalizados en una hoja de trabajo
          const worksheet = XLSX.utils.json_to_sheet(exportData);

          // Añadir la hoja de trabajo al libro
          XLSX.utils.book_append_sheet(workbook, worksheet, "Datos Exportados");

          // Exportar el libro a un archivo Excel
          XLSX.writeFile(workbook, "Encuesta a productores.xlsx");
        }
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      })
      .finally(() => {
        _setLoading(false);
      });
  };

  const _changeDateIni = (_moment: any, _date: any) => {
    if (!_date) {
      _setDateIni(null);
    } else {
      _setDateIni(_date);
    }
  };

  const _changeDateFin = (_moment: any, _date: any) => {
    if (!_date) {
      _setDateFin(null);
    } else {
      _setDateFin(_date);
    }
  };

  useEffect(() => {
    _setMounted(true);
    _listarUsuarios();
    _listarEncuestaProductores();
    return () => {
      _setMounted(false);
    };
  }, [_listarEncuestaProductores, _listarUsuarios]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <>
      <TitleWidget
        data="Encuesta a productores"
        color="rgb(0 21 41)"
        size={30}
      />

      <Collapse>
        <Panel header="Filtros de búsqueda" key={1}>
          <Row gutter={[24, 24]} style={{ padding: 15 }}>
            {_perfil === 1 && (
              <>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <label>Usuario</label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    allowClear
                    placeholder="Selecciona un usuario"
                    optionFilterProp="children"
                    filterOption={(input: string, option: any) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(usuario: number) => {
                      _setUsuario(usuario);
                    }}
                  >
                    {_usuarios.map((value: Usuario, _index: number) => {
                      return (
                        <Option
                          key={`option_usu_${value.usu_id}`}
                          value={`${value.usu_id}`}
                        >
                          {value.usu_nombres}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>

                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <label>Estado</label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    placeholder="Selecciona un estado"
                    onChange={(estado: number) => {
                      _setEstado(estado);
                    }}
                  >
                    {ESTADOS.map(
                      (
                        value: { text: string; value: number },
                        _index: number
                      ) => {
                        return (
                          <Option
                            key={`option_estado_${value.value}`}
                            value={`${value.value}`}
                          >
                            {value.text}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Col>
              </>
            )}

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Fecha de inicio</label>
              <DatePicker
                style={{ width: "100%" }}
                allowClear
                locale={locale}
                onChange={_changeDateIni}
                format={"YYYY-MM-DD"}
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Fecha de fin</label>
              <DatePicker
                style={{ width: "100%" }}
                allowClear
                locale={locale}
                onChange={_changeDateFin}
                format={"YYYY-MM-DD"}
              />
            </Col>

            <Col xs={24}>
              <Space>
                <Tag color="default">
                  Todos: {aprobados + pendientes + rechazados}
                </Tag>
                <Tag color="success">Aprobados: {aprobados}</Tag>
                <Tag color="warning">Pendientes: {pendientes}</Tag>
                <Tag color="error">Rechazados: {rechazados}</Tag>
              </Space>
            </Col>
          </Row>
        </Panel>
      </Collapse>

      <Space
        direction="vertical"
        style={{ width: "100%", margin: "20px 10px" }}
      >
        <Space
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Pagination
            current={page}
            disabled={_encuestas.length === 0}
            pageSize={pageSize}
            showSizeChanger
            size="small"
            total={totalItems}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
          />

          <Button
            icon={<FileExcelOutlined />}
            style={{ width: 120 }}
            onClick={_exportExcel}
            loading={_loading}
          >
            Exportar
          </Button>
        </Space>

        <LeyendaComponent
          mobile={_mobile}
          aceptar={_perfil === 1 ? true : false}
          rechazar={_perfil === 1 ? true : false}
          ubicacion={true}
        />

        <Table
          loading={_loading}
          rowKey={"enc_id"}
          pagination={false}
          columns={[
            {
              title: "Acciones",
              key: "enc_estado",
              dataIndex: "enc_estado",
              align: "center",
              render: _renderAcciones,
            },
            {
              title: "Estado",
              key: "enc_estado",
              dataIndex: "enc_estado",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_estado - b.enc_estado,
              showSorterTooltip: false,
              render: _renderEstado,
            },
            {
              title: "Código",
              key: "enc_id",
              dataIndex: "enc_id",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_id - b.enc_id,
            },
            {
              title: "Foto",
              key: "enc_foto",
              dataIndex: "enc_foto",
              render: (
                _value: string,
                _row: EncuestaProductores,
                _index: number
              ) => {
                return (
                  <Image
                    width={100}
                    height={50}
                    src={`${URL_FOTOS}/${_value}`}
                    fallback={ERROR_IMAGE}
                    style={{ borderRadius: 10 }}
                  />
                );
              },
            },
            {
              title: "Nombre",
              key: "enc_nombre",
              dataIndex: "enc_nombre",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_nombre.localeCompare(b.enc_nombre),
            },
            {
              title: "DNI",
              key: "enc_dni",
              dataIndex: "enc_dni",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_dni.localeCompare(b.enc_dni),
            },
            {
              title: "Celular",
              key: "enc_celular",
              dataIndex: "enc_celular",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_celular.localeCompare(b.enc_celular),
            },
            {
              title: "Dirección",
              key: "enc_direccion",
              dataIndex: "enc_direccion",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_direccion.localeCompare(b.enc_direccion),
            },
            {
              title: "Localidad",
              key: "enc_localidad",
              dataIndex: "enc_localidad",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_localidad.localeCompare(b.enc_localidad),
            },
            {
              title: "Sector",
              key: "enc_sector",
              dataIndex: "enc_sector",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_sector.localeCompare(b.enc_sector),
            },
            {
              title: "Distrito (ubicación)",
              key: "enc_dis_id_ubicacion",
              dataIndex: "enc_dis_id_ubicacion",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_dis_id_ubicacion.dis_nombre.localeCompare(
                  b.enc_dis_id_ubicacion.dis_nombre
                ),
              render: (
                _value: Distrito,
                _row: EncuestaProductores,
                _index: number
              ) => {
                return _value.dis_nombre;
              },
            },
            {
              title: "Altitud",
              key: "enc_altitud",
              dataIndex: "enc_altitud",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_altitud - b.enc_altitud,
            },
            {
              title: "Área",
              key: "enc_area",
              dataIndex: "enc_area",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_area - b.enc_area,
            },
            {
              title: "Estado físico legal",
              key: "enc_cag_efl_id",
              dataIndex: "enc_cag_efl_id",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_cag_efl_id.efl_descripcion.localeCompare(
                  b.enc_cag_efl_id.efl_descripcion
                ),
              render: (
                _value: EstadoFisicoLegal,
                _row: EncuestaProductores,
                _index: number
              ) => {
                return _value.efl_descripcion;
              },
            },
            {
              title: "Tiene título de propiedad",
              key: "enc_tiene_titulo_propiedad",
              dataIndex: "enc_tiene_titulo_propiedad",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_tiene_titulo_propiedad.localeCompare(
                  b.enc_tiene_titulo_propiedad
                ),
            },
            {
              title: "Otro documento",
              key: "enc_otro_documento",
              dataIndex: "enc_otro_documento",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_otro_documento.localeCompare(b.enc_otro_documento),
            },
            {
              title: "Cultivo principal",
              key: "enc_cag_cul_id_principal",
              dataIndex: "enc_cag_cul_id_principal",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_cag_cul_id_principal.cul_descripcion.localeCompare(
                  b.enc_cag_cul_id_principal.cul_descripcion
                ),
              render: (
                _value: Cultivo,
                _row: EncuestaProductores,
                _index: number
              ) => {
                return _value.cul_descripcion;
              },
            },
            {
              title: "Cultivos en campo",
              key: "enc_cultivos_en_campo",
              dataIndex: "enc_cultivos_en_campo",
              render: (
                _value: Cultivo[],
                _row: EncuestaProductores,
                _index: number
              ) => {
                return _value && _value.length > 0
                  ? _value.map((x) => x.cul_descripcion).join(", ")
                  : "";
              },
            },
            {
              title: "Tipo de producción",
              key: "enc_tipo_produccion",
              dataIndex: "enc_tipo_produccion",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_tipo_produccion.localeCompare(b.enc_tipo_produccion),
            },
            {
              title: "Nivel de tecnología",
              key: "enc_nivel_tecnologia",
              dataIndex: "enc_nivel_tecnologia",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_nivel_tecnologia.localeCompare(b.enc_nivel_tecnologia),
            },
            {
              title: "Sistema de riego",
              key: "enc_sistema_riego",
              dataIndex: "enc_sistema_riego",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_sistema_riego.localeCompare(b.enc_sistema_riego),
            },
            {
              title: "Área total en producción",
              key: "enc_area_total_produccion",
              dataIndex: "enc_area_total_produccion",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_area_total_produccion - b.enc_area_total_produccion,
            },
            {
              title: "Área sembrada",
              key: "enc_area_sembrada",
              dataIndex: "enc_area_sembrada",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_area_sembrada - b.enc_area_sembrada,
            },
            {
              title: "Área cosechada",
              key: "enc_area_cosechada",
              dataIndex: "enc_area_cosechada",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_area_cosechada - b.enc_area_cosechada,
            },
            {
              title: "Rendimiento",
              key: "enc_rendimiento",
              dataIndex: "enc_rendimiento",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_rendimiento.localeCompare(b.enc_rendimiento),
            },
            {
              title: "Precio de venta",
              key: "enc_precio_venta",
              dataIndex: "enc_precio_venta",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_precio_venta - b.enc_precio_venta,
            },
            {
              title: "Maneja costos de producción",
              key: "enc_maneja_costos_produccion",
              dataIndex: "enc_maneja_costos_produccion",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_maneja_costos_produccion.localeCompare(
                  b.enc_maneja_costos_produccion
                ),
            },
            {
              title: "Costo de producción x ha",
              key: "enc_costo_produccion_ha",
              dataIndex: "enc_costo_produccion_ha",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_costo_produccion_ha - b.enc_costo_produccion_ha,
            },
            {
              title: "¿A quién comercializa?",
              key: "enc_cliente_comercializacion",
              dataIndex: "enc_cliente_comercializacion",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_cliente_comercializacion.cli_descripcion.localeCompare(
                  b.enc_cliente_comercializacion.cli_descripcion
                ),
              render: (
                _value: Cliente,
                _row: EncuestaProductores,
                _index: number
              ) => {
                return _value.cli_descripcion;
              },
            },
            {
              title: "Precio de comercialización",
              key: "enc_costo_produccion_ha",
              dataIndex: "enc_costo_produccion_ha",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_costo_produccion_ha - b.enc_costo_produccion_ha,
            },
            {
              title: "Organización",
              key: "enc_organizacion",
              dataIndex: "enc_organizacion",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_organizacion.localeCompare(b.enc_organizacion),
              render: (
                _value: string,
                _row: EncuestaProductores,
                _index: number
              ) => {
                return _row.enc_pertenece_organizacion === "SI"
                  ? _value
                  : "---";
              },
            },
            {
              title: "Otra organización",
              key: "enc_otro_organizacion",
              dataIndex: "enc_otro_organizacion",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_otro_organizacion.localeCompare(b.enc_otro_organizacion),
            },
            {
              title: "Denominación",
              key: "enc_denominacion",
              dataIndex: "enc_denominacion",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_denominacion.localeCompare(b.enc_denominacion),
            },
            {
              title: "¿Lo comercializa con su organización?",
              key: "enc_comercializa_organizacion",
              dataIndex: "enc_comercializa_organizacion",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_comercializa_organizacion.localeCompare(
                  b.enc_comercializa_organizacion
                ),
            },
            {
              title: "¿Recibió financiamiento?",
              key: "enc_recibio_financiamiento",
              dataIndex: "enc_recibio_financiamiento",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_recibio_financiamiento.localeCompare(
                  b.enc_recibio_financiamiento
                ),
            },
            {
              title: "Comentarios",
              key: "enc_comentario_financiamiento",
              dataIndex: "enc_comentario_financiamiento",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_comentario_financiamiento.localeCompare(
                  b.enc_comentario_financiamiento
                ),
            },
            {
              title: "¿Fue beneficiario?",
              key: "enc_fue_beneficiario",
              dataIndex: "enc_fue_beneficiario",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_fue_beneficiario.localeCompare(b.enc_fue_beneficiario),
            },
            {
              title: "Comentarios",
              key: "enc_comentario_beneficiario",
              dataIndex: "enc_comentario_beneficiario",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_comentario_beneficiario.localeCompare(
                  b.enc_comentario_beneficiario
                ),
            },
            {
              title: "Fecha de visita",
              key: "enc_fecha_visita",
              dataIndex: "enc_fecha_visita",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                moment(a.enc_fecha_visita, FORMATO_FECHA).unix() -
                moment(b.enc_fecha_visita, FORMATO_FECHA).unix(),
            },
            {
              title: "Sincronizado",
              key: "enc_sync_up_date",
              dataIndex: "enc_sync_up_date",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                moment(a.enc_sync_up_date, FORMATO_FECHA).unix() -
                moment(b.enc_sync_up_date, FORMATO_FECHA).unix(),
            },
            {
              title: "Usuario",
              key: "enc_usu_id",
              dataIndex: "enc_usu_id",
              sorter: (a: EncuestaProductores, b: EncuestaProductores) =>
                a.enc_usu_id.usu_nombres.localeCompare(
                  b.enc_usu_id.usu_nombres
                ),
              render: (
                _value: Usuario,
                _row: EncuestaProductores,
                _index: number
              ) => {
                return _value.usu_nombres;
              },
            },
          ]}
          dataSource={_encuestas}
        />

        <Pagination
          current={page}
          disabled={_encuestas.length === 0}
          pageSize={pageSize}
          showSizeChanger
          size="small"
          total={totalItems}
          onChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      </Space>

      {/* Modal de mapa */}
      {_encuesta && (
        <Modal
          key={`location_${_encuesta.enc_id}`}
          visible={_visible}
          onCancel={() => {
            _setVisible(false);
          }}
          footer={[]}
          centered
        >
          <MapaComponent
            lat={_encuesta.enc_latitud}
            lng={_encuesta.enc_longitud}
          />
        </Modal>
      )}

      {_contextHolder}
    </>
  );
}
