import BodyWidget from "../util/body.widget";
import styled from "styled-components";
import TitleWidget from "../util/title.widget";

const NotFound = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default function NotFoundComponent() {
  return (
    <NotFound>
      <TitleWidget data="404" color="rgb(76 175 80)" />
      <BodyWidget data="No se econtr&oacute; el contenido solicitado" />
    </NotFound>
  );
}
