import { Spin } from "antd";
import styled from "styled-components";

const Load = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`;

export default function LoadComponent() {
  return (
    <Load>
      <Spin />
    </Load>
  );
}
