import { CausaReconversion } from "./causa_reconversion";
import { Cliente } from "./cliente";
import { Coloracion } from "./coloracion";
import { ControlCultural } from "./control_cultural";
import { ControlQuimico } from "./control_quimico";
import { Cultivo } from "./cultivo";
import { Distrito } from "./distrito";
import { EstadoCivil } from "./estado_civil";
import { EstadoFenologico } from "./estado_fenologico";
import { EstadoFisicoLegal } from "./estado_fisico_legal";
import { GradoInstruccion } from "./grado_instruccion";
import { Institucion } from "./institucion";
import { Maderable } from "./maderable";
import { Pendiente } from "./pendiente";
import { Plaga } from "./plaga";
import { Poda } from "./poda";
import { Producto } from "./producto";
import { Rendimiento } from "./rendimiento";
import { Riego } from "./riego";
import { SistemaSiembra } from "./sistema_siembra";
import { TipoAbonamiento } from "./tipo_abonamiento";
import { TipoAbono } from "./tipo_abono";
import { TipoFuenteAgua } from "./tipo_fuente_agua";
import { TipoVenta } from "./tipo_venta";
import { TipoVia } from "./tipo_via";
import { Transporte } from "./transporte";
import { Usuario } from "./usuario";
import { Variedad } from "./variedad";

export interface CensoAgrarioDataI {
  cagnew_id: number;
  cagnew_id_ubicacion: Distrito;
  cagnew_localidad: string;
  cagnew_sector: string;
  cagnew_nombre: string;
  cagnew_telefono: string;
  cagnew_dni: string;
  cagnew_edad: string;
  cagnew_gi_id: GradoInstruccion;
  cagnew_ec_id: EstadoCivil;
  cagnew_natural_de: string;
  cagnew_dis_id_personal: Distrito;
  cagnew_efl_id: EstadoFisicoLegal;
  cagnew_area_total: number;
  cagnew_pen_id: Pendiente;
  cagnew_area_produccion: number;
  cagnew_area_conservada: number;
  cagnew_fuente_agua_cercana: string;
  cagnew_fa_id: TipoFuenteAgua;
  cagnew_antecedentes: string;
  cagnew_cre_id: CausaReconversion;
  cagnew_otros_cultivos: string;
  cagnew_cul_principal_id: Cultivo;
  cagnew_ha_crecimiento: number;
  cagnew_fecha_crecimiento: string;
  cagnew_edad_crecimiento: number;
  cagnew_ha_produccion: number;
  cagnew_fecha_produccion: string;
  cagnew_edad_produccion: number;
  cagnew_id_rendimiento: Rendimiento;
  cagnew_cam1_rendimiento: number;
  cagnew_cam2_rendimiento: number;
  cagnew_variedad: string;
  cagnew_variedad_id: Variedad;
  cagnew_ef_id: EstadoFenologico;
  cagnew_id_coloracion: Coloracion;
  cagnew_altura: number;
  cagnew_fertiliza: string;
  cagnew_fecha_cosecha: string;
  cagnew_diametro: number;
  cagnew_poda_id: Poda;
  cagnew_distanciamiento: string;
  cagnew_sis_id: SistemaSiembra;
  cagnew_sis_otros: string;
  cagnew_cul_id_asociado: Cultivo;
  cagnew_mad_id: Maderable;
  cagnew_aplica_abonamiento: string;
  cagnew_tab_id: TipoAbonamiento;
  cagnew_ta_id: TipoAbono;
  cagnew_aplica_riego: string;
  cagnew_rie_id: Riego;
  cagnew_presencia_plagas: string;
  cagnew_plagas: Plaga[];
  cagnew_porcentaje_plagas: number;
  cagnew_recibe_asistencia_tecnica: string;
  cagnew_ins_id_asistencia: Institucion;
  cagnew_aplica_cc: string;
  cagnew_cc_id: ControlCultural;
  cagnew_aplica_cq: string;
  cagnew_cq_id: ControlQuimico[];
  cagnew_pertenece_organizacion: string;
  cagnew_organizacion: string;
  cagnew_maquinaria: string;
  cagnew_costo_maquinaria: number;
  cagnew_precio_jornal: number;
  cagnew_cli_id: Cliente;
  cagnew_tve_id: TipoVenta;
  cagnew_pro_id: Producto;
  cagnew_tvi_id: TipoVia;
  cagnew_tra_id: Transporte;
  cagnew_financiamiento: string;
  cagnew_ins_id_financiamiento: Institucion;
  cagnew_observaciones: string;
  cagnew_foto: string;
  cagnew_altitud: number;
  cagnew_latitud: number;
  cagnew_longitud: number;
  cagnew_fecha_visita: string;
  cagnew_sync_up_date: string;
  cagnew_usu_id: Usuario;
  cagnew_estado: number;
  cagnew_cultivos_en_campo: Cultivo[];
}

export class CensoAgrario implements CensoAgrarioDataI {
  cagnew_id: number;
  cagnew_id_ubicacion: Distrito;
  cagnew_localidad: string;
  cagnew_sector: string;
  cagnew_nombre: string;
  cagnew_telefono: string;
  cagnew_dni: string;
  cagnew_edad: string;
  cagnew_gi_id: GradoInstruccion;
  cagnew_ec_id: EstadoCivil;
  cagnew_natural_de: string;
  cagnew_dis_id_personal: Distrito;
  cagnew_efl_id: EstadoFisicoLegal;
  cagnew_area_total: number;
  cagnew_pen_id: Pendiente;
  cagnew_area_produccion: number;
  cagnew_area_conservada: number;
  cagnew_fuente_agua_cercana: string;
  cagnew_fa_id: TipoFuenteAgua;
  cagnew_antecedentes: string;
  cagnew_cre_id: CausaReconversion;
  cagnew_otros_cultivos: string;
  cagnew_cul_principal_id: Cultivo;
  cagnew_ha_crecimiento: number;
  cagnew_fecha_crecimiento: string;
  cagnew_edad_crecimiento: number;
  cagnew_ha_produccion: number;
  cagnew_fecha_produccion: string;
  cagnew_edad_produccion: number;
  cagnew_id_rendimiento: Rendimiento;
  cagnew_cam1_rendimiento: number;
  cagnew_cam2_rendimiento: number;
  cagnew_variedad: string;
  cagnew_variedad_id: Variedad;
  cagnew_ef_id: EstadoFenologico;
  cagnew_id_coloracion: Coloracion;
  cagnew_altura: number;
  cagnew_fertiliza: string;
  cagnew_fecha_cosecha: string;
  cagnew_diametro: number;
  cagnew_poda_id: Poda;
  cagnew_distanciamiento: string;
  cagnew_sis_id: SistemaSiembra;
  cagnew_sis_otros: string;
  cagnew_cul_id_asociado: Cultivo;
  cagnew_mad_id: Maderable;
  cagnew_aplica_abonamiento: string;
  cagnew_tab_id: TipoAbonamiento;
  cagnew_ta_id: TipoAbono;
  cagnew_aplica_riego: string;
  cagnew_rie_id: Riego;
  cagnew_presencia_plagas: string;
  cagnew_plagas: Plaga[];
  cagnew_porcentaje_plagas: number;
  cagnew_recibe_asistencia_tecnica: string;
  cagnew_ins_id_asistencia: Institucion;
  cagnew_aplica_cc: string;
  cagnew_cc_id: ControlCultural;
  cagnew_aplica_cq: string;
  cagnew_cq_id: ControlQuimico[];
  cagnew_pertenece_organizacion: string;
  cagnew_organizacion: string;
  cagnew_maquinaria: string;
  cagnew_costo_maquinaria: number;
  cagnew_precio_jornal: number;
  cagnew_cli_id: Cliente;
  cagnew_tve_id: TipoVenta;
  cagnew_pro_id: Producto;
  cagnew_tvi_id: TipoVia;
  cagnew_tra_id: Transporte;
  cagnew_financiamiento: string;
  cagnew_ins_id_financiamiento: Institucion;
  cagnew_observaciones: string;
  cagnew_foto: string;
  cagnew_altitud: number;
  cagnew_latitud: number;
  cagnew_longitud: number;
  cagnew_fecha_visita: string;
  cagnew_sync_up_date: string;
  cagnew_usu_id: Usuario;
  cagnew_estado: number;
  cagnew_cultivos_en_campo: Cultivo[];

  constructor(data: CensoAgrarioDataI) {
    this.cagnew_id = data.cagnew_id;
    this.cagnew_id_ubicacion = data.cagnew_id_ubicacion;
    this.cagnew_localidad = data.cagnew_localidad;
    this.cagnew_sector = data.cagnew_sector;
    this.cagnew_nombre = data.cagnew_nombre;
    this.cagnew_telefono = data.cagnew_telefono;
    this.cagnew_dni = data.cagnew_dni;
    this.cagnew_edad = data.cagnew_edad;
    this.cagnew_gi_id = data.cagnew_gi_id;
    this.cagnew_ec_id = data.cagnew_ec_id;
    this.cagnew_natural_de = data.cagnew_natural_de;
    this.cagnew_dis_id_personal = data.cagnew_dis_id_personal;
    this.cagnew_efl_id = data.cagnew_efl_id;
    this.cagnew_area_total = data.cagnew_area_total;
    this.cagnew_pen_id = data.cagnew_pen_id;
    this.cagnew_area_produccion = data.cagnew_area_produccion;
    this.cagnew_area_conservada = data.cagnew_area_conservada;
    this.cagnew_fuente_agua_cercana = data.cagnew_fuente_agua_cercana;
    this.cagnew_fa_id = data.cagnew_fa_id;
    this.cagnew_antecedentes = data.cagnew_antecedentes;
    this.cagnew_cre_id = data.cagnew_cre_id;
    this.cagnew_otros_cultivos = data.cagnew_otros_cultivos;
    this.cagnew_cul_principal_id = data.cagnew_cul_principal_id;
    this.cagnew_ha_crecimiento = data.cagnew_ha_crecimiento;
    this.cagnew_fecha_crecimiento = data.cagnew_fecha_crecimiento;
    this.cagnew_edad_crecimiento = data.cagnew_edad_crecimiento;
    this.cagnew_ha_produccion = data.cagnew_ha_produccion;
    this.cagnew_fecha_produccion = data.cagnew_fecha_produccion;
    this.cagnew_edad_produccion = data.cagnew_edad_produccion;
    this.cagnew_id_rendimiento = data.cagnew_id_rendimiento;
    this.cagnew_cam1_rendimiento = data.cagnew_cam1_rendimiento;
    this.cagnew_cam2_rendimiento = data.cagnew_cam2_rendimiento;
    this.cagnew_variedad = data.cagnew_variedad;
    this.cagnew_variedad_id = data.cagnew_variedad_id;
    this.cagnew_ef_id = data.cagnew_ef_id;
    this.cagnew_id_coloracion = data.cagnew_id_coloracion;
    this.cagnew_altura = data.cagnew_altura;
    this.cagnew_fertiliza = data.cagnew_fertiliza;
    this.cagnew_fecha_cosecha = data.cagnew_fecha_cosecha;
    this.cagnew_diametro = data.cagnew_diametro;
    this.cagnew_poda_id = data.cagnew_poda_id;
    this.cagnew_distanciamiento = data.cagnew_distanciamiento;
    this.cagnew_sis_id = data.cagnew_sis_id;
    this.cagnew_sis_otros = data.cagnew_sis_otros;
    this.cagnew_cul_id_asociado = data.cagnew_cul_id_asociado;
    this.cagnew_mad_id = data.cagnew_mad_id;
    this.cagnew_aplica_abonamiento = data.cagnew_aplica_abonamiento;
    this.cagnew_tab_id = data.cagnew_tab_id;
    this.cagnew_ta_id = data.cagnew_ta_id;
    this.cagnew_aplica_riego = data.cagnew_aplica_riego;
    this.cagnew_rie_id = data.cagnew_rie_id;
    this.cagnew_presencia_plagas = data.cagnew_presencia_plagas;
    this.cagnew_plagas = data.cagnew_plagas;
    this.cagnew_porcentaje_plagas = data.cagnew_porcentaje_plagas;
    this.cagnew_recibe_asistencia_tecnica = data.cagnew_recibe_asistencia_tecnica;
    this.cagnew_ins_id_asistencia = data.cagnew_ins_id_asistencia;
    this.cagnew_aplica_cc = data.cagnew_aplica_cc;
    this.cagnew_cc_id = data.cagnew_cc_id;
    this.cagnew_aplica_cq = data.cagnew_aplica_cq;
    this.cagnew_cq_id = data.cagnew_cq_id;
    this.cagnew_pertenece_organizacion = data.cagnew_pertenece_organizacion;
    this.cagnew_organizacion = data.cagnew_organizacion;
    this.cagnew_maquinaria = data.cagnew_maquinaria;
    this.cagnew_costo_maquinaria = data.cagnew_costo_maquinaria;
    this.cagnew_precio_jornal = data.cagnew_precio_jornal;
    this.cagnew_cli_id = data.cagnew_cli_id;
    this.cagnew_tve_id = data.cagnew_tve_id;
    this.cagnew_pro_id = data.cagnew_pro_id;
    this.cagnew_tvi_id = data.cagnew_tvi_id;
    this.cagnew_tra_id = data.cagnew_tra_id;
    this.cagnew_financiamiento = data.cagnew_financiamiento;
    this.cagnew_ins_id_financiamiento = data.cagnew_ins_id_financiamiento;
    this.cagnew_observaciones = data.cagnew_observaciones;
    this.cagnew_foto = data.cagnew_foto;
    this.cagnew_altitud = data.cagnew_altitud;
    this.cagnew_latitud = data.cagnew_latitud;
    this.cagnew_longitud = data.cagnew_longitud;
    this.cagnew_fecha_visita = data.cagnew_fecha_visita;
    this.cagnew_sync_up_date = data.cagnew_sync_up_date;
    this.cagnew_usu_id = data.cagnew_usu_id;
    this.cagnew_estado = data.cagnew_estado;
    this.cagnew_cultivos_en_campo = data.cagnew_cultivos_en_campo;
  }

  _toMap() {
    return {
      cagnew_id: this.cagnew_id,
      cagnew_id_ubicacion: this.cagnew_id_ubicacion,
      cagnew_localidad: this.cagnew_localidad,
      cagnew_sector: this.cagnew_sector,
      cagnew_nombre: this.cagnew_nombre,
      cagnew_telefono: this.cagnew_telefono,
      cagnew_dni: this.cagnew_dni,
      cagnew_edad: this.cagnew_edad,
      cagnew_gi_id: this.cagnew_gi_id,
      cagnew_ec_id: this.cagnew_ec_id,
      cagnew_natural_de: this.cagnew_natural_de,
      cagnew_dis_id_personal: this.cagnew_dis_id_personal,
      cagnew_efl_id: this.cagnew_efl_id,
      cagnew_area_total: this.cagnew_area_total,
      cagnew_pen_id: this.cagnew_pen_id,
      cagnew_area_produccion: this.cagnew_area_produccion,
      cagnew_area_conservada: this.cagnew_area_conservada,
      cagnew_fuente_agua_cercana: this.cagnew_fuente_agua_cercana,
      cagnew_fa_id: this.cagnew_fa_id,
      cagnew_antecedentes: this.cagnew_antecedentes,
      cagnew_cre_id: this.cagnew_cre_id,
      cagnew_otros_cultivos: this.cagnew_otros_cultivos,
      cagnew_cul_principal_id: this.cagnew_cul_principal_id,
      cagnew_ha_crecimiento: this.cagnew_ha_crecimiento,
      cagnew_fecha_crecimiento: this.cagnew_fecha_crecimiento,
      cagnew_edad_crecimiento: this.cagnew_edad_crecimiento,
      cagnew_ha_produccion: this.cagnew_ha_produccion,
      cagnew_fecha_produccion: this.cagnew_fecha_produccion,
      cagnew_edad_produccion: this.cagnew_edad_produccion,
      cagnew_id_rendimiento: this.cagnew_id_rendimiento,
      cagnew_cam1_rendimiento: this.cagnew_cam1_rendimiento,
      cagnew_cam2_rendimiento: this.cagnew_cam2_rendimiento,
      cagnew_variedad: this.cagnew_variedad,
      cagnew_variedad_id: this.cagnew_variedad_id,
      cagnew_ef_id: this.cagnew_ef_id,
      cagnew_id_coloracion: this.cagnew_id_coloracion,
      cagnew_altura: this.cagnew_altura,
      cagnew_fertiliza: this.cagnew_fertiliza,
      cagnew_fecha_cosecha: this.cagnew_fecha_cosecha,
      cagnew_diametro: this.cagnew_diametro,
      cagnew_poda_id: this.cagnew_poda_id,
      cagnew_distanciamiento: this.cagnew_distanciamiento,
      cagnew_sis_id: this.cagnew_sis_id,
      cagnew_sis_otros: this.cagnew_sis_otros,
      cagnew_cul_id_asociado: this.cagnew_cul_id_asociado,
      cagnew_mad_id: this.cagnew_mad_id,
      cagnew_aplica_abonamiento: this.cagnew_aplica_abonamiento,
      cagnew_tab_id: this.cagnew_tab_id,
      cagnew_ta_id: this.cagnew_ta_id,
      cagnew_aplica_riego: this.cagnew_aplica_riego,
      cagnew_rie_id: this.cagnew_rie_id,
      cagnew_presencia_plagas: this.cagnew_presencia_plagas,
      cagnew_plagas: this.cagnew_plagas,
      cagnew_porcentaje_plagas: this.cagnew_porcentaje_plagas,
      cagnew_recibe_asistencia_tecnica: this.cagnew_recibe_asistencia_tecnica,
      cagnew_ins_id_asistencia: this.cagnew_ins_id_asistencia,
      cagnew_aplica_cc: this.cagnew_aplica_cc,
      cagnew_cc_id: this.cagnew_cc_id,
      cagnew_aplica_cq: this.cagnew_aplica_cq,
      cagnew_cq_id: this.cagnew_cq_id,
      cagnew_pertenece_organizacion: this.cagnew_pertenece_organizacion,
      cagnew_organizacion: this.cagnew_organizacion,
      cagnew_maquinaria: this.cagnew_maquinaria,
      cagnew_costo_maquinaria: this.cagnew_costo_maquinaria,
      cagnew_precio_jornal: this.cagnew_precio_jornal,
      cagnew_cli_id: this.cagnew_cli_id,
      cagnew_tve_id: this.cagnew_tve_id,
      cagnew_pro_id: this.cagnew_pro_id,
      cagnew_tvi_id: this.cagnew_tvi_id,
      cagnew_tra_id: this.cagnew_tra_id,
      cagnew_financiamiento: this.cagnew_financiamiento,
      cagnew_ins_id_financiamiento: this.cagnew_ins_id_financiamiento,
      cagnew_observaciones: this.cagnew_observaciones,
      cagnew_foto: this.cagnew_foto,
      cagnew_altitud: this.cagnew_altitud,
      cagnew_latitud: this.cagnew_latitud,
      cagnew_longitud: this.cagnew_longitud,
      cagnew_fecha_visita: this.cagnew_fecha_visita,
      cagnew_sync_up_date: this.cagnew_sync_up_date,
      cagnew_usu_id: this.cagnew_usu_id,
      cagnew_estado: this.cagnew_estado,
      cagnew_cultivos_en_campo: this.cagnew_cultivos_en_campo,
    };
  }

  _fromMap(data: CensoAgrarioDataI) {
    this.cagnew_id = data.cagnew_id;
    this.cagnew_id_ubicacion = data.cagnew_id_ubicacion;
    this.cagnew_localidad = data.cagnew_localidad;
    this.cagnew_sector = data.cagnew_sector;
    this.cagnew_nombre = data.cagnew_nombre;
    this.cagnew_telefono = data.cagnew_telefono;
    this.cagnew_dni = data.cagnew_dni;
    this.cagnew_edad = data.cagnew_edad;
    this.cagnew_gi_id = data.cagnew_gi_id;
    this.cagnew_ec_id = data.cagnew_ec_id;
    this.cagnew_natural_de = data.cagnew_natural_de;
    this.cagnew_dis_id_personal = data.cagnew_dis_id_personal;
    this.cagnew_efl_id = data.cagnew_efl_id;
    this.cagnew_area_total = data.cagnew_area_total;
    this.cagnew_pen_id = data.cagnew_pen_id;
    this.cagnew_area_produccion = data.cagnew_area_produccion;
    this.cagnew_area_conservada = data.cagnew_area_conservada;
    this.cagnew_fuente_agua_cercana = data.cagnew_fuente_agua_cercana;
    this.cagnew_fa_id = data.cagnew_fa_id;
    this.cagnew_antecedentes = data.cagnew_antecedentes;
    this.cagnew_cre_id = data.cagnew_cre_id;
    this.cagnew_otros_cultivos = data.cagnew_otros_cultivos;
    this.cagnew_cul_principal_id = data.cagnew_cul_principal_id;
    this.cagnew_ha_crecimiento = data.cagnew_ha_crecimiento;
    this.cagnew_fecha_crecimiento = data.cagnew_fecha_crecimiento;
    this.cagnew_edad_crecimiento = data.cagnew_edad_crecimiento;
    this.cagnew_ha_produccion = data.cagnew_ha_produccion;
    this.cagnew_fecha_produccion = data.cagnew_fecha_produccion;
    this.cagnew_edad_produccion = data.cagnew_edad_produccion;
    this.cagnew_id_rendimiento = data.cagnew_id_rendimiento;
    this.cagnew_cam1_rendimiento = data.cagnew_cam1_rendimiento;
    this.cagnew_cam2_rendimiento = data.cagnew_cam2_rendimiento;
    this.cagnew_variedad = data.cagnew_variedad;
    this.cagnew_variedad_id = data.cagnew_variedad_id;
    this.cagnew_ef_id = data.cagnew_ef_id;
    this.cagnew_id_coloracion = data.cagnew_id_coloracion;
    this.cagnew_altura = data.cagnew_altura;
    this.cagnew_fertiliza = data.cagnew_fertiliza;
    this.cagnew_fecha_cosecha = data.cagnew_fecha_cosecha;
    this.cagnew_diametro = data.cagnew_diametro;
    this.cagnew_poda_id = data.cagnew_poda_id;
    this.cagnew_distanciamiento = data.cagnew_distanciamiento;
    this.cagnew_sis_id = data.cagnew_sis_id;
    this.cagnew_sis_otros = data.cagnew_sis_otros;
    this.cagnew_cul_id_asociado = data.cagnew_cul_id_asociado;
    this.cagnew_mad_id = data.cagnew_mad_id;
    this.cagnew_aplica_abonamiento = data.cagnew_aplica_abonamiento;
    this.cagnew_tab_id = data.cagnew_tab_id;
    this.cagnew_ta_id = data.cagnew_ta_id;
    this.cagnew_aplica_riego = data.cagnew_aplica_riego;
    this.cagnew_rie_id = data.cagnew_rie_id;
    this.cagnew_presencia_plagas = data.cagnew_presencia_plagas;
    this.cagnew_plagas = data.cagnew_plagas;
    this.cagnew_porcentaje_plagas = data.cagnew_porcentaje_plagas;
    this.cagnew_recibe_asistencia_tecnica =
      data.cagnew_recibe_asistencia_tecnica;
    this.cagnew_ins_id_asistencia = data.cagnew_ins_id_asistencia;
    this.cagnew_aplica_cc = data.cagnew_aplica_cc;
    this.cagnew_cc_id = data.cagnew_cc_id;
    this.cagnew_aplica_cq = data.cagnew_aplica_cq;
    this.cagnew_cq_id = data.cagnew_cq_id;
    this.cagnew_pertenece_organizacion = data.cagnew_pertenece_organizacion;
    this.cagnew_organizacion = data.cagnew_organizacion;
    this.cagnew_maquinaria = data.cagnew_maquinaria;
    this.cagnew_costo_maquinaria = data.cagnew_costo_maquinaria;
    this.cagnew_precio_jornal = data.cagnew_precio_jornal;
    this.cagnew_cli_id = data.cagnew_cli_id;
    this.cagnew_tve_id = data.cagnew_tve_id;
    this.cagnew_pro_id = data.cagnew_pro_id;
    this.cagnew_tvi_id = data.cagnew_tvi_id;
    this.cagnew_tra_id = data.cagnew_tra_id;
    this.cagnew_financiamiento = data.cagnew_financiamiento;
    this.cagnew_ins_id_financiamiento = data.cagnew_ins_id_financiamiento;
    this.cagnew_observaciones = data.cagnew_observaciones;
    this.cagnew_foto = data.cagnew_foto;
    this.cagnew_altitud = data.cagnew_altitud;
    this.cagnew_latitud = data.cagnew_latitud;
    this.cagnew_longitud = data.cagnew_longitud;
    this.cagnew_fecha_visita = data.cagnew_fecha_visita;
    this.cagnew_sync_up_date = data.cagnew_sync_up_date;
    this.cagnew_usu_id = data.cagnew_usu_id;
    this.cagnew_estado = data.cagnew_estado;
    this.cagnew_cultivos_en_campo = data.cagnew_cultivos_en_campo;
  }
}
