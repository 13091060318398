export interface APIDataI {
  success?: boolean;
  code?: number;
  message?: string;
  data?: any;
  token?: string;
}

export class APIResponse implements APIDataI {
  success?: boolean;
  code?: number;
  message?: string;
  data?: any;
  token?: string;

  constructor(data: APIDataI) {
    this.success = data.success;
    this.code = data.code;
    this.message = data.message;
    this.data = data.data;
    this.token = data.token;
  }
}
