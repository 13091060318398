export interface DepartamentoDataI {
  dep_id: number;
  dep_nombre: string;
}

export class Departamento implements DepartamentoDataI {
  dep_id: number;
  dep_nombre: string;

  constructor(data: DepartamentoDataI) {
    this.dep_id = data.dep_id;
    this.dep_nombre = data.dep_nombre;
  }

  _toMap() {
    return {
      dep_id: this.dep_id,
      dep_nombre: this.dep_nombre,
    };
  }

  _fromMap(data: DepartamentoDataI) {
    this.dep_id = data.dep_id;
    this.dep_nombre = data.dep_nombre;
  }
}
