export interface DataGraficoDataI {
  latitud: number;
  longitud: number;
  rendimiento: number;
  tipo: string;
  podas: string;
  abonamientos: string;
  plagas: string;
  controlQuimico: string;
  asistenciaTecnica: string;
  areaTotal: number;
  areaProduccion: number;
  estadoLegal: string;
  antecedentes: string;
}
export class DataGrafico implements DataGraficoDataI {
  latitud: number;
  longitud: number;
  rendimiento: number;
  tipo: string;
  podas: string;
  abonamientos: string;
  plagas: string;
  controlQuimico: string;
  asistenciaTecnica: string;
  areaTotal: number;
  areaProduccion: number;
  estadoLegal: string;
  antecedentes: string;

  constructor(data: DataGraficoDataI) {
    this.latitud = data.latitud;
    this.longitud = data.longitud;
    this.rendimiento = data.rendimiento;
    this.tipo = data.tipo;
    this.podas = data.podas;
    this.abonamientos = data.abonamientos;
    this.plagas = data.plagas;
    this.controlQuimico = data.controlQuimico;
    this.asistenciaTecnica = data.asistenciaTecnica;
    this.areaTotal = Number(data.areaTotal);
    this.areaProduccion = data.areaProduccion;
    this.estadoLegal = data.estadoLegal;
    this.antecedentes = data.antecedentes;
  }
}
