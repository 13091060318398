import CacaoPage from "./cacao.page";
import CensoAgrarioPage from "./censo_agrario.page";
import ColoracionPage from "./coloracion.page";
import CultivoPage from "./cultivo.page";
import DashboardPage from "./dashboard.page";
import EncuestaProductoresPage from "./encuesta_productores.page";
import ErrorComponent from "../components/error.component";
import EstadoFenologicoPage from "./estado_fenologico.page";
import FichaCampoPage from "./ficha_campo.page";
import FichaTecnicaAgronomicaPage from "./fichatecnicaagronomica.page";
import HomePage from "./home.page";
import LoadComponent from "../components/load.component";
import LoginPage from "./login.page";
import NotFoundComponent from "../components/notfound.component";
import PendientePage from "./pendiente.page";
import PodaPage from "./poda.page";
import RiegoPage from "./riego.page";
import SistemaSiembraPage from "./sistema_siembra.page";
import UsuarioPage from "./usuario.page";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PerfilContext } from "../../context/perfil-context";
import { useEffect, useMemo, useState } from "react";

export default function AppPage() {
  const [_mounted, _setMounted] = useState<any>(null);
  const [_perfil, _setPerfil] = useState<number>(0);

  const value = useMemo(
    () => ({
      _perfil,
      _setPerfil,
    }),
    [_perfil]
  );

  useEffect(() => {
    _setMounted(true);
    return () => {
      _setMounted(false);
    };
  }, [_perfil]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }
  return (
    <>
      {/* <BrowserRouter basename="/agroat/dashboard"> */}
      <BrowserRouter>
        <PerfilContext.Provider value={value}>
          <Routes>
            <Route path="/*" element={<DashboardPage />}>
              <Route index element={<HomePage />} />
              <Route path="home" element={<HomePage />} />
              <Route path="censo_agrario" element={<CensoAgrarioPage />} />
              <Route path="cacao" element={<CacaoPage />} />
              <Route path="fta" element={<FichaTecnicaAgronomicaPage />} />
              <Route
                path="encuesta_productores"
                element={<EncuestaProductoresPage />}
              />
              <Route path="ficha_campo" element={<FichaCampoPage />} />
              {_perfil === 1 && (
                <>
                  <Route path="coloration" element={<ColoracionPage />} />
                  <Route path="crops" element={<CultivoPage />} />
                  <Route
                    path="phenological_state"
                    element={<EstadoFenologicoPage />}
                  />
                  <Route path="slope" element={<PendientePage />} />
                  <Route path="pruning" element={<PodaPage />} />
                  <Route path="irrigation" element={<RiegoPage />} />
                  <Route
                    path="planting_system"
                    element={<SistemaSiembraPage />}
                  />
                  <Route path="users" element={<UsuarioPage />} />
                </>
              )}
              <Route path="*" element={<NotFoundComponent />} />
            </Route>
            <Route path="auth" element={<LoginPage />} />
            <Route path="*" element={<NotFoundComponent />} />
          </Routes>
        </PerfilContext.Provider>
      </BrowserRouter>
    </>
  );
}
