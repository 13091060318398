import { ReactNode } from "react";
import styled from "styled-components";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
`;

const HeaderWidget = ({ children }: { children: ReactNode }) => {
  return <Header>{children}</Header>;
};

export default HeaderWidget;
