import { APIException } from "../model/api_exception";
import { APIResponse } from "../model/api_response";
import { AuthService } from "../services/auth.service";
import { AxiosResponse } from "axios";
import { Cultivo } from "../model/cultivo";
import { CultivoDataI } from "../model/cultivo";
import axios from "axios";
import Global from "../global/global";

export class APICultivo {
  private _auth: AuthService = new AuthService();

  async _listarCultivos(): Promise<Cultivo[]> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarCultivos`,
      {
        token: await this._auth._getToken(),
      }
    );
    if (result.status === 200) {
      let listaCultivo: Cultivo[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: CultivoDataI) => {
          listaCultivo.push(new Cultivo(element));
        });
      }
      return listaCultivo;
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _registrarCultivo(_cultivo: Cultivo): Promise<APIResponse> {
    let data: any = _cultivo._toMap();
    data.token = await this._auth._getToken();

    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/registrarCultivo`,
      data
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _editarCultivo(_cultivo: Cultivo): Promise<APIResponse> {
    let data: any = _cultivo._toMap();
    data.token = await this._auth._getToken();
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/editarCultivo`,
      data
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _eliminarCultivo(_cultivo: Cultivo): Promise<APIResponse> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/eliminarCultivo`,
      {
        token: await this._auth._getToken(),
        codigo: _cultivo.cul_id,
        estado: 0,
      }
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }
}
