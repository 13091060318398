import { APIException } from "../model/api_exception";
import { AuthService } from "../services/auth.service";
import { AxiosResponse } from "axios";
import axios from "axios";
import Global from "../global/global";
import { Usuario, UsuarioDataI } from "../model/usuario";
import { APIResponse } from "../model/api_response";
import { Perfil, PerfilDataI } from "../model/perfil";

export class APIUsuario {
  private _auth: AuthService = new AuthService();

  async _listarPerfiles(): Promise<Perfil[]> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarPerfiles`,
      {
        token: await this._auth._getToken(),
      }
    );
    if (result.status === 200) {
      let listaPerfil: Perfil[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: PerfilDataI) => {
          listaPerfil.push(new Perfil(element));
        });
      }
      return listaPerfil;
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _listarUsuarios(): Promise<Usuario[]> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarUsuarios`,
      {
        token: await this._auth._getToken(),
      }
    );
    if (result.status === 200) {
      let listaUsuario: Usuario[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: UsuarioDataI) => {
          listaUsuario.push(new Usuario(element));
        });
      }
      return listaUsuario;
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _registrarUsuario(_usuario: Usuario): Promise<APIResponse> {
    let data: any = _usuario._toMap();
    data.token = await this._auth._getToken();
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/registrarUsuario`,
      data
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _editarUsuario(_usuario: Usuario): Promise<APIResponse> {
    let data: any = _usuario._toMap();
    data.token = await this._auth._getToken();
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/editarUsuario`,
      data
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _actualizarEstadoUsuario(
    _usuario: Usuario,
    _estado: number
  ): Promise<APIResponse> {
    let data: any = _usuario._toMap();
    data.token = await this._auth._getToken();
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/actualizarEstadoUsuario`,
      {
        token: await this._auth._getToken(),
        codigo: _usuario.usu_id,
        estado: _estado,
      }
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }
}
