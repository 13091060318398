import styled from "styled-components";

const Body = styled.p`
  text-align: justify;
  color: rgb(0 0 0);
  margin: unset;
`;

const BodyWidget = ({ data }: { data: string }) => {
  return <Body>{data}</Body>;
};

export default BodyWidget;
