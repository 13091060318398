import { Provincia } from "./provincia";

export interface DistritoDataI {
  dis_id: number;
  dis_nombre: string;
  dis_pro_id: Provincia;
}

export class Distrito implements DistritoDataI {
  dis_id: number;
  dis_nombre: string;
  dis_pro_id: Provincia;

  constructor(data: DistritoDataI) {
    this.dis_id = data.dis_id;
    this.dis_nombre = data.dis_nombre;
    this.dis_pro_id = data.dis_pro_id;
  }

  _toMap() {
    return {
      dis_id: this.dis_id,
      dis_nombre: this.dis_nombre,
      dis_pro_id: this.dis_pro_id,
    };
  }

  _fromMap(data: DistritoDataI) {
    this.dis_id = data.dis_id;
    this.dis_nombre = data.dis_nombre;
    this.dis_pro_id = data.dis_pro_id;
  }
}
