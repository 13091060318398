import styled from "styled-components";

const Activo = styled.div`
  background: rgb(76 175 80);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: ${(props) => props.theme.margin};
`;

const ActivoWidget = ({ margin }: { margin: string }) => {
  return <Activo theme={{ margin }} />;
};

export default ActivoWidget;
