import { Cacao } from "../../model/cacao";
import { Evaluacion } from "../../model/evaluacion";
import { Plantacion } from "../../model/plantacion";
import { Table } from "antd";
import { Usuario } from "../../model/usuario";
import type { TableProps } from "antd";

export default function EvaluacionesComponent({ cacao }: { cacao: Cacao }) {
  const columns: TableProps<Evaluacion>["columns"] = [
    {
      title: "Código",
      dataIndex: "eva_id",
      key: "eva_id",
    },
    {
      title: "Plantación",
      dataIndex: "eva_plan_id",
      key: "eva_plan_id",
      render: (_value: Plantacion) => _value.plan_descripcion,
    },
    {
      title: "Total mazorcas",
      dataIndex: "eva_total_mazorcas",
      key: "eva_total_mazorcas",
    },
    {
      title: "Total mazorcas",
      dataIndex: "eva_total_mazorcas",
      key: "eva_total_mazorcas",
    },
    {
      title: "Tiene escoba de bruja",
      dataIndex: "eva_tiene_escoba_de_bruja",
      key: "eva_tiene_escoba_de_bruja",
    },
    {
      title: "Tiene phytopthora",
      dataIndex: "eva_tiene_phytopthora",
      key: "eva_tiene_phytopthora",
    },
    {
      title: "Mazorcas infectadas con perforador",
      dataIndex: "eva_mazorcas_infectadas_perforador",
      key: "eva_mazorcas_infectadas_perforador",
    },
    {
      title: "%",
      dataIndex: "eva_porcentaje_infectadas_perforador",
      key: "eva_porcentaje_infectadas_perforador",
    },
    {
      title: "Mazorcas infectadas con monilia",
      dataIndex: "eva_mazorcas_infectadas_monilia",
      key: "eva_mazorcas_infectadas_monilia",
    },
    {
      title: "%",
      dataIndex: "eva_porcentaje_infectadas_monilia",
      key: "eva_porcentaje_infectadas_monilia",
    },
    {
      title: "Cojines florales",
      dataIndex: "eva_cojines_florales",
      key: "eva_cojines_florales",
    },
    {
      title: "Cojines florales cantidad",
      dataIndex: "eva_cojines_florales_cantidad",
      key: "eva_cojines_florales_cantidad",
    },
    {
      title: "Brotes terminales",
      dataIndex: "eva_brotes_terminales",
      key: "eva_brotes_terminales",
    },
    {
      title: "Brotes terminales cantidad",
      dataIndex: "eva_brotes_terminales_cantidad",
      key: "eva_brotes_terminales_cantidad",
    },
    {
      title: "Brotes laterales",
      dataIndex: "eva_brotes_laterales",
      key: "eva_brotes_laterales",
    },
    {
      title: "Brotes laterales cantidad",
      dataIndex: "eva_brotes_laterales_cantidad",
      key: "eva_brotes_laterales_cantidad",
    },
    {
      title: "Infectadas con escoba de bruja (%)",
      dataIndex: "eva_porcentaje_infectadas_bruja",
      key: "eva_porcentaje_infectadas_bruja",
    },
    {
      title: "Infectadas con escoba de bruja",
      dataIndex: "eva_mazorcas_infectadas_phytopthora",
      key: "eva_mazorcas_infectadas_phytopthora",
    },
    {
      title: "Infectadas con escoba de bruja (%)",
      dataIndex: "eva_porcentaje_infectadas_phytopthora",
      key: "eva_porcentaje_infectadas_phytopthora",
    },
    {
      title: "Otras plagas",
      dataIndex: "eva_otras_plagas",
      key: "eva_otras_plagas",
    },
    {
      title: "Observaciones",
      dataIndex: "eva_observaciones",
      key: "eva_observaciones",
    },
    {
      title: "Fecha de sincronización",
      dataIndex: "eva_sync_up_date",
      key: "eva_sync_up_date",
    },
    {
      title: "Fecha de creación",
      dataIndex: "eva_create_date",
      key: "eva_create_date",
    },
    {
      title: "Usuario",
      dataIndex: "eva_user_id",
      key: "eva_user_id",
      render: (_value: Usuario) => _value.usu_nombres,
    },
  ];

  return (
    <Table
      rowKey="eva_id"
      columns={columns}
      dataSource={cacao.cacao_evaluaciones}
      size="small"
      pagination={{
        size: "small",
      }}
    />
  );
}
