import { APIException } from "../model/api_exception";
import { AuthService } from "../services/auth.service";
import { AxiosResponse } from "axios";
import { Departamento } from "../model/departamento";
import { DepartamentoDataI } from "../model/departamento";
import { Distrito } from "../model/distrito";
import { DistritoDataI } from "../model/distrito";
import { Provincia } from "../model/provincia";
import { ProvinciaDataI } from "../model/provincia";
import axios from "axios";
import Global from "../global/global";

export class APIUbigeo {
  private _auth: AuthService = new AuthService();

  async _listarDepartamentos(): Promise<Departamento[]> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarDepartamentos`,
      {
        token: await this._auth._getToken(),
      }
    );
    if (result.status === 200) {
      let listaDepartamentos: Departamento[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: DepartamentoDataI) => {
          listaDepartamentos.push(new Departamento(element));
        });
      }
      return listaDepartamentos;
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _listarProvincias(departamento: number): Promise<Provincia[]> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarProvincias`,
      {
        token: await this._auth._getToken(),
        departamento,
        flag: true,
      }
    );
    if (result.status === 200) {
      let listaProvincias: Provincia[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: ProvinciaDataI) => {
          listaProvincias.push(new Provincia(element));
        });
      }
      return listaProvincias;
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }

  async _listarDistritos(provincia: number): Promise<Distrito[]> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarDistritos`,
      {
        token: await this._auth._getToken(),
        provincia,
        flag: true,
      }
    );
    if (result.status === 200) {
      let listaDistritos: Distrito[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: DistritoDataI) => {
          listaDistritos.push(new Distrito(element));
        });
      }
      return listaDistritos;
    } else {
      throw new APIException(result.status, result.statusText);
    }
  }
}
