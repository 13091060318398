import * as XLSX from "xlsx";
import ActivoWidget from "../util/activo.widget";
import ErrorComponent from "../components/error.component";
import HeaderWidget from "../util/header.widget";
import LeyendaComponent from "../components/leyenda.component";
import LoadComponent from "../components/load.component";
import PodaContext from "../../provider/poda_provider";
import TitleWidget from "../util/title.widget";
import { _getContextDashboard } from "./dashboard.page";
import { APIException } from "../../model/api_exception";
import { APIPoda } from "../../api/api_poda";
import { APIResponse } from "../../model/api_response";
import { Button, Form, Input, Modal, Space, Spin, Table } from "antd";
import { Poda } from "../../model/poda";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

export default function PodaPage() {
  const [_flag, _setFlag] = useState<boolean>(true);
  const [_form] = Form.useForm();
  const [_listaPoda, _setListaPoda] = useState<Poda[]>([]);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_title, _setTitle] = useState<string>("");
  const [_visible, _setVisible] = useState<boolean>(false);
  const _apiPoda = useContext<APIPoda>(PodaContext);
  const { _mobile } = _getContextDashboard();

  const _listarPodas = useCallback(async () => {
    await _apiPoda
      ._listarPodas()
      .then((value: Poda[]) => {
        _setListaPoda(value);
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      });

    _setLoading(false);
  }, [_apiPoda, _modal]);

  const _renderAcciones = (_value: number, _row: Poda, _index: number) => {
    return (
      <Space>
        <EditOutlined
          style={{
            fontSize: 20,
            color: "rgb(76 175 80)",
          }}
          onClick={() => {
            _setFlag(false);
            _setTitle("Editar registro");
            _form.setFieldsValue({
              pod_id: _row.pod_id,
              pod_descripcion: _row.pod_descripcion,
              pod_estado: _row.pod_estado,
            });
            _setVisible(true);
          }}
        />
        <DeleteOutlined
          style={{
            fontSize: 20,
            color: "rgb(244 67 54)",
          }}
          onClick={() => {
            _eliminarPoda(_row, 0);
          }}
        />
      </Space>
    );
  };

  const _registrarPoda = async (_poda: Poda) => {
    _modal.confirm({
      title: "Agregar registro",
      content: "¿Está seguro de agregar el registro",
      okText: "Si, registrar",
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },
      onOk: async () => {
        _setLoading(true);
        await _apiPoda
          ._registrarPoda(_poda)
          .then((value: APIResponse) => {
            if (value.success) {
              _listarPodas();
              _form.resetFields();
              _modal.success({
                title: "Agregar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
                onOk: () => {
                  _setVisible(false);
                },
              });
            } else {
              _modal.error({
                title: "Agregar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: "Agregar registro",
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _editarPoda = async (_poda: Poda) => {
    _modal.confirm({
      title: "Actualizar registro",
      content: "¿Está seguro de actualizar el registro",
      okText: "Si, actualizar",
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },
      onOk: async () => {
        _setLoading(true);
        await _apiPoda
          ._editarPoda(_poda)
          .then((value: APIResponse) => {
            if (value.success) {
              _listarPodas();
              _form.resetFields();
              _modal.success({
                title: "Editar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
                onOk: () => {
                  _setVisible(false);
                },
              });
            } else {
              _modal.error({
                title: "Editar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: "Editar registro",
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _eliminarPoda = async (_poda: Poda, _estado: number) => {
    _modal.confirm({
      title: "Eliminar registro",
      content:
        "¿Está seguro de eliminar el registro? Esta acción no se puede revertir",
      okText: "Si, eliminar",
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },

      onOk: async () => {
        _setLoading(true);
        await _apiPoda
          ._eliminarPoda(_poda)
          .then((value: APIResponse) => {
            _listarPodas();
            if (value.success) {
              _modal.success({
                title: "Eliminar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            } else {
              _modal.error({
                title: "Eliminar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: "Eliminar registro",
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _exportExcel = (data: Poda[]) => {
    const exportData = data.map((item) => ({
      CODIGO: item.pod_id,
      DESCRIPCION: item.pod_descripcion,
      ESTADO: item.pod_estado === 1 ? "ACTIVO" : "INACTIVO",
    }));
    // Crear un nuevo libro de trabajo
    const workbook = XLSX.utils.book_new();

    // Convertir los datos personalizados en una hoja de trabajo
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Añadir la hoja de trabajo al libro
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datos Exportados");

    // Exportar el libro a un archivo Excel
    XLSX.writeFile(workbook, "Poda.xlsx");
  };

  useEffect(() => {
    _setMounted(true);
    _listarPodas();
    return () => {
      _setMounted(false);
    };
  }, [_listarPodas]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <>
      <Spin spinning={_loading}>
        <TitleWidget data="Podas" color="rgb(0 21 41)" size={30} />
        <hr />
        <HeaderWidget
          children={
            <>
              <LeyendaComponent
                mobile={_mobile}
                editar={true}
                borrar={true}
                activo={true}
              />
              <Space direction={_mobile ? "vertical" : "horizontal"}>
                <Button
                  icon={<FileExcelOutlined />}
                  style={{ width: 120 }}
                  onClick={() =>
                    _listaPoda.length > 0
                      ? _exportExcel(_listaPoda)
                      : _modal.warning({
                          title: "Exportar datos",
                          content: "No se econtró información para exportar",
                          centered: true,
                        })
                  }
                >
                  Exportar
                </Button>
                <Button
                  icon={<PlusCircleOutlined />}
                  type="primary"
                  onClick={() => {
                    _setFlag(true);
                    _form.resetFields();
                    _form.setFieldValue("pod_estado", 1);
                    _setTitle("Agregar registro");
                    _setVisible(true);
                  }}
                >
                  Agregar
                </Button>
              </Space>
            </>
          }
        />
        <Table
          loading={_loading}
          rowKey={"pod_id"}
          pagination={{ size: "small" }}
          columns={[
            {
              title: "Estado",
              key: "pod_estado",
              dataIndex: "pod_estado",
              width: "10%",
              render: (_value: number, _row: Poda, _index: number) => {
                return <ActivoWidget margin="auto" />;
              },
            },
            {
              title: "Descripción",
              key: "pod_descripcion",
              dataIndex: "pod_descripcion",
              width: "80%",
              sorter: (a: Poda, b: Poda) =>
                a.pod_descripcion.localeCompare(b.pod_descripcion),
            },
            {
              title: "Acciones",
              key: "pod_estado",
              dataIndex: "pod_estado",
              width: "10%",
              align: "center",
              render: _renderAcciones,
            },
          ]}
          dataSource={_listaPoda}
        />

        {/* Modal de edición */}
        <Modal
          title={_title}
          visible={_visible}
          onCancel={() => {
            _setVisible(false);
          }}
          onOk={() => {
            _form.validateFields().then((values: any) => {
              _flag
                ? _registrarPoda(new Poda(values))
                : _editarPoda(new Poda(values));
            });
          }}
          okButtonProps={{ danger: true, type: "dashed" }}
          cancelButtonProps={{
            type: "primary",
          }}
          okText="Aceptar"
          cancelText="Cancelar"
          centered
        >
          <Form form={_form} layout="vertical" name="form_in_modal">
            <Form.Item name="pod_id" label="Código" hidden>
              <Input placeholder="Código" readOnly />
            </Form.Item>
            <Form.Item
              name="pod_descripcion"
              label="Descripción"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa la descripción!",
                },
              ]}
            >
              <Input placeholder="Descripción" />
            </Form.Item>
            <Form.Item name="pod_estado" label="Estado" hidden>
              <Input placeholder="Estado" readOnly />
            </Form.Item>
          </Form>
        </Modal>

        {_contextHolder}
      </Spin>
    </>
  );
}
