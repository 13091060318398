import * as XLSX from "xlsx";
import ActivoWidget from "../util/activo.widget";
import ErrorComponent from "../components/error.component";
import FichaTecnicaAgronomicaContext from "../../provider/fichatecnicaagronomica_provider";
import FTABloqueComponent from "../components/ftabloque.component";
import Global from "../../global/global";
import InactivoWidget from "../util/inactivo.widget";
import LeyendaComponent from "../components/leyenda.component";
import LoadComponent from "../components/load.component";
import locale from "antd/es/date-picker/locale/es_ES";
import MapaComponent from "../components/mapa.component";
import moment from "moment";
import PendienteWidget from "../util/pendiente.widget";
import TitleWidget from "../util/title.widget";
import UsuarioContext from "../../provider/usuario_provider";
import { _getContextDashboard } from "./dashboard.page";
import { APIException } from "../../model/api_exception";
import { APIFichaTecnicaAgronomica } from "../../api/api_fichatecnicaagronomica";
import { APIResponse } from "../../model/api_response";
import { APIUsuario } from "../../api/api_usuario";
import { Cultivo } from "../../model/cultivo";
import { Distrito } from "../../model/distrito";
import { FichaTecnicaAgronomica } from "../../model/fichatecnicaagronomica";
import { FTABloque } from "../../model/ftabloque";
import { PerfilContext } from "../../context/perfil-context";
import { useCallback, useContext, useEffect, useState } from "react";
import { Usuario } from "../../model/usuario";
import { Variedad } from "../../model/variedad";
import {
  CheckOutlined,
  CloseOutlined,
  FileExcelOutlined,
  OrderedListOutlined,
  PushpinFilled,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Image,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";

const { ESTADOS, URL_FOTOS, ERROR_IMAGE, FORMATO_FECHA } = Global;
export default function FichaTecnicaAgronomicaPage() {
  const [_dateFin, _setDateFin] = useState<any>();
  const [_dateIni, _setDateIni] = useState<any>();
  const [_estado, _setEstado] = useState<number>(3);
  const [_fta, _setFichaTecnicaAgronomica] = useState<FichaTecnicaAgronomica>();
  const [_ftas, _setFtas] = useState<FichaTecnicaAgronomica[]>([]);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_usuario, _setUsuario] = useState<number>(0);
  const [_visible, _setVisible] = useState<boolean>(false);
  const [_visibleEva, _setVisibleEva] = useState<boolean>(false);
  const [aprobados, setAprobados] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [pendientes, setPendientes] = useState<number>(0);
  const [rechazados, setRechazados] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [_usuarios, _setUsuarios] = useState<Usuario[]>([]);
  const _apiFichaTecnicaAgronomica = useContext<APIFichaTecnicaAgronomica>(
    FichaTecnicaAgronomicaContext
  );
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const { _mobile } = _getContextDashboard();
  const { _perfil } = useContext(PerfilContext);
  const { Option } = Select;
  const { Panel } = Collapse;

  const _listarUsuarios = useCallback(async () => {
    await _apiUsuario._listarUsuarios().then((value: Usuario[]) => {
      _setUsuarios(value);
    });
  }, [_apiUsuario]);

  const _listarFichaTecnicaAgronomica = useCallback(async () => {
    _setLoading(true);
    await _apiFichaTecnicaAgronomica
      ._listarFichaTecnicaAgronomica(
        _usuario,
        _dateIni,
        _dateFin,
        _estado,
        page,
        pageSize
      )
      .then((value) => {
        _setFtas(value.data);
        setTotalItems(value.totalItems || 0);
        setPendientes(value.pendientes || 0);
        setAprobados(value.aprobados || 0);
        setRechazados(value.rechazados || 0);
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      })
      .finally(() => {
        _setLoading(false);
      });
  }, [
    _apiFichaTecnicaAgronomica,
    _dateFin,
    _dateIni,
    _estado,
    _modal,
    _usuario,
    page,
    pageSize,
  ]);

  const _renderEstado = (
    _value: number,
    _row: FichaTecnicaAgronomica,
    _index: number
  ) => {
    if (_value === 0) {
      return <InactivoWidget margin="auto" />;
    }
    if (_value === 1) {
      return <PendienteWidget margin="auto" />;
    }
    if (_value === 2) {
      return <ActivoWidget margin="auto" />;
    }
  };

  const _renderAcciones = (
    _value: number,
    _row: FichaTecnicaAgronomica,
    _index: number
  ) => {
    return _value === 1 && _perfil === 1 ? (
      <Space>
        <CheckOutlined
          style={{
            fontSize: 20,
            color: "rgb(76 175 80)",
          }}
          onClick={() => {
            _actualizarFichaTecnicaAgronomica(_row, 2);
          }}
        />
        <CloseOutlined
          style={{
            fontSize: 20,
            color: "rgb(244 67 54)",
          }}
          onClick={() => {
            _actualizarFichaTecnicaAgronomica(_row, 0);
          }}
        />
        <PushpinFilled
          style={{
            fontSize: 20,
            color: "rgb(0 188 212)",
          }}
          onClick={() => {
            _setFichaTecnicaAgronomica(_row);
            _setVisible(true);
          }}
        />
        <OrderedListOutlined
          style={{
            fontSize: 20,
            color: "rgb(96 125 139)",
          }}
          onClick={() => {
            _setFichaTecnicaAgronomica(_row);
            _setVisibleEva(true);
          }}
        />
      </Space>
    ) : (
      <Space>
        <PushpinFilled
          style={{
            fontSize: 20,
            color: "rgb(0 188 212)",
          }}
          onClick={() => {
            _setFichaTecnicaAgronomica(_row);
            _setVisible(true);
          }}
        />
        <OrderedListOutlined
          style={{
            fontSize: 20,
            color: "rgb(96 125 139)",
          }}
          onClick={() => {
            _setFichaTecnicaAgronomica(_row);
            _setVisibleEva(true);
          }}
        />
      </Space>
    );
  };

  const _actualizarFichaTecnicaAgronomica = async (
    _fta: FichaTecnicaAgronomica,
    _estado: number
  ) => {
    _modal.confirm({
      title: `${_estado === 2 ? "Aceptar" : "Rechazar"} registro`,
      content:
        "¿Está seguro de actualizar el registro? Esta acción no se puede revertir",
      okText: `Si, ${_estado === 2 ? "aceptar" : "rechazar"}`,
      cancelText: "No, cancelar",
      centered: true,
      okButtonProps: {
        danger: true,
        type: "dashed",
      },
      cancelButtonProps: {
        type: "primary",
      },
      onOk: async () => {
        _setLoading(true);
        await _apiFichaTecnicaAgronomica
          ._actualizarFichaTecnicaAgronomica(_fta, _estado)
          .then((value: APIResponse) => {
            _listarFichaTecnicaAgronomica();
            if (value.success) {
              _modal.success({
                title: "Actualizar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            } else {
              _modal.error({
                title: "Actualizar registro",
                content: value.message,
                centered: true,
                okButtonProps: {
                  danger: true,
                  type: "dashed",
                },
                cancelButtonProps: {
                  type: "primary",
                },
              });
            }
          })
          .catch((exception: APIException) => {
            _modal.error({
              title: "Actualizar registro",
              content: exception.message,
              centered: true,
              okButtonProps: {
                danger: true,
                type: "dashed",
              },
              cancelButtonProps: {
                type: "primary",
              },
            });
          });
        _setLoading(false);
      },
    });
  };

  const _exportExcel = async () => {
    _setLoading(true);
    await _apiFichaTecnicaAgronomica
      ._listarFichaTecnicaAgronomica(_usuario, _dateIni, _dateFin, _estado)
      .then((value) => {
        if (value.totalItems > 0) {
          const data: FichaTecnicaAgronomica[] = value.data;
          // Primera hoja: Datos principales
          const exportData = data.map((item) => ({
            CODIGO: item.fta_id,
            ALTITUD: item.fta_altitud,
            PROVINCIA: item.fta_id_distrito?.dis_pro_id?.pro_nombre,
            DISTRITO: item.fta_id_distrito?.dis_nombre,
            LOCALIDAD: item.fta_localidad,
            SECTOR: item.fta_sector,
            "DNI DEL PRODUCTOR": item.fta_dni,
            "NOMBRE COMPLETO": item.fta_nombre,
            "CULTIVO PRINCIPAL": item.fta_cultivo?.cul_descripcion,
            VARIEDAD: item.fta_variedad?.var_descripcion,
            "FECHA DE REGISTRO": item.fta_fecha_registro,
            OBSERVACIONES: item.fta_observaciones,
            "FECHA DE CREACIÓN": item.fta_create_date,
            "FECHA DE SINCRONIZACIÓN": item.fta_sync_up_date,
            USUARIO: item.fta_usu_id?.usu_nombres,
            ESTADO: item.fta_estado === 1 ? "ACEPTADO" : "PENDIENTE",
          }));

          // Crear un nuevo libro de trabajo
          const workbook = XLSX.utils.book_new();

          // Convertir los datos personalizados en una hoja de trabajo
          const worksheet = XLSX.utils.json_to_sheet(exportData);

          // Añadir la primera hoja de trabajo al libro
          XLSX.utils.book_append_sheet(workbook, worksheet, "Datos Exportados");

          // Segunda hoja: Lista de bloques con relación a cabeceras
          const bloquesData: FTABloque[] = [];

          // Recorrer cada registro y sus bloques
          data.forEach((item) => {
            item.fta_bloques.forEach((bloque) => {
              bloquesData.push(bloque);
            });
          });

          // Convertir los datos de los bloques en una hoja de trabajo
          const bloqueWorksheet = XLSX.utils.json_to_sheet(
            bloquesData.map((bloque) => ({
              "FICHA TÉCNICA AGRONÓMICA": bloque.ftab_fta_id,
              CODIGO: bloque.ftab_id,
              "ESTADO FENOLÓGICO":
                bloque.ftab_estado_fenologico?.ef_descripcion,
              "N° PLANTAS MUESTRA": bloque.ftab_nro_plantas_muestra,
              DISTANCIAMIENTO: bloque.ftab_distanciamiento,
              "DIÁMETRO DE COPA": bloque.ftab_diametro_copa,
              "N° HOJAS TOTALES": bloque.ftab_nro_hojas_totales,
              "N° HOJAS ENFERMAS": bloque.ftab_nro_hojas_enfermas,
              "% HOJAS ENFERMAS": bloque.ftab_per_hojas_enfermas,
              "N° HOJAS SANAS": bloque.ftab_nro_hojas_sanas,
              "% HOJAS SANAS": bloque.ftab_per_hojas_sanas,
              "% MALEZA": bloque.ftab_per_malesa,
              EDAD: bloque.ftab_edad,
              "PUNTO 1": `LAT: ${bloque.ftab_lat1} | LNG: ${bloque.ftab_lng1}`,
              "PUNTO 2": `LAT: ${bloque.ftab_lat2} | LNG: ${bloque.ftab_lng2}`,
              "PUNTO 3": `LAT: ${bloque.ftab_lat3} | LNG: ${bloque.ftab_lng3}`,
              "PUNTO 4": `LAT: ${bloque.ftab_lat4} | LNG: ${bloque.ftab_lng4}`,
              "% ANEGAMIENTO": bloque.ftab_per_anegamiento,
              "ALTURA DE LA PLANTA": bloque.ftab_altura_planta,
            }))
          );

          // Añadir la segunda hoja de trabajo al libro
          XLSX.utils.book_append_sheet(
            workbook,
            bloqueWorksheet,
            "Lista de bloques"
          );

          // Exportar el libro a un archivo Excel
          XLSX.writeFile(workbook, "Ficha técnica agronómica con bloques.xlsx");
        }
      })
      .catch((exception: APIException) => {
        _modal.error({
          title: "Listar datos",
          content: exception.message,
          centered: true,
          okButtonProps: {
            danger: true,
            type: "dashed",
          },
          cancelButtonProps: {
            type: "primary",
          },
        });
      })
      .finally(() => {
        _setLoading(false);
      });
  };

  const _changeDateIni = (_moment: any, _date: any) => {
    if (!_date) {
      _setDateIni(null);
    } else {
      _setDateIni(_date);
    }
  };

  const _changeDateFin = (_moment: any, _date: any) => {
    if (!_date) {
      _setDateFin(null);
    } else {
      _setDateFin(_date);
    }
  };

  useEffect(() => {
    _setMounted(true);
    _listarUsuarios();
    _listarFichaTecnicaAgronomica();
    return () => {
      _setMounted(false);
    };
  }, [_listarFichaTecnicaAgronomica, _listarUsuarios]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <>
      <TitleWidget
        data="Ficha técnica agronómica"
        color="rgb(0 21 41)"
        size={30}
      />

      <Collapse>
        <Panel header="Filtros de búsqueda" key={1}>
          <Row gutter={[24, 24]} style={{ padding: 15 }}>
            {_perfil === 1 && (
              <>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <label>Usuario</label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    allowClear
                    placeholder="Selecciona un usuario"
                    optionFilterProp="children"
                    filterOption={(input: string, option: any) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(usuario: number) => {
                      _setUsuario(usuario);
                    }}
                  >
                    {_usuarios.map((value: Usuario, _index: number) => {
                      return (
                        <Option
                          key={`option_usu_${value.usu_id}`}
                          value={`${value.usu_id}`}
                        >
                          {value.usu_nombres}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>

                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <label>Estado</label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    placeholder="Selecciona un estado"
                    onChange={(estado: number) => {
                      _setEstado(estado);
                    }}
                  >
                    {ESTADOS.map(
                      (
                        value: { text: string; value: number },
                        _index: number
                      ) => {
                        return (
                          <Option
                            key={`option_estado_${value.value}`}
                            value={`${value.value}`}
                          >
                            {value.text}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Col>
              </>
            )}

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Fecha de inicio</label>
              <DatePicker
                style={{ width: "100%" }}
                allowClear
                locale={locale}
                onChange={_changeDateIni}
                format={"YYYY-MM-DD"}
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <label>Fecha de fin</label>
              <DatePicker
                style={{ width: "100%" }}
                allowClear
                locale={locale}
                onChange={_changeDateFin}
                format={"YYYY-MM-DD"}
              />
            </Col>

            <Col xs={24}>
              <Space>
                <Tag color="default">
                  Todos: {aprobados + pendientes + rechazados}
                </Tag>
                <Tag color="success">Aprobados: {aprobados}</Tag>
                <Tag color="warning">Pendientes: {pendientes}</Tag>
                <Tag color="error">Rechazados: {rechazados}</Tag>
              </Space>
            </Col>
          </Row>
        </Panel>
      </Collapse>

      <Space
        direction="vertical"
        style={{ width: "100%", margin: "20px 10px" }}
      >
        <Space
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Pagination
            current={page}
            disabled={_ftas.length === 0}
            pageSize={pageSize}
            showSizeChanger
            size="small"
            total={totalItems}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
          />

          <Button
            icon={<FileExcelOutlined />}
            style={{ width: 120 }}
            onClick={_exportExcel}
            loading={_loading}
          >
            Exportar
          </Button>
        </Space>

        <LeyendaComponent
          mobile={_mobile}
          aceptar={_perfil === 1 ? true : false}
          rechazar={_perfil === 1 ? true : false}
          ubicacion={true}
        />

        <Table
          loading={_loading}
          rowKey={"fta_id"}
          pagination={false}
          columns={[
            {
              title: "Acciones",
              key: "fta_estado",
              dataIndex: "fta_estado",
              align: "center",
              render: _renderAcciones,
            },
            {
              title: "Estado",
              key: "fta_estado",
              dataIndex: "fta_estado",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                a.fta_estado - b.fta_estado,
              showSorterTooltip: false,
              render: _renderEstado,
            },
            {
              title: "Código",
              key: "fta_id",
              dataIndex: "fta_id",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                a.fta_id - b.fta_id,
            },
            {
              title: "Foto",
              key: "fta_foto",
              dataIndex: "fta_foto",
              render: (
                _value: string,
                _row: FichaTecnicaAgronomica,
                _index: number
              ) => {
                return (
                  <Image
                    width={100}
                    height={50}
                    src={`${URL_FOTOS}/${_value}`}
                    fallback={ERROR_IMAGE}
                    style={{ borderRadius: 10 }}
                  />
                );
              },
            },
            {
              title: "Distrito",
              key: "fta_id_distrito",
              dataIndex: "fta_id_distrito",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                a.fta_id_distrito.dis_nombre.localeCompare(
                  b.fta_id_distrito.dis_nombre
                ),
              render: (
                _value: Distrito,
                _row: FichaTecnicaAgronomica,
                _index: number
              ) => {
                return _value.dis_nombre;
              },
            },
            {
              title: "Localidad",
              key: "fta_localidad",
              dataIndex: "fta_localidad",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                a.fta_localidad.localeCompare(b.fta_localidad),
            },
            {
              title: "Sector",
              key: "fta_sector",
              dataIndex: "fta_sector",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                a.fta_sector.localeCompare(b.fta_sector),
            },
            {
              title: "N° DNI",
              key: "fta_dni",
              dataIndex: "fta_dni",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                a.fta_dni.localeCompare(b.fta_dni),
            },
            {
              title: "Celular",
              key: "fta_nombre",
              dataIndex: "fta_nombre",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                a.fta_nombre.localeCompare(b.fta_nombre),
            },
            {
              title: "Cultivo",
              key: "fta_cultivo",
              dataIndex: "fta_cultivo",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                a.fta_cultivo.cul_descripcion.localeCompare(
                  b.fta_cultivo.cul_descripcion
                ),
              render: (
                _value: Cultivo,
                _row: FichaTecnicaAgronomica,
                _index: number
              ) => {
                return _value.cul_descripcion;
              },
            },
            {
              title: "Variedad",
              key: "fta_variedad",
              dataIndex: "fta_variedad",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                a.fta_variedad.var_descripcion.localeCompare(
                  b.fta_variedad.var_descripcion
                ),
              render: (
                _value: Variedad,
                _row: FichaTecnicaAgronomica,
                _index: number
              ) => {
                return _value.var_descripcion;
              },
            },
            {
              title: "Fecha de registro",
              key: "fta_fecha_registro",
              dataIndex: "fta_fecha_registro",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                moment(a.fta_fecha_registro, FORMATO_FECHA).unix() -
                moment(b.fta_fecha_registro, FORMATO_FECHA).unix(),
            },
            {
              title: "Observaciones",
              key: "fta_observaciones",
              dataIndex: "fta_observaciones",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                a.fta_observaciones.localeCompare(b.fta_observaciones),
            },
            {
              title: "Fecha de creación",
              key: "fta_create_date",
              dataIndex: "fta_create_date",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                moment(a.fta_create_date, FORMATO_FECHA).unix() -
                moment(b.fta_create_date, FORMATO_FECHA).unix(),
            },
            {
              title: "Fecha de sincronización",
              key: "fta_sync_up_date",
              dataIndex: "fta_sync_up_date",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                moment(a.fta_sync_up_date, FORMATO_FECHA).unix() -
                moment(b.fta_sync_up_date, FORMATO_FECHA).unix(),
            },
            {
              title: "Usuario",
              key: "fta_usu_id",
              dataIndex: "fta_usu_id",
              sorter: (a: FichaTecnicaAgronomica, b: FichaTecnicaAgronomica) =>
                a.fta_usu_id.usu_nombres.localeCompare(
                  b.fta_usu_id.usu_nombres
                ),
              render: (
                _value: Usuario,
                _row: FichaTecnicaAgronomica,
                _index: number
              ) => {
                return _value.usu_nombres;
              },
            },
          ]}
          dataSource={_ftas}
        />

        <Pagination
          current={page}
          disabled={_ftas.length === 0}
          pageSize={pageSize}
          showSizeChanger
          size="small"
          total={totalItems}
          onChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      </Space>

      {/* Modal ubicación */}
      {_fta && (
        <Modal
          key={`location_${_fta.fta_id}`}
          visible={_visible}
          onCancel={() => {
            _setVisible(false);
          }}
          footer={[]}
          centered
        >
          <MapaComponent lat={_fta.fta_latitud} lng={_fta.fta_longitud} />
        </Modal>
      )}

      {/* Modal evaluaciones */}
      {_fta && (
        <Modal
          key={`fta_${_fta.fta_id}`}
          visible={_visibleEva}
          onCancel={() => {
            _setVisibleEva(false);
          }}
          footer={[]}
          centered
          width={"1500px"}
        >
          <FTABloqueComponent fta={_fta} />
        </Modal>
      )}
      {_contextHolder}
    </>
  );
}
